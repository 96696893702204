import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Fragment, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import StatusCell from "./components/StatusCell";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "./components/DefaultCell";
import ConfirmationAlert from "components/ConfirmationAlert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import CopyToClipboard from "react-copy-to-clipboard";

/* eslint-disable */
export default function Domains() {
  const [isLoading, setIsLoading] = useState(true);
  const [verified, setVerified] = useState(true);
  const [items, setItems] = useState([]);
  const [toDeleteItem, setToDeleteItem] = useState({});
  const [isVerifing, setIsVerifing] = useState(false);
  const [verifyResponse, setVerifyResponse] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("");
  const [tokenTxt, setTokenTxt] = useState("");
  const [openVerifingDomain, setOpenVerifingDomain] = useState(false);
  const closeVerifingDomain = () => setOpenVerifingDomain(false);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = () => {
    deleteItem(toDeleteItem.id);
    setOpenAlert(false);
  };

  const navigate = useNavigate();

  const options = {
    method: "GET",
    url: "domains",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `domains`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        //  console.log(response);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const verifyDomain = (domain) => {
    setIsVerifing(true);
    setOpenVerifingDomain(true);
    setSelectedDomain(domain);
    const options = {
      method: "GET",
      url: `domains/verify?domain=${domain}`,
    };

    client
      .request(options)
      .then((response) => {
        setVerifyResponse(response);
        setIsVerifing(false);
        if (
          response.a_record === false ||
          response.txt_record === false ||
          response.two_a_records === true
        ) {
          setVerified(false);
        } else {
          setVerified(true);
          getData();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteItem = (id) => {
    const options = {
      method: "DELETE",
      url: `domains?id=${id}`,
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getTxt = async () => {
    options.method = "GET";
    options.url = `domains/GetInstructions`;
    await client
      .request(options)
      .then((response) => {
        setTokenTxt(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTxt();
    getData();
  }, []);

  const dataTableData = {
    columns: [
      {
        Header: "Actions",
        accessor: "dom_id",
        Cell: ({ row }) => (
          <Fragment>
            {row.original.dom_status != 0 && (
              <MDButton
                onClick={() => {
                  verifyDomain(row.original.dom_domain);
                }}
                variant="gradient"
                color="primary"
              >
                Connect
              </MDButton>
            )}
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                onClick={() => {
                  setToDeleteItem({
                    id: row.original.dom_id,
                    domain: row.original.dom_domain, // Pasar el dom_domain aquí
                  });
                  setOpenAlert(true);
                }}
              >
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </Fragment>
        ),
      },
      {
        Header: "status",
        accessor: "dom_status",
        Cell: ({ value }) => {
          let status;
          switch (value) {
            case 0:
              status = <StatusCell icon="done" color="success" status="Connected" />;
              break;
            case 11:
              status = <StatusCell icon="close" color="error" status="Connected To old IP" />;
              break;
            default:
              status = <StatusCell icon="close" color="error" status="Not Connected" />;
              break;
          }
          return status;
        },
      },
      {
        Header: "domain",
        accessor: "dom_domain",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "creation date",
        accessor: "dom_creationDate",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
      },
    ],

    rows: items,
  };

  const renderVerifyDomain = (
    <MDSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      color={"text"}
      icon="domain_verification"
      title={
        isVerifing ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CircularProgress size={50} />
            <MDTypography variant="h5" color="success">
              Verifing domain {selectedDomain}...
            </MDTypography>
          </MDBox>
        ) : verified ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />
            <MDTypography variant="h5" color="success">
              Domain connected
            </MDTypography>
            {verifyResponse.ss == false && (
              <MDTypography variant="h5" color="warning">
                There may be a delay creating a certificate for your domain, but do not worry, an
                tech on our team has been notified about it. We will be closely monitoring the
                process to make sure it gets issued as fast as possible
              </MDTypography>
            )}
          </MDBox>
        ) : (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <HighlightOffIcon color="error" fontSize="large" />
            <MDTypography variant="h5" color="error">
              Domain Connection Failed
            </MDTypography>
            <MDTypography variant="h6" color="error">
              If you have recently updated your domain records, please note that it may take 24-48
              hours for the changes to be propagated throughout the internet. Please try again after
              allowing time for propagation.
            </MDTypography>
            {verifyResponse?.two_a_records === true && (
              <MDTypography variant="h6" color="error">
                We have detected that you have two A records set up for your domain. Make sure to
                remove the record that is not pointing to our IP address (
                {process.env.REACT_APP_DOMAIN_DNS_RECORD}).
              </MDTypography>
            )}
            <MDTypography variant="h6" mt={5}>
              Make sure you have the following DNS records set:
            </MDTypography>
            <MDBox mt={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Type
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Name / Host Name
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="left">
                        IP Address / Value
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {verifyResponse.a_record === true ? (
                        <StatusCell icon="done" color="success" />
                      ) : (
                        <StatusCell icon="close" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        A
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        @
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDBox display="flex" justifyContent="left">
                        <CopyToClipboard text={process.env.REACT_APP_DOMAIN_DNS_RECORD}>
                          <IconButton
                            sx={{ fontWeight: "bold" }}
                            color="primary"
                            aria-label="prompt"
                          >
                            <Tooltip id="button-report" title="Copy to clipboard">
                              <ContentCopyIcon />
                            </Tooltip>
                          </IconButton>
                        </CopyToClipboard>
                        <MDTypography variant="body2" color="text" mt={1}>
                          {" "}
                          {process.env.REACT_APP_DOMAIN_DNS_RECORD}
                        </MDTypography>
                      </MDBox>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {verifyResponse.txt_record === true ? (
                        <StatusCell icon="done" color="success" />
                      ) : (
                        <StatusCell icon="close" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        TXT
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        _{process.env.REACT_APP_PLNAME}
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDBox display="flex" justifyContent="left">
                        <CopyToClipboard text={tokenTxt}>
                          <IconButton
                            sx={{ fontWeight: "bold" }}
                            color="primary"
                            aria-label="prompt"
                          >
                            <Tooltip id="button-report" title="Copy to clipboard">
                              <ContentCopyIcon />
                            </Tooltip>
                          </IconButton>
                        </CopyToClipboard>
                        <MDTypography variant="body2" color="text" textAlign="center" mt={1}>
                          {tokenTxt}
                        </MDTypography>
                      </MDBox>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MDBox>
            <MDBox mt={5} display="flex" justifyContent="space-between">
              <MDButton variant="gradient" color="secondary" onClick={closeVerifingDomain}>
                Close
              </MDButton>
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => verifyDomain(selectedDomain)}
              >
                Retry Connection
              </MDButton>
            </MDBox>
          </MDBox>
        )
      }
      autoHideDuration={60000}
      dateTime=""
      open={openVerifingDomain}
      onClose={closeVerifingDomain}
      close={closeVerifingDomain}
      backgroundcolor={verified ? "success" : "error"}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderVerifyDomain}
      <MDBox my={3}>
        <Card style={{ padding: 20, marginBottom: 5 }}>
          {localStorage.getItem("profileCompleted") == "true" ? (
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
              <MDButton variant="gradient" color="primary" onClick={() => navigate("/domains/add")}>
                new domain
              </MDButton>
            </MDBox>
          ) : (
            <>
              <MDTypography variant="h6" color="text">
                You need to complete your profile in order to create a domain on{" "}
                {process.env.REACT_APP_SITE_TITLE}
              </MDTypography>
              <MDButton
                variant="gradient"
                color="primary"
                component={Link}
                to="/profile"
                style={{ width: 200 }}
              >
                Go to profile
              </MDButton>
            </>
          )}
        </Card>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={true} canSearch />
        </Card>
        <ConfirmationAlert
          openModal={openAlert}
          onClose={() => setOpenAlert(false)}
          onYes={handleDelete}
          yesText="Yes"
          title={`Are you sure you want to delete your domain (${toDeleteItem.domain}) from PixaPage? Deleting the domain will remove all existing links to pages for this domain from your account  and any pages published to the domain will be moved to Draft status.`}
        />
      </MDBox>
    </DashboardLayout>
  );
}

/* DROPDOWN MENU

<PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <Fragment>
                <Tooltip title="Actions" placement="bottom">
                  <IconButton {...bindTrigger(popupState)} sx={{ cursor: "pointer" }}>
                    <Icon color="action" fontSize="small">
                      more_horiz
                    </Icon>
                  </IconButton>
                </Tooltip>

                <Menu {...bindMenu(popupState)}>
                  {row.original.dom_status != 0 && (
                    <MenuItem
                      onClick={() => {
                        popupState.close;
                        verifyDomain(row.original.dom_domain);
                      }}
                    >
                      <ListItemIcon>
                        <Icon>domain_verification</Icon>
                      </ListItemIcon>
                      Verify
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      popupState.close;
                      setToDeleteItem({
                        id: row.original.dom_id,
                        domain: row.original.dom_domain, // Pasar el dom_domain aquí
                      });
                      setOpenAlert(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                </Menu>
              </Fragment>
            )}
          </PopupState>

          */
