import type grapesjs from 'grapesjs';
import { PluginOptions } from '.';
import { commandNameCustomCode, keyCustomCode } from './utils.ts';

export default (editor: grapesjs.Editor, opts: PluginOptions = {}) => {
  const { modalTitle, codeViewOptions, commandCustomCode } = opts;

  const appendToContent = (target: HTMLElement, content: HTMLElement | string) => {
    if (content instanceof HTMLElement) {
        target.appendChild(content);
    } else if (content) {
        target.insertAdjacentHTML('beforeend', content);
    }
  }

  // Add the custom code command
  editor.Commands.add(commandNameCustomCode, {
    keyCustomCode,

    run(editor, s, opts = {}) {
      const target = opts.target || editor.getSelected();
      // @ts-ignore
      this.target = target;

      if (target?.get('editable')) {
        // @ts-ignore
        this.showCustomCode(target, opts);
      }
    },

    stop(editor) {
      editor.Modal.close();
    },

    /**
     * Method which tells how to show the custom code
     * @param  {Component} target
     */
    showCustomCode(target, options = {}) {
      const title = options.title || modalTitle;
      const storedTitle = target.get('storedTitleAttribute'); // Replace 'storedTitleAttribute' with the actual attribute name
      const storedDescription = target.get('storedDescriptionAttribute'); // Replace 'storedDescriptionAttribute' with the actual attribute name

      console.warn("Retrieved Title: ", storedTitle); // Debugging log
      console.warn("Retrieved Description: ", storedDescription); // Debugging log
      const code = target.get(keyCustomCode) || '';
      const content = this.getContent(target, storedTitle, storedDescription);
    
      editor.Modal.open({
        title: title,
        content: content,
      }).onceClose(() => editor.stopCommand(commandNameCustomCode));
    
      this.getCodeViewer().setContent(code);
    },
    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {
      const preContent = document.createElement('div');
      preContent.style.borderBottom = '1px solid #ddd'; // Add border to separate title and description
      return preContent;
    },

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {
      const postContent = document.createElement('div');
      postContent.style.marginTop = '10px'; // Add margin between description and actions
      return postContent;
    },

    /**
     * Get all the content for the custom code
     * @return {HTMLElement}
     */
    
    getContent(target, storedTitle = '', storedDescription = '') {
      const codeViewer = this.getCodeViewer();
      const content = document.createElement('div');
      const pfx = editor.getConfig('stylePrefix');
      content.className = `${pfx}custom-code`;
    
      appendToContent(content, this.getPreContent());
      content.appendChild(this.getTitleInput(storedTitle));
      content.appendChild(this.getDescriptionTextarea(storedDescription));
      appendToContent(content, this.getPostContent());
      appendToContent(content, this.getContentActions(storedTitle));
      codeViewer.refresh();
      setTimeout(() => codeViewer.focus(), 0);
    
      return content;
    },

    getTitleInput(title = '') {
      const titleInputContainer = document.createElement('div');
      titleInputContainer.style.marginBottom = '10px'; // Add margin to separate title and description
    
      const titleInput = document.createElement('input');
      titleInput.setAttribute('type', 'text');
      titleInput.setAttribute('placeholder', 'Dialog Box Title');
      titleInput.setAttribute('id', 'titleInput'); // Add an ID for easy reference
      titleInput.style.border = '1px solid #ddd'; // Add border to the title
      titleInput.style.padding = '5px'; // Add padding for better appearance
      titleInput.style.width = '100%'; // Set width to 100%
      titleInput.style.color = 'black'; // Set text color to black
      titleInput.style.fontWeight = 'bold'; // Set font weight to bold
    
      // Set the title value if provided
      titleInput.value = title;
    
      titleInputContainer.appendChild(titleInput);
      return titleInputContainer;
    },
    
    getDescriptionTextarea(description = '') {
      const container = document.createElement('div');
      container.style.position = 'relative'; // Position the container relatively

      const descriptionTextarea = document.createElement('textarea');
      descriptionTextarea.setAttribute('placeholder', 'Insert your script here');
      descriptionTextarea.setAttribute('id', 'descriptionTextarea');
      descriptionTextarea.style.border = '1px solid #ddd';
      descriptionTextarea.style.padding = '5px';
      descriptionTextarea.style.width = '100%';
      descriptionTextarea.setAttribute('rows', '15');
      descriptionTextarea.style.color = 'black';
      descriptionTextarea.style.fontWeight = 'bold';
      descriptionTextarea.value = description;
    
      if (description) {
        descriptionTextarea.setAttribute('disabled', 'disabled');
      }
    
      container.appendChild(descriptionTextarea);
    
      // Check if there is a description and add an Edit button
      if (description) {
        descriptionTextarea.style.backgroundColor =   'white' ; // Set to white in edit mode

        const editButton = document.createElement('button');
        editButton.textContent = 'Edit';
        editButton.style.position = 'absolute'; // Position the button absolutely within the container
        editButton.style.top = '5px'; // Adjust the top position
        editButton.style.borderRadius = '5px'; // Add border radius
        editButton.style.backgroundColor = '#d3d3d3'; // Set the background color to grey
        editButton.style.color = 'black'; // Set the text color to black
        editButton.style.border = 'none'; // Remove default border
        editButton.style.right = '5px'; // Adjust the right position
        editButton.onclick = () => {
          descriptionTextarea.removeAttribute('disabled');
          descriptionTextarea.focus();
          editButton.remove(); // Remove the edit button
        };
    
        container.appendChild(editButton);
      }
    
      return container;
    },
               

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     * @return {HTMLElement|String}
     */
    getContentActions(storedTitle) {
      const btn = document.createElement('button');
      btn.setAttribute('type', 'button');
      const pfx = editor.getConfig('stylePrefix');
      btn.innerHTML = opts.buttonLabel!;
      btn.className = `${pfx}btn-prim ${pfx}btn-import__custom-code`;
      // @ts-ignore
      btn.onclick = () => this.handleSave(storedTitle);

      return btn;
    },

    /**
     * Handle the main save task
     */
    handleSave(storedTitle) {
      const { target } = this;
      const title = document.getElementById('titleInput').value;
      const description = document.getElementById('descriptionTextarea').value;
      const isEditModal = !!document.getElementById('titleInput') && !!document.getElementById('descriptionTextarea');
      // alert(isEditModal)
 
      // alert(storedTitle)
      let template:any = `` 
    if (!storedTitle) {
      template = `
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          /* Style the button */
    /*      .openModalBtn { */
    /*        border: none;*/
    /*       cursor: pointer;*/
    /*       background-color: #f0c864;*/
    /*color: #000;*/
    /*font-weight: 700;*/
    /*line-height: 1.66;*/
    /*font-size: 20px;*/
    /*border: 0;*/
    /*border-radius: 30px;*/
    /*display: inline-block;*/
    /*padding: 8px 20px;*/
    /*width: auto;*/
    /*height: 48px;*/
    /*      }*/
      
          /* Style the modal */
          .modal {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
          }
      
          /* Style the modal content */
          .modal-content {
            margin: 5% auto;    
                padding: 32px 64px;
    max-width: 624px;
    text-align: center;
    border-radius: 4px;
    background-color: #f4f4f4;
    border: solid 1px grey;
    position: relative;
          }
      
          /* Style the close button */
          .closeModalBtn {
           color: rgb(42 42 42);
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
}
          }
      
          .closeModalBtn:hover,
          .closeModalBtn:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
          }
        </style>
      </head>
      <body>
      <modal>
      <button class="openModalBtn">${title}</button>
      
      <div class="modal">
         
      <div class="modal-content">
      <span class="closeModalBtn">&times;</span>
      <p>${description}</p>
      </div>
      
      </div>
      </modal>
      <script>
        // Get the modal and button elements
        var modals = document.querySelectorAll(".modal");
        var openBtns = document.querySelectorAll(".openModalBtn");
        var closeBtns = document.querySelectorAll(".closeModalBtn");
      
        // Open the modal when the button is clicked
        document.body.addEventListener('click', function(event) {
          for (var i = 0; i < openBtns.length; i++) {
            if (event.target === openBtns[i]) {
              modals[i].style.display = "block";
            } else if (event.target === closeBtns[i] || event.target === modals[i]) {
              modals[i].style.display = "none";
            }
          }
        });
      </script>
      </body>
      </html>
      `;
   
    }

    else{

      template = `
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
         
          /* Style the modal */
          .modal {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
          }
      
          /* Style the modal content */
          .modal-content {
            margin: 5% auto;    
                padding: 32px 64px;
    max-width: 624px;
    text-align: center;
    border-radius: 4px;
    background-color: #f4f4f4;
    border: solid 1px grey;
    position: relative;
          }
      
          /* Style the close button */
          .closeModalBtn {
           color: rgb(42 42 42);
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
}
          }
      
          .closeModalBtn:hover,
          .closeModalBtn:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
          }
        </style>
      </head>
      <body>
      <modal>
      <button class="openModalBtn">${title}</button>
      
      <div class="modal">
         
      <div class="modal-content">
      <span class="closeModalBtn">&times;</span>
      <p>${description}</p>
      </div>
      
      </div>
      </modal>
      <script>
        // Get the modal and button elements
        var modals = document.querySelectorAll(".modal");
        var openBtns = document.querySelectorAll(".openModalBtn");
        var closeBtns = document.querySelectorAll(".closeModalBtn");
      
        // Open the modal when the button is clicked
        document.body.addEventListener('click', function(event) {
          for (var i = 0; i < openBtns.length; i++) {
            if (event.target === openBtns[i]) {
              modals[i].style.display = "block";
            } else if (event.target === closeBtns[i] || event.target === modals[i]) {
              modals[i].style.display = "none";
            }
          }
        });
      </script>
      </body>
      </html>
      `
    }

      target.set('storedTitleAttribute', title);
      target.set('storedDescriptionAttribute', description);

      // Combine title and description as needed
      // const code = `${title}\n${description}`;

      // Template
      
      console.warn('kk', title)
      target.set(keyCustomCode, template);
      editor.Modal.close();
    },

    /**
     * Return the code viewer instance
     * @return {CodeViewer}
     */
    getCodeViewer() {
      // @ts-ignore
      if (!this.codeViewer) {
        // @ts-ignore
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: 'htmlmixed',
          theme: 'hopscotch',
          readOnly: 0,
          ...codeViewOptions,
        });
      }
      // @ts-ignore
      return this.codeViewer;
    },

    ...commandCustomCode,
  });
};
