import { CircularProgress } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";

/*eslint-disable */
export default function ConfirmationAlert(props) {
  const [isLoading, setIsLoading] = useState(props.isLoading);
  return (
    <Dialog open={props.openModal}>
      <DialogContent>
        <MDTypography>{props.title}</MDTypography>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          autoFocus
          onClick={props.onClose}
          variant="gradient"
          color="secondary"
          disabled={props.isLoading}
        >
          No
        </MDButton>
        <MDButton
          autoFocus
          variant="gradient"
          color="error"
          onClick={props.onYes}
          disabled={props.isLoading}
        >
          {props.yesText ?? "Yes"}
          {props.isLoading && <CircularProgress size={20} />}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
