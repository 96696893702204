import { Chip, Divider, Icon, withStyles, Tooltip, Menu } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function FirstNode(node) {
  /*
    public const int AUTOMATION_START_EVENT_SUBSCRIBER_ADDED = 0;
        public const int AUTOMATION_START_EVENT_FINISHED_SERIE = 1;
        public const int AUTOMATION_START_EVENT_ENGAGED_BROADCAST = 2;
        public const int AUTOMATION_START_EVENT_ENGAGED_CATALOG = 3;
        public const int AUTOMATION_START_EVENT_ENGAGED_ITEM = 4;

        public const string AUTOMATION_STEP_TYPE_INPUT = "AUTOMATION_STEP_TYPE_INPUT";
        public const string AUTOMATION_STEP_TYPE_SEND_EMAIL = "AUTOMATION_STEP_TYPE_SEND_EMAIL";
        public const string AUTOMATION_STEP_TYPE_CALL_WEBHOOK = "AUTOMATION_STEP_TYPE_CALL_WEBHOOK";
        public const string AUTOMATION_STEP_TYPE_WAIT_TIME = "AUTOMATION_STEP_TYPE_WAIT_TIME";
        public const string AUTOMATION_STEP_TYPE_WAIT_EVENT = "AUTOMATION_STEP_TYPE_WAIT_EVENT";
        */

  const StyleChip = withStyles({
    root: {
      backgroundColor: "gray",
      borderColor: "white",
      "& .MuiChip-label": {
        fontSize: 8,
        color: "white",
      },
    },
  })(Chip);

  const [anchorEl, setAnchorEl] = useState(null);
  const onClick = (event) => node.data.onClickAdd(event, node);
  const open = Boolean(anchorEl);

  const getIcon = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return (
          <Icon fontSize="small" mt={3} rotate={180}>
            call_split
          </Icon>
        );
      case "FUNNELITEM_PAGE":
        return (
          <Icon fontSize="small" mt={3}>
            web
          </Icon>
        );
      case "FUNNELITEM_CALLURL":
        return (
          <Icon fontSize="small" mt={3}>
            webhook
          </Icon>
        );
      case "FUNNELITEM_EMAIL":
        return (
          <Icon fontSize="small" mt={3}>
            mail
          </Icon>
        );
      case "FUNNELITEM_ADDSUBSCRIBER":
        return (
          <Icon fontSize="small" mt={3}>
            person_add
          </Icon>
        );
      default:
        return "error";
    }
  };

  const getEvent = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return "Split test";
      case "FUNNELITEM_PAGE":
        return "Page";
      default:
        return "error";
    }
  };

  const getLabel = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return "tests";
      case "FUNNELITEM_PAGE":
        return "";
      default:
        return "";
    }
  };

  const getData = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        const splitArr = JSON.parse(node.data.fus_additionalData);
        return (
          <MDBox>
            {splitArr.map((item, index) => {
              return (
                <MDBox display="flex">
                  <MDTypography variant="caption" fontWeight="medium">
                    Page {index + 1}:
                  </MDTypography>
                  <MDTypography variant="caption">{item.Percentage}%</MDTypography>
                </MDBox>
              );
            })}
          </MDBox>
        );
      case "FUNNELITEM_PAGE":
        return (
          <MDBox>
            {" "}
            <MDTypography variant="caption" fontWeight="medium">
              Name:{" "}
            </MDTypography>
            <MDTypography variant="caption">{node.data.fus_additionalData}</MDTypography>
          </MDBox>
        );

      default:
        return "";
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    //  console.log(event.currentTarget.value);
    onClick(event.currentTarget.value);
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#00CCFF22",
      }}
    >
      <MDBox display="flex">
        {getIcon(node.data.fus_type)}
        <MDBox display="flex" style={{ width: "100%" }}>
          <MDTypography variant="caption" fontWeight="medium" ml={1} style={{ width: "150px" }}>
            {getEvent(node.data.fus_type)}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="right" style={{ width: "100%" }}>
          {node.selected && node.data.fus_type === "FUNNELITEM_PAGE" && (
            <Tooltip title="edit page" placement="top">
              <Icon
                color="action"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onEdit(e, node)}
              >
                edit
              </Icon>
            </Tooltip>
          )}
          {node.selected && (
            <Tooltip title="delete event" placement="top">
              <Icon
                color="error"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onDelete(e, node)}
              >
                delete
              </Icon>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox display="flex">
        <MDTypography ml={1} variant="caption" fontWeight="medium">
          {getLabel(node.data.fus_type)}
        </MDTypography>
      </MDBox>
      {getData(node.data.fus_type)}

      <Handle type="source" position={Position.Bottom}>
        {node.data.fus_type != "FUNNELITEM_SPLITTEST" && node.selected && (
          <div>
            <Tooltip title="Add a new item" placement="top">
              <Icon
                color="action"
                fontSize="small"
                onClick={handleClick}
                style={{ marginLeft: -8 }}
              >
                add_circle
              </Icon>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose} value="0">
                Add a Page
              </MenuItem>
              <MenuItem onClick={handleClose} value="1">
                Add a split test
              </MenuItem>
              <MenuItem onClick={handleClose} value="2">
                Call a webhook
              </MenuItem>
            </Menu>
          </div>
        )}
      </Handle>
    </div>
  );
}
