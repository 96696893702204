import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  useReactFlow,
  useOnSelectionChange,
} from "reactflow";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import FirstNode from "./firstNode";
import ItemNode from "./itemNode";

const nodeTypes = {
  firstNode: FirstNode,
  itemNode: ItemNode,
};
let nodeId = 0;

const initialNodes = [];
const initialEdges = [];

/*eslint-disable*/
const Flow = forwardRef((props, ref) => {
  const [lastId, setLastId] = useState(0);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const rfInstance = useReactFlow();

  const { getNodes, getEdges } = useReactFlow();

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  useImperativeHandle(ref, () => ({
    onAddAction(data) {
      addAction(data);
    },
    onUpdate(nodeUpdate, data) {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeUpdate.id) {            
            data.fus_step_id = Number(nodeUpdate.id);
            data.onClickAdd = onClickAdd;
            data.onDelete = onDelete;
            data.onEdit = onEdit;
            node.data = data;
          }
          return node;
        })
      );
      setSelectedNodes([nodeUpdate.id]);
      rfInstance.fitView();
    },
    getNodes() {
      return rfInstance.getNodes();
    },
    onAddEvent(data) {
      addEvent(data);
    },
    onSave() {
      return rfInstance.toObject();
    },
    fitView() {
      setSelectedNodes([]);
      rfInstance.fitView();
      props.onSelectNode(nodes);
    },
  }));

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      setSelectedNodes(nodes.map((node) => node.id));
      props.onSelectNode(nodes);
    },
  });

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({ id: `e${source}-${target}`, source, target }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const isValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target.id === connection.source) return false;
      return !hasCycle(target);
    },
    [getNodes, getEdges]
  );

  const onClickAdd = (event, data) => {
    setSelectedNodes([data.id]);
    props.onClickAdd(event, data);
  };

  const onEdit = (event, data) => {
    setSelectedNodes([data.id]);
    props.onClickEdit(event, data);
    //console.log(event, data);
  };

  const onDelete = (event, data) => {
    rfInstance.deleteElements({ nodes: [data], edges: [] });
    //props.onDeleteNode(event, data);
    // rfInstance.setNodes((nds) => nds.filter((node) => node.id !== data.id));

    //props.onClickAdd(event, data);
    //console.log(event, data);
  };

  const addEvent = useCallback(
    (data) => {
      let id = `${++nodeId}`;
      let position = { x: 0, y: 0 };
      const actualNodes = rfInstance.getNodes();
      if (actualNodes.length > 0) {
        const eventNodes = actualNodes.filter((node) => node.type === "firstNode");
        position = {
          x: eventNodes[eventNodes.length - 1].position.x + 250,
          y: eventNodes[eventNodes.length - 1].position.y + 10,
        };
      }

      setNodes((nds) =>
        nds.map((node) => {
          node.selected = false;
          return node;
        })
      );

      data.fus_step_id = Number(id);
      data.onClickAdd = onClickAdd;
      data.onDelete = onDelete;
      data.onEdit = onEdit;
      const newNode = {
        id: id,
        position: position,
        type: "firstNode",
        selected: true,
        data: data,
      };

      rfInstance.addNodes(newNode);
      setSelectedNodes([id]);

      if (data.fus_type == "FUNNELITEM_SPLITTEST") {
        const splitNode = newNode;
        let pagesPerc = [];
        for (let i = 0; i < data.fus_pag_id; i++) {
          const idNode = `${++nodeId}`;
          const position = {
            x: splitNode.position.x + 250 * i,
            y: splitNode.position.y + 250,
          };
          const newNode = {
            id: idNode,
            position: position,
            type: "itemNode",
            selected: false,
            data: {
              fus_step_id: Number(idNode),
              fus_type: "FUNNELITEM_PAGE",
              fus_additionalData: "Split " + (i + 1),
              fus_fun_id: 0,
              fus_id: 0,
              fus_pag_id: 0,
              fus_triggerDetails: "",
              fus_uniqueVisitorId: 0,
              onClickAdd: onClickAdd,
              onDelete: onDelete,
              onEdit: onEdit,
            },
          };
          console.log(newNode);
          rfInstance.addNodes(newNode);
          let newEdge = { id: `e${splitNode.id}-${idNode}`, source: splitNode.id, target: idNode };
          setEdges((eds) => addEdge(newEdge, eds));
          pagesPerc.push({ NodeId: idNode.toString(), Percentage: 100 / data.fus_pag_id });
        }
        splitNode.data.fus_additionalData = JSON.stringify(pagesPerc);
      }

      rfInstance.setCenter(position.x, position.y);
    },
    [rfInstance, setEdges, selectedNodes]
  );

  const addAction = useCallback(
    (data) => {
      const id = `${++nodeId}`;
      let position = { x: 0, y: 0 };
      const actualNodes = rfInstance.getNodes();
      const selectedNode = actualNodes.find((node) => node.id === selectedNodes[0]);

      position = {
        x: selectedNode.position.x,
        y: selectedNode.position.y + selectedNode.height + 80,
      };

      setNodes((nds) =>
        nds.map((node) => {
          node.selected = false;
          return node;
        })
      );

      data.fus_step_id = Number(id);
      data.onClickAdd = onClickAdd;
      data.onEdit = onEdit;
      data.onDelete = onDelete;

      const newNode = {
        id,
        position: position,
        type: "itemNode",
        selected: true,
        data: data,
      };

      rfInstance.addNodes(newNode);
      let newEdge = { id: `e${selectedNode.id}-${id}`, source: selectedNode.id, target: id };
      setEdges((eds) => addEdge(newEdge, eds));
      setSelectedNodes([id]);

      if (data.fus_type == "FUNNELITEM_SPLITTEST") {
        const splitNode = newNode;
        let pagesPerc = [];
        for (let i = 0; i < data.fus_pag_id; i++) {
          const idNode = `${++nodeId}`;
          const position = {
            x: splitNode.position.x + 250 * i,
            y: splitNode.position.y + 250,
          };
          const newNode = {
            id: idNode,
            position: position,
            type: "itemNode",
            selected: false,
            data: {
              fus_step_id: Number(idNode),
              fus_type: "FUNNELITEM_PAGE",
              fus_additionalData: "Split " + (i + 1),
              fus_fun_id: 0,
              fus_id: 0,
              fus_pag_id: 0,
              fus_triggerDetails: "",
              fus_uniqueVisitorId: 0,
              onClickAdd: onClickAdd,
              onDelete: onDelete,
              onEdit: onEdit,
            },
          };
          rfInstance.addNodes(newNode);
          let newEdge = { id: `e${splitNode.id}-${idNode}`, source: splitNode.id, target: idNode };
          setEdges((eds) => addEdge(newEdge, eds));
          pagesPerc.push({ NodeId: idNode.toString(), Percentage: 100 / data.fus_pag_id });
        }
        splitNode.data.fus_additionalData = JSON.stringify(pagesPerc);
        //  console.log(splitNode);
      }

      rfInstance.setCenter(position.x, position.y);
    },
    [rfInstance, setEdges, selectedNodes]
  );

  useEffect(() => {
    if (props.nodes.length === 0) return;
    let newNodes = [];
    props.nodes.map((node) => {
      node.data.onClickAdd = onClickAdd;
      node.data.onDelete = onDelete;
      node.data.onEdit = onEdit;
      newNodes.push(node);
    });
    newNodes.length > 0 ? (nodeId = Number(newNodes[0].id) + 1) : (nodeId = 0);
    setNodes(newNodes);
    setEdges(props.edges);
  }, [props.nodes]);

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onNodesDelete={onNodesDelete}
      isValidConnection={isValidConnection}
      fitView
    ></ReactFlow>
  );
});

export default Flow;
