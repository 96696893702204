import GET_IMAGE_DEFINITION from "./image/image";
import GET_VIDEO_DEFINITION from "./video/video";
import { CONTAINER_DEFINITION } from "./container";
export default function myNewComponentTypes(editor) {
  const domc = editor.DomComponents;
  editor.DomComponents.addType("container", CONTAINER_DEFINITION);
  editor.DomComponents.addType("custom-image", GET_IMAGE_DEFINITION(editor));
  editor.DomComponents.addType("custom-video", GET_VIDEO_DEFINITION(editor));
  editor.DomComponents.addType("custom-video", GET_VIDEO_DEFINITION(editor));
  // editor.DomComponents.addType("custom-video", {
  //   model: {
  //     defaults: {
  //       type: "custom-video",
  //       tagName: "div",
  //       void: true,
  //       droppable: 0,
  //       editable: 1,
  //       highlightable: 0,

  //       resizable: { ratioDefault: 1 },
  //       traits: ["alt"],
  //       style: {
  //         position: "relative",
  //       },
  //       components: [
  //         {
  //           type: "default",
  //           classes: ["video-plh-image"],
  //           style: {
  //             height: "300px",
  //             "background-color": "white",
  //             "z-index": "1",
  //             width: "100%",
  //             position: "absolute",
  //           },
  //         },
  //         {
  //           type: "video",
  //         },
  //       ],
  //     },
  //     handlePropChange(me) {
  //       console.warn(me);
  //     },

  //     // isComponent(el) {
  //     //   return toLowerCase(el.tagName) === 'img';
  //     // },
  //     view: {
  //       // Style the wrapper div if needed
  //       style: {
  //         "min-height": "148px",

  //         "justify-content": "center",
  //         width: "100%",
  //         display: "flex", // Example: display as inline-block
  //         // Add more styles as needed
  //       },
  //     },
  //   },
  // });
}
