/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import howImage from "assets/images/1.d89cc93d7fbd4ab64d54.gif";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import DNSSettings from "./components/DnsSettings";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Icon,
  InputAdornment,
} from "@material-ui/core";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import isValidDomain from "is-valid-domain";
import StatusCell from "components/StatusCell";
import CopyToClipboard from "react-copy-to-clipboard";

function getSteps() {
  return ["DNS", "Domain", "Connect", "Finish"];
}

function AddDomain() {
  const [domain, setDomain] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [verified, setVerified] = useState(true);
  const [showMe, setShowMe] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [responseResult, setResponseResult] = useState(null);
  const [tokenTxt, setTokenTxt] = useState("");
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const navigate = useNavigate();

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        domain: {
          // name the rule
          message: "Please entera valid domain",
          rule: (val, params, validator) => {
            return isValidDomain(val);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );

  //  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(activeStep + 1);
        break;
      case 1:
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setActiveStep(activeStep + 1);
        break;
      case 3:
        navigate("/domains");
        break;
    }
  };

  useEffect(() => {
    if (activeStep == 2) verify();
    if (activeStep == 3) save();
  }, [activeStep]);

  useEffect(() => {
    getTxt();
  }, []);

  const options = {
    method: "GET",
    url: "domains",
    headers: {
      "content-type": "application/json",
    },
  };

  const verify = async () => {
    setIsVerifing(true);
    options.method = "GET";
    options.url = `domains/verify?domain=${domain}`;
    await client
      .request(options)
      .then((response) => {
        setIsVerifing(false);
        setResponseResult(response);
        if (
          response.a_record === false ||
          response.txt_record === false ||
          response.two_a_records === true
        ) {
          setVerified(false);
        } else {
          setVerified(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTxt = async () => {
    options.method = "GET";
    options.url = `domains/GetInstructions`;
    await client
      .request(options)
      .then((response) => {
        setTokenTxt(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const save = async () => {
    setResponseResult("");
    setIsVerifing(true);
    options.method = "POST";
    options.url = `domains`;
    options.data = JSON.stringify({
      dom_status: 1,
      dom_user_id: 3,
      dom_domain: domain,
    });
    await client
      .request(options)
      .then((response) => {
        setResponseResult(response);
        setIsVerifing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <MDBox>
            <MDBox mx="auto" my={4}>
              <MDBox mb={1}>
                <MDTypography variant="h5" fontWeight="regular">
                  Change your DNS records in your third party domain provider
                </MDTypography>
              </MDBox>
              <MDTypography variant="body2" color="text">
                On your domain provider’s website, first log into your account, then locate the DNS
                settings or domain management area and change the following records. Once done,
                please check the &quot;confirmed&quot; box below.
              </MDTypography>
              <FormControlLabel
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
                control={<Checkbox checked={confirmed} onChange={() => setConfirmed(!confirmed)} />}
                label={
                  <MDTypography variant="h6" fontWeight="medium">
                    Confirmed
                  </MDTypography>
                }
              />
              <MDBox display="flex" justifyContent="center">
                <MDTypography variant="body2" color="text">
                  Update the A record in your third party domain provider OR create a new A record
                  if there is no existing one with the following values.
                </MDTypography>
                <MDButton onClick={() => setShowMe(true)}>
                  <MDTypography variant="body2" color="info">
                    Show me how!
                  </MDTypography>
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox mt={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Type
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Name / Host Name
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        IP Address / Value
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        A
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        @
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        {process.env.REACT_APP_DOMAIN_DNS_RECORD}
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        TXT
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        _{process.env.REACT_APP_PLNAME}
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        {tokenTxt}
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MDBox>
          </MDBox>
        );
      case 1:
        return (
          <MDBox>
            <MDBox mx="auto" my={4}>
              <MDBox mb={1}>
                <MDTypography variant="h5" fontWeight="regular">
                  Enter your domain
                </MDTypography>
                <MDBox color="red">
                  {simpleValidator.current.message("Domain", domain, "domain")}
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                autoComplete="domain"
                type={"text"}
                label="Domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <Icon>language</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
          </MDBox>
        );
      case 2:
        return isVerifing ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CircularProgress size={60} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h5" color text>
              Connecting domain...
            </MDTypography>
          </MDBox>
        ) : verified ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />
            <MDTypography variant="h5" color="success">
              Domain connected
            </MDTypography>
            {responseResult?.ssl == false && (
              <MDTypography variant="h5" color="warning">
                There may be a delay creating a certificate for your domain, but do not worry, an
                tech on our team has been notified about it. We will be closely monitoring the
                process to make sure it gets issued as fast as possible
              </MDTypography>
            )}
          </MDBox>
        ) : (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <HighlightOffIcon color="error" fontSize="large" />
            <MDTypography variant="h5" color="error">
              Domain Connection Failed
            </MDTypography>
            <MDTypography variant="h6" color="error">
              If you have recently updated your domain records, please note that it may take 24-48
              hours for the changes to be propagated throughout the internet. Please try again after
              allowing time for propagation.
            </MDTypography>
            {responseResult?.two_a_records === true && (
              <MDTypography variant="h6" color="error">
                We have detected that you have two A records set up for your domain. Make sure to
                remove the record that is not pointing to our IP address (
                {process.env.REACT_APP_DOMAIN_DNS_RECORD}).
              </MDTypography>
            )}

            <MDTypography variant="h6" mt={5}>
              Make sure you have the following DNS records set:
            </MDTypography>
            <MDBox mt={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Type
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Name / Host Name
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="left">
                        IP Address / Value
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {responseResult?.a_record === true ? (
                        <StatusCell icon="done" color="success" />
                      ) : (
                        <StatusCell icon="close" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        A
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        @
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDBox display="flex" justifyContent="left">
                        <CopyToClipboard text={process.env.REACT_APP_DOMAIN_DNS_RECORD}>
                          <IconButton
                            sx={{ fontWeight: "bold" }}
                            color="primary"
                            aria-label="prompt"
                          >
                            <Tooltip id="button-report" title="Copy to clipboard">
                              <ContentCopyIcon />
                            </Tooltip>
                          </IconButton>
                        </CopyToClipboard>
                        <MDTypography variant="body2" color="text" mt={1}>
                          {" "}
                          {process.env.REACT_APP_DOMAIN_DNS_RECORD}
                        </MDTypography>
                      </MDBox>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {responseResult?.txt_record === true ? (
                        <StatusCell icon="done" color="success" />
                      ) : (
                        <StatusCell icon="close" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        TXT
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        _{process.env.REACT_APP_PLNAME}
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDBox display="flex" justifyContent="left">
                        <CopyToClipboard text={tokenTxt}>
                          <IconButton
                            sx={{ fontWeight: "bold" }}
                            color="primary"
                            aria-label="prompt"
                          >
                            <Tooltip id="button-report" title="Copy to clipboard">
                              <ContentCopyIcon />
                            </Tooltip>
                          </IconButton>
                        </CopyToClipboard>
                        <MDTypography variant="body2" color="text" textAlign="center" mt={1}>
                          {tokenTxt}
                        </MDTypography>
                      </MDBox>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MDBox>
          </MDBox>
        );
      case 3:
        return isVerifing ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CircularProgress size={60} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h5" color text>
              Saving domain ...
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />
            <MDTypography variant="h5" color="success">
              Domain saved
            </MDTypography>
          </MDBox>
        );
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog open={showMe} fullWidth maxWidth="lg">
        <DialogContent>
          <img src={howImage} alt="how" />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setShowMe(false)}>Close</MDButton>
        </DialogActions>
      </Dialog>
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add a new domain
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ||
                    activeStep === 3 ||
                    (activeStep === 2 &&
                      responseResult?.txt_record === true &&
                      responseResult?.a_record === true &&
                      responseResult?.two_a_records === false) ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="outlined"
                        color="dark"
                        onClick={handleBack}
                        disabled={
                          (activeStep === 2 &&
                            responseResult?.txt_record === true &&
                            responseResult?.a_record === true &&
                            responseResult?.two_a_records === false) ||
                          isVerifing
                        }
                      >
                        back
                      </MDButton>
                    )}
                    <MDBox>
                      {activeStep === 2 &&
                        (responseResult?.txt_record === false ||
                          responseResult?.a_record === false ||
                          responseResult?.two_a_records === true) && (
                          <MDButton
                            style={{ marginRight: 10 }}
                            variant={"outlined"}
                            color="secondary"
                            onClick={() => navigate("/domains")}
                          >
                            Close
                          </MDButton>
                        )}
                      <MDButton
                        disabled={
                          (activeStep === 2 &&
                            (responseResult?.txt_record === false ||
                              responseResult?.a_record === false ||
                              responseResult?.two_a_records === true)) ||
                          !confirmed ||
                          (activeStep === 1 && !isValidDomain(domain)) ||
                          isVerifing
                        }
                        variant={isLastStep ? (verified ? "outlined" : "gradient") : "gradient"}
                        color={isLastStep ? (verified ? "secondary" : "error") : "success"}
                        onClick={handleNext}
                      >
                        {isLastStep
                          ? verified
                            ? "close"
                            : "cancel"
                          : activeStep === 2
                          ? "save"
                          : "next"}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <DNSSettings />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddDomain;
