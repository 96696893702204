import { useMaterialUIController } from "context";
import {
  Grid,
  List,
  ListSubheader,
  ListItemText,
  MenuItem,
  Select,
  Icon,
  FormControl,
  CircularProgress,
  Divider,
  makeStyles,
  Dialog,
  FormControlLabel,
  Checkbox,
  CardHeader,
} from "@material-ui/core";
import { FeatureFlags } from "context/FeatureFlags";
import SimpleReactValidator from "simple-react-validator";
import MDInput from "components/MDInput";
import { Chip, ListItemButton, TextField, Tooltip, Card } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import { useContext, useEffect, useRef, useState } from "react";
import "reactflow/dist/style.css";
import { setHideSidenav } from "context";
import { ReactFlowProvider } from "reactflow";
import client from "ApiClient";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Flow from "./components/Flow";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Page from "./page";
import MDSnackbar from "components/MDSnackbar";
import ConfirmationAlert from "components/ConfirmationAlert";

// import EmailCopy from "./emailCopy";

const useStyles = makeStyles((themes) => ({
  formControl: {
    margin: themes.spacing(1),
    minWidth: 120,
    maxWidth: 600,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: themes.spacing(3),
  },
}));

/*eslint-disable*/
export default function Add(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmationClose, setOpenConfirmationClose] = useState(false);
  const [openUnpublish, setOpenUnpublish] = useState(false);
  const [showFirst, setShowFirst] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showAddFirst, setShowAddFirst] = useState(false);
  const [showAddPage, setShowAddPage] = useState(false);
  const [showAddSplit, setShowAddSplit] = useState(false);
  const [showEditSplit, setShowEditSplit] = useState(false);
  const [showAddWebHook, setShowAddWebHook] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [showAddCopy, setShowAddCopy] = useState(false);
  const [showPercError, setShowPercError] = useState(false);
  const [openEmailCopy, setOpenEmailCopy] = useState(false);
  const [saveEmailCopy, setSaveEmailCopy] = useState(false);

  const [openPage, setOpenPage] = useState(false);
  const [savePage, setSavePage] = useState(false);
  const [openPublishSB, setOpenPublishSB] = useState(false);
  const closePublishSB = () => setOpenPublishSB(false);

  const [updateAction, setUpdateAction] = useState(false);

  const { openModal, closeModal } = props;
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [domains, setDomains] = useState([]);
  const [funnel, setFunnel] = useState(null);

  const [selectedURLMethod, setSelectedURLMethod] = useState(0);
  const [triggerTargetURL, setTriggerTargetURL] = useState("");
  const [triggerBody, setTriggerBody] = useState("");
  const [tags, setTags] = useState([]);

  const [suggestionsTag, setSuggestionsTag] = useState([]);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [emailCopies, setEmailCopies] = useState([]);
  const [selectedEmailCopy, setSelectedEmailCopy] = useState(null);
  const [selectedSplitPages, setSelectedSplitPages] = useState(null);
  const [pages, setPages] = useState([]);
  const [splitPages, setSplitPages] = useState(2);
  const [selectedPage, setSelectedPage] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [images, setImages] = useState([]);

  const [, forceUpdate] = useState();
  const refFlow = useRef();

  const simpleValidator = useRef(new SimpleReactValidator());
  const { features } = useContext(FeatureFlags);
  const filter = createFilterOptions();

  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const [windowsHeigth, setWindowsHeigth] = useState(window.innerHeight - 20);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [publishOpen, setPublishOpen] = useState(false);
  const [publishOnRoot, setPublishOnRoot] = useState(false);
  const [openPublishError, setOpenPublishError] = useState(false);
  const [publishText, setPublishText] = useState("");
  const closePublishError = () => setOpenPublishError(false);

  const classes = useStyles();

  const account = JSON.parse(localStorage.getItem("account"));

  const [triggerMethod] = useState([
    { method: "GET", value: 0 },
    { method: "POST", value: 1 },
    { method: "PUT", value: 2 },
    { method: "DELETE", value: 3 },
  ]);

  const clearVariables = (reason) => {
    if (reason === "backdropClick") return;
    setUpdateAction(false);
    setShowAddFirst(false);
    setShowItems(false);
    setShowAddPage(false);
    setShowAddSplit(false);
    setShowAddWebHook(false);
    setShowAddTag(false);
    setShowAddCopy(false);
    setShowFirst(false);

    setSelectedNodes([]);
    setSelectedURLMethod(0);
    setTriggerTargetURL("");
    setTriggerBody("");
    setTags([]);
    setEmailCopies([]);
    setSelectedEmailCopy(null);

    setName("");
    setDescription("");
    simpleValidator.current.hideMessages();
    navigate("/funnels");
    window.location.reload();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      const options = {
        method: "PUT",
        url: `funnels`,
        headers: {
          "content-type": "application/json",
        },
      };

      const account = JSON.parse(localStorage.getItem("account"));
      let autJson = refFlow.current.onSave();
      autJson.nodes.map((node) => {
        node.selected = false;
      });

      if (
        autJson.nodes.filter(
          (node) => node.data.fus_type == "FUNNELITEM_PAGE" && node.data.fus_pag_id == 0
        ).length > 0
      ) {
        alert("Please select a page for the split test");
        return;
      }

      options.data = JSON.stringify({
        fun_status: funnel.fun_status,
        fun_name: name,
        fun_description: description,
        fun_id: funnel.fun_id,
        fun_creationDate: funnel.fun_creationDate,
        fun_user_id: funnel == null ? 0 : funnel.fun_user_id,
        fun_url: funnel.fun_status == 0 ? funnel.fun_url : "",
        fun_json: JSON.stringify(autJson),
        fun_slug: slug,
      });

      //console.log(autJson);

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          //  props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const unpublish = () => {
    setIsLoading(true);

    const options = {
      method: "PUT",
      url: `funnels`,
      headers: {
        "content-type": "application/json",
      },
    };

    let autJson = refFlow.current.onSave();
    autJson.nodes.map((node) => {
      node.selected = false;
    });

    options.data = JSON.stringify({
      fun_status: 3,
      fun_id: funnel.fun_id,
      fun_creationDate: funnel.fun_creationDate,
      fun_name: name,
      fun_description: description,
      fun_user_id: funnel == null ? 0 : funnel.fun_user_id,
      fun_url: "",
      fun_json: JSON.stringify(autJson),
      fun_slug: slug,
    });

    client
      .request(options)
      .then((response) => {
        setOpenUnpublish(false);
        setIsLoading(false);
        clearVariables();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const publish = () => {
    setIsLoading(true);

    options.method = "PUT";
    options.url = `funnels`;

    let autJson = refFlow.current.onSave();
    autJson.nodes.map((node) => {
      node.selected = false;
    });

    if (
      autJson.nodes.filter(
        (node) => node.data.fus_type == "FUNNELITEM_PAGE" && node.data.fus_pag_id == 0
      ).length > 0
    ) {
      setPublishText("Please make sure you have created pages for all splits");
      setOpenPublishError(true);
      return;
    }

    options.data = JSON.stringify({
      fun_status: 0,
      fun_id: funnel.fun_id,
      fun_creationDate: funnel.fun_creationDate,
      fun_name: name,
      fun_description: description,
      fun_user_id: funnel == null ? 0 : funnel.fun_user_id,
      fun_url:
        selectedDomain == process.env.REACT_APP_MAIN_DOMAIN
          ? publishOnRoot
            ? `https://${account.user_subDomain}.${process.env.REACT_APP_MAIN_DOMAIN}`
            : `https://${account.user_subDomain}.${process.env.REACT_APP_MAIN_DOMAIN}/${slug}`
          : publishOnRoot
          ? `https://${selectedDomain}`
          : `https://${selectedDomain + (slug != "" ? "/" + slug : "")}`,
      fun_json: JSON.stringify(autJson),
      fun_slug: slug,
    });

    client
      .request(options)
      .then((response) => {
        if (response == "Slug already exists" || response == undefined) {
          setPublishText("There is already a page at this url");
          setOpenPublishError(true);
        } else {
          setFunnel(response);
          setPublishText(`Your funnel has been published at ${response.fun_url}`);
          setOpenPublishSB(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDomains = async () => {
    options.method = "GET";
    options.url = `domains`;
    await client
      .request(options)
      .then((response) => {
        const domains = response.filter((f) => f.dom_status == 0).map((d) => d.dom_domain);
        domains.push(process.env.REACT_APP_MAIN_DOMAIN);
        setDomains(domains);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getImages = async () => {
    options.method = "GET";
    options.url = `grapeai/getImages`;
    await client
      .request(options)
      .then((response) => {
        //console.log(response);
        setImages(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClickAdd = (event, data) => {
    setSelectedPage(null);
    setUpdateAction(false);
    setShowAddFirst(false);
    setShowFirst(false);
    setShowAddPage(false);
    setShowAddSplit(false);
    setShowAddWebHook(false);
    setShowAddTag(false);
    setShowAddCopy(false);
    setShowEditSplit(false);
    setSelectedNodes([data]);
    switch (event) {
      case 0:
        setSelectedPage(null);
        setOpenPage(true);
        setShowFirst(false);
        setShowItems(false);
        break;
      case 1:
        setShowFirst(false);
        setShowItems(false);
        setShowAddSplit(true);
        break;
      case 2:
        setShowFirst(false);
        setShowItems(false);
        setShowAddWebHook(true);
        break;
    }
  };

  const onClickEdit = (event, data) => {
    setSelectedNodes([data]);
    setOpenPage(true);
  };

  const addEvent = (event) => {
    const data = {
      fus_id: 0,
      fus_step_id: 0,
      fus_uniqueVisitorId: 0,
      fus_type: "",
      fus_fun_id: 0,
      fus_pag_id: 0,
      fus_triggerDetails: "",
      fus_additionalData: "",
    };

    switch (event) {
      case "splitTest":
        data.fus_type = "FUNNELITEM_SPLITTEST";
        data.fus_pag_id = splitPages;
        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddEvent(data);
        setShowAddSplit(false);
        break;
      case "page":
        data.fus_type = "FUNNELITEM_PAGE";
        data.fus_pag_id = selectedPage.pag_id;
        data.fus_additionalData = selectedPage.pag_name;
        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddEvent(data);
        setShowAddPage(false);
        setSavePage(false);
        break;
    }
    //setShowFirst(true);
  };

  const addAction = (event) => {
    if (selectedNodes.length == 0) {
      return;
    }
    // console.log(selectedNodes[0]);
    const data = {
      fus_id: 0,
      fus_step_id: 0,
      fus_uniqueVisitorId: 0,
      fus_type: "",
      fus_fun_id: 0,
      fus_pag_id: 0,
      fus_triggerDetails: "",
      fus_additionalData: "",
    };
    switch (event) {
      case "splitTest":
        data.fus_type = "FUNNELITEM_SPLITTEST";
        data.fus_pag_id = splitPages;
        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddAction(data);
        setShowAddSplit(false);
        break;
      case "page":
        data.fus_type = "FUNNELITEM_PAGE";
        data.fus_pag_id = selectedPage.pag_id;
        data.fus_additionalData = selectedPage.pag_name;
        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddAction(data);
        setShowAddPage(false);
        setSavePage(false);
        break;
      case "webHook":
        data.fus_type = "FUNNELITEM_CALLURL";
        data.fus_triggerDetails = JSON.stringify({
          tri_targetURL: triggerTargetURL,
          tri_body: triggerBody,
          tri_method: selectedURLMethod,
        });

        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddAction(data);

        setTriggerTargetURL("");
        setTriggerBody("");
        setSelectedURLMethod(0);
        setShowAddWebHook(false);
        break;
      case "sendCopy":
        data.fus_type = "FUNNELITEM_EMAIL";
        data.fus_emc_id = selectedEmailCopy.emc_id;
        data.fus_additionalData = selectedEmailCopy.emc_subject;
        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddAction(data);
        setSaveEmailCopy(false);
        break;
      case "addTag":
        data.fus_type = "FUNNELITEM_ADDSUBSCRIBER";
        data.fus_tag = tags.join(",");
        if (updateAction) {
          refFlow.current.onUpdate(selectedNodes[0], data);
          setUpdateAction(false);
        } else refFlow.current.onAddAction(data);
        setShowAddTag(false);
        setTags([]);
        break;
    }
  };

  const nodeSelect = (n) => {
    setUpdateAction(false);
    setShowFirst(false);
    setShowItems(false);
    setShowAddPage(false);
    setShowAddSplit(false);
    setShowAddWebHook(false);
    setShowAddTag(false);
    setShowAddCopy(false);
    setOpenEmailCopy(false);
    setShowAddTag(false);
    setShowEditSplit(false);

    setSelectedNodes([]);
    setSelectedURLMethod(0);
    setTriggerTargetURL("");
    setTriggerBody("");
    setTags([]);
    setSelectedEmailCopy(null);
    setSelectedPage(null);

    if (n.length == 0) {
      // setShowFirst(true);
      return;
    }

    setSelectedNodes(n);
    let node = n[0];

    switch (node.data.fus_type) {
      case "FUNNELITEM_SPLITTEST":
        setSelectedSplitPages([]);
        const splitData = JSON.parse(node.data.fus_additionalData);
        const pageNodes = refFlow.current
          ?.getNodes()
          .filter((n) => n.data.fus_type == "FUNNELITEM_PAGE");

        splitData.map((item, index) => {
          const page = pageNodes.filter((p) => p.id == item.NodeId)[0];
          setSelectedSplitPages((prev) => [
            ...prev,
            {
              NodeId: item.NodeId,
              Percentage: item.Percentage,
              Page: page.data.fus_additionalData,
            },
          ]);
        });
        setShowEditSplit(true);
        break;
      case "FUNNELITEM_PAGE":
        setShowAddPage(true);
        setUpdateAction(true);
        if (node.data.fus_pag_id > 0) {
          setSelectedPage(pages.filter((p) => p.pag_id == node.data.fus_pag_id)[0]);
        } else {
          setSelectedPage(null);
        }
        break;
      case "FUNNELITEM_CALLURL":
        const triggerDetails = JSON.parse(node.data.fus_triggerDetails);
        setTriggerTargetURL(triggerDetails.tri_targetURL);
        setTriggerBody(triggerDetails.tri_body);
        setSelectedURLMethod(triggerDetails.tri_method);
        setShowAddWebHook(true);
        setUpdateAction(true);
        break;
      case "FUNNELITEM_EMAIL":
        setShowAddCopy(true);
        setUpdateAction(true);
        setSelectedEmailCopy(emailCopies.filter((ec) => ec.emc_id == node.data.fus_emc_id)[0]);
        break;
      case "FUNNELITEM_ADDSUBSCRIBER":
        setTags(node.data.fus_tag.split(","));
        setUpdateAction(true);
        setShowAddTag(true);
        break;
      default:
        //  setShowItems(true);
        break;
    }
  };

  const changePageSlug = (event) => {
    const slugPage = event.target.value;
    const regex = new RegExp("^[a-zA-Z_]+$");
    if (regex.test(slugPage)) setSlug(slugPage);
    else if (slugPage == "") setSlug("");
    else setSlug(slug);
  };

  const options = {
    method: "GET",
    url: `broadcasts/getTags`,
    headers: {
      "content-type": "application/json",
    },
  };

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <Chip
            label="Published"
            color="success"
            size="small"
            style={{ backgroundColor: "#4caf50", color: "#fff", width: "100px" }}
          />
        );
      case 3:
        return (
          <Chip
            label="Draft"
            color="info"
            size="small"
            style={{ backgroundColor: "#0088F5", color: "#fff", width: "100px" }}
          />
        );
    }
  };

  const getFunnelPages = async (id) => {
    const options = {
      method: "GET",
      url: `pages/getFunnelPages?fun_id=${id}`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setPages(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFunnel = async () => {
    options.method = "GET";
    options.url = `funnels/getById?id=${searchParams.get("id")}`;
    await client
      .request(options)
      .then((response) => {
        setFunnel(response);
        setName(response.fun_name);
        setDescription(response.fun_description);
        setSlug(response.fun_slug);

        if (response.fun_json != "") {
          getFunnelPages(response.fun_id);
          const json = JSON.parse(response.fun_json);
          setNodes(json.nodes);
          setEdges(json.edges);
        } else {
          setShowFirst(true);
        }

        getDomains();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePercentage = (value, index) => {
    const nodeData = selectedNodes[0].data;
    let splitData = JSON.parse(nodeData.fus_additionalData);

    splitData[index].Percentage = value;
    nodeData.fus_additionalData = JSON.stringify(splitData);

    refFlow.current.onUpdate(selectedNodes[0], nodeData);

    selectedSplitPages[index].Percentage = value;
    setSelectedSplitPages([...selectedSplitPages]);

    let totalPerc = 0;
    selectedSplitPages.map((item, index) => {
      totalPerc += parseInt(item.Percentage);
    });
    if (totalPerc != 100) setShowPercError(true);
    else setShowPercError(false);
  };

  useEffect(() => {
    setHideSidenav(dispatch, true);
    getFunnel();
    getImages();

    return () => {
      setHideSidenav(dispatch, false);
    };
  }, []);

  useEffect(() => {
    refFlow.current?.getNodes().length == 0 ? setShowFirst(true) : setShowFirst(false);
  }, [refFlow.current?.getNodes().length]);

  useEffect(() => {
    if (saveEmailCopy) addAction("sendCopy");
  }, [selectedEmailCopy]);

  useEffect(() => {
    setWindowsHeigth(window.innerHeight);
  }, [window.innerWidth]);

  useEffect(() => {
    if (savePage) {
      if (refFlow.current?.getNodes().length > 0) addAction("page");
      else addEvent("page");
    }
  }, [selectedPage]);

  useEffect(() => {
    const sDomain =
      funnel?.fun_url == ""
        ? process.env.REACT_APP_MAIN_DOMAIN
        : funnel?.fun_url.includes(process.env.REACT_APP_MAIN_DOMAIN)
        ? process.env.REACT_APP_MAIN_DOMAIN
        : funnel?.fun_url.split("://")[1].split("/")[0];
    setSelectedDomain(sDomain);
  }, [domains]);

  useEffect(() => {
    selectedDomain == process.env.REACT_APP_MAIN_DOMAIN ?? setPublishOnRoot(false);
  }, [selectedDomain]);

  useEffect(() => {
    if (funnel?.fun_status == 0) {
      if (
        funnel?.fun_url.split("//")[1].replace(`${account.user_subDomain}.`, "") ==
        process.env.REACT_APP_MAIN_DOMAIN
      )
        setPublishOnRoot(true);
      domains.map((domain) => {
        console.log(
          `${account.user_subDomain}.`,
          domain,
          funnel?.fun_url.split("//")[1].replace(`${account.user_subDomain}.`, "")
        );
        if (funnel?.fun_url.split("//")[1] == domain) setPublishOnRoot(true);
      });
    }
  }, [publishOpen]);

  const changePagTitle = (e) => {
    setSelectedPage({ ...selectedPage, pag_name: e.target.value });
    const nodeData = selectedNodes[0].data;
    nodeData.fus_additionalData = e.target.value;
    refFlow.current.onUpdate(selectedNodes[0], nodeData);

    const index = pages.findIndex((pag) => pag.pag_id === selectedPage.pag_id);
    const pagesAux = [...pages]; // important to create a copy, otherwise you'll modify state outside of setState call
    pagesAux[index] = { ...selectedPage, pag_name: e.target.value };
    setPages(pagesAux);
    updatePage(pagesAux[index]);
  };

  const updatePage = (page) => {
    options.method = "PUT";
    options.url = `pages`;
    options.data = JSON.stringify(page);

    client
      .request(options)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <MDSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="warning"
        icon="error"
        title="Error"
        content={publishText}
        dateTime=""
        open={openPublishError}
        onClose={closePublishError}
        close={closePublishError}
        backgroundcolor={"error"}
      />
      <MDSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="success"
        icon="success"
        title="Success"
        content={publishText}
        dateTime={""}
        open={openPublishSB}
        onClose={closePublishSB}
        close={closePublishSB}
        bgWhite
      />
      <ConfirmationAlert
        openModal={openUnpublish}
        onClose={() => setOpenUnpublish(false)}
        onYes={() => unpublish()}
        yesText="Yes"
        title={`Are you sure you want to unpublish your funnel (${funnel?.fun_name}) from "${funnel?.fun_url}" ?`}
      />
      <ConfirmationAlert
        openModal={openConfirmationClose}
        isLoading={isLoading}
        title={
          <MDBox>
            <MDBox>
              Are you sure you want to close the funnel builder? Any unsaved changes will be lost.
            </MDBox>
          </MDBox>
        }
        onYes={() => {
          clearVariables();
        }}
        onClose={() => setOpenConfirmationClose(false)}
      />
      <Dialog open={publishOpen}>
        <Card style={{ padding: 20 }}>
          Select your destination
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                autofillBackgroundColor="highlight"
                disabled={isLoading}
                variant="outlined"
                defaultValue={
                  funnel?.fun_url != ""
                    ? funnel?.fun_url.includes(process.env.REACT_APP_MAIN_DOMAIN)
                      ? process.env.REACT_APP_MAIN_DOMAIN
                      : funnel?.fun_url.split("://")[1].split("/")[0]
                    : process.env.REACT_APP_MAIN_DOMAIN
                }
                options={domains}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
                fullWidth
                onChange={(e, value) => setSelectedDomain(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={publishOnRoot}
                    onChange={() => setPublishOnRoot(!publishOnRoot)}
                  />
                }
                label="Publish on root domain"
              />
              {!publishOnRoot && (
                <MDBox>
                  <MDInput
                    style={{ padding: 10 }}
                    fullWidth
                    type="text"
                    disabled={isLoading}
                    label="Page Slug"
                    placeholder="Only letters and underscores"
                    value={slug}
                    onChange={(e) => changePageSlug(e)}
                  />
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="caption" color="textSecondary">
                {selectedDomain == process.env.REACT_APP_MAIN_DOMAIN
                  ? publishOnRoot
                    ? `https://${account.user_subDomain}.${process.env.REACT_APP_MAIN_DOMAIN}`
                    : `https://${account.user_subDomain}.${process.env.REACT_APP_MAIN_DOMAIN}/${slug}`
                  : publishOnRoot
                  ? `https://${selectedDomain}`
                  : `https://${selectedDomain + (slug != "" ? "/" + slug : "")}`}
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              {publishText != "" && (
                <>
                  {publishText}
                  {isLoading && (
                    <CircularProgress
                      size={30}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <MDButton
                color="success"
                variant="gradient"
                onClick={publish}
                disabled={isLoading}
                style={{ color: "#FFF", padding: 10 }}
              >
                {`Publish on selected domain`}
              </MDButton>
              <MDButton
                onClick={() => {
                  setPublishOpen(!publishOpen);
                }}
                variant="outlined"
                color="secondary"
                disabled={isLoading}
                style={{
                  marginLeft: 10,
                }}
              >
                Close
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
      <MDBox style={{ height: windowsHeigth - 250, width: window.innerWidth, padding: 10 }}>
        <MDBox
          variant="gradient"
          bgColor="secondary"
          coloredShadow="secondary"
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Funnel Builder
          </MDTypography>
        </MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card style={{ padding: 10, backgroundColor: "#FFFFFF", height: "100%" }}>
              {funnel && (
                <Grid item xs={12} md={12}>
                  <MDBox display="flex" style={{ marginLeft: 10, marginBottom: 20 }}>
                    <MDBox display="block">{getStatus(funnel.fun_status)}</MDBox>
                    {funnel && funnel.fun_status == 0 && (
                      <MDBox display="block">
                        <MDTypography
                          component={Link}
                          to={funnel.fun_url}
                          target="_blank"
                          variant="button"
                          color="info"
                          fontWeight="medium"
                          textGradient
                          style={{ marginLeft: 10 }}
                        >
                          {funnel.fun_url}
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                </Grid>
              )}
              <MDBox display="flex"></MDBox>
              <MDBox display="flex" style={{ marginTop: 10 }}>
                <MDButton
                  onClick={() => setOpenConfirmationClose(true)}
                  disabled={isLoading}
                  variant="outlined"
                  color="secondary"
                  style={{ marginLeft: 10 }}
                >
                  Close
                </MDButton>
                <MDButton
                  onClick={handleSave}
                  disabled={isLoading}
                  variant="gradient"
                  color="success"
                  style={{ marginLeft: 10 }}
                >
                  Save
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </MDButton>
                {refFlow.current?.getNodes().length > 0 && (
                  <MDButton
                    size="small"
                    disabled={isLoading}
                    variant="gradient"
                    color="success"
                    onClick={() => setPublishOpen(true)}
                    style={{ marginLeft: 10 }}
                  >
                    {funnel && funnel.fun_status == 3 ? "publish in domain" : "change domain"}
                  </MDButton>
                )}
                {funnel && funnel.fun_status == 0 && (
                  <MDButton
                    disabled={isLoading}
                    variant="outlined"
                    color="warning"
                    onClick={() => setOpenUnpublish(true)}
                    size="small"
                    style={{ marginLeft: 10 }}
                  >
                    unpublish
                  </MDButton>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={3}>
            <Card style={{ padding: 10, backgroundColor: "#FFFFFF", height: "100%" }}>
              <MDBox>
                <MDInput
                  style={{ padding: 10 }}
                  type="text"
                  label="Funnel name"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox>
                <MDInput
                  style={{ padding: 10 }}
                  type="text"
                  label="Description"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Description", description, "required")}
                </MDBox>
              </MDBox>

              <Divider />
              <MDBox mt={2}>
                {showFirst && (
                  <List
                    style={{ overflow: "auto" }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        First node
                      </ListSubheader>
                    }
                  >
                    <ListItemButton
                      key="addPage"
                      onClick={() => {
                        setSelectedPage(null);
                        setShowFirst(false);
                        setShowItems(false);
                        setOpenPage(true);
                      }}
                    >
                      <ListItemText primary="Add a page" secondary="A new page" />
                    </ListItemButton>
                    <ListItemButton
                      key="addSplitTest"
                      onClick={() => {
                        setShowFirst(false);
                        setShowAddSplit(true);
                      }}
                    >
                      <ListItemText primary="Add a split test" secondary="Add a split test" />
                    </ListItemButton>
                  </List>
                )}
                {showItems && (
                  <List
                    style={{ overflow: "auto" }}
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Items
                      </ListSubheader>
                    }
                  >
                    <ListItemButton
                      key="addPage"
                      onClick={() => {
                        setSelectedPage(null);
                        setShowFirst(false);
                        setShowItems(false);
                        setShowAddPage(true);
                      }}
                    >
                      <ListItemText primary="Add a page" secondary="" />
                    </ListItemButton>
                    <ListItemButton
                      key="addSplitTest"
                      onClick={() => {
                        setShowFirst(false);
                        setShowItems(false);
                        setShowAddSplit(true);
                      }}
                    >
                      <ListItemText primary="Add a split test" secondary="Add a split test" />
                    </ListItemButton>
                    {/*<ListItemButton
                      key="webHook"
                      onClick={() => {
                        setShowFirst(false);
                        setShowItems(false);
                        setShowAddWebHook(true);
                      }}
                    >
                      <ListItemText primary="Call a webhook" secondary="Call a webhook" />
                    </ListItemButton>*/}
                  </List>
                )}
                {showAddWebHook && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Call webhook</MDTypography>
                      <MDTypography variant="subtitle2">
                        Call a webhook (external event)
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2} mb={2}>
                      <FormControl className={classes.formControl}>
                        <MDBox pb={2} display="flex" justifyContent="left">
                          <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                            Method
                          </MDTypography>
                          <Select
                            onChange={(event) => setSelectedURLMethod(event.target.value)}
                            value={selectedURLMethod}
                          >
                            {triggerMethod.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.method}
                              </MenuItem>
                            ))}
                          </Select>
                        </MDBox>
                        <MDBox m={2}>
                          <MDInput
                            type="text"
                            label="Target URL"
                            fullWidth
                            value={triggerTargetURL}
                            onChange={(e) => setTriggerTargetURL(e.target.value)}
                            required
                          />
                        </MDBox>
                        <MDBox m={2}>
                          <MDInput
                            type="text"
                            label="JSON body"
                            fullWidth
                            value={triggerBody}
                            onChange={(e) => setTriggerBody(e.target.value)}
                            required
                          />
                        </MDBox>
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddWebHook(false);
                          if (nodes.length == 0) setShowFirst(true);
                          //  setShowItems(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("webHook")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddPage && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    {selectedPage != null && (
                      <MDBox mt={2} mb={2}>
                        {selectedPage.pag_favicon && (
                          <MDTypography
                            component={Link}
                            to={selectedPage.pag_favicon}
                            target="_blank"
                            variant="button"
                            color="info"
                            fontWeight="medium"
                            textGradient
                          >
                            <img
                              src={selectedPage.pag_favicon}
                              style={{ width: 30, height: 30, marginLeft: 10 }}
                            />
                          </MDTypography>
                        )}
                        <MDBox display="flex" mb={2}>
                          <MDTypography variant="h6">Page Name:</MDTypography>
                          <MDTypography variant="body2">{selectedPage.pag_name}</MDTypography>
                        </MDBox>
                        {selectedPage.pag_thumbnailURL && (
                          <MDBox
                            component="img"
                            src={selectedPage.pag_thumbnailURL}
                            alt={selectedPage.pag_name}
                            borderRadius="lg"
                            shadow="md"
                            width="100%"
                            height="100%"
                            position="relative"
                            zIndex={1}
                          />
                        )}
                      </MDBox>
                    )}
                  </Card>
                )}
                {showAddCopy && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Send copy</MDTypography>
                      <MDTypography variant="subtitle2">Send a email copy</MDTypography>
                    </MDBox>
                    {selectedEmailCopy != null && (
                      <MDBox mt={2} mb={2}>
                        <MDBox display="flex">
                          <MDTypography variant="h6">Subject:</MDTypography>
                          <MDTypography variant="body2">
                            {selectedEmailCopy ? selectedEmailCopy.emc_subject : ""}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    )}
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddCopy(false);
                          if (nodes.length == 0) setShowFirst(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      {selectedEmailCopy == null ? (
                        <MDButton
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            setSelectedEmailCopy(null);
                            setOpenEmailCopy(true);
                          }}
                          color="success"
                          variant="gradient"
                        >
                          Create
                        </MDButton>
                      ) : (
                        <MDButton
                          style={{ marginLeft: 5 }}
                          onClick={() => setOpenEmailCopy(true)}
                          color="success"
                          variant="gradient"
                        >
                          Edit
                        </MDButton>
                      )}
                    </MDBox>
                  </Card>
                )}
                {showAddTag && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Add a tag</MDTypography>
                      <MDTypography variant="subtitle2">
                        Add the following tag(s) to the subscriber
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDBox display="flex">
                        <MDTypography variant="h6" fontWeight="medium">
                          Tags
                        </MDTypography>
                        <Tooltip title="Type to search or add a new tag and press enter">
                          <Icon style={{ marginTop: 0, marginLeft: 5 }}>info</Icon>
                        </Tooltip>
                      </MDBox>
                      <Autocomplete
                        value={tags}
                        onChange={(event, newValue) => {
                          setTags(
                            newValue.map((tag) =>
                              tag.indexOf('Create new tag "') === -1
                                ? tag
                                : tag.substring(16).slice(0, -1)
                            )
                          );
                        }}
                        multiple
                        id="tags-filled"
                        options={suggestionsTag.map((option) => option.name)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type to search or add a new tag and press enter"
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue.trim() !== "" && !isExisting) {
                            filtered.push(`Create new tag "${inputValue}"`);
                          }
                          return filtered;
                        }}
                      />
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setShowAddTag(false);
                          setUpdateAction(false);
                          if (nodes.length == 0) setShowFirst(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() => addAction("addTag")}
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showAddSplit && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Split test</MDTypography>
                      <MDTypography variant="subtitle2">
                        Add a split test to the funnel
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2} mb={2}>
                      <FormControl className={classes.formControl}>
                        <MDInput
                          fullWidth
                          required
                          style={{ paddingRight: 20 }}
                          type="number"
                          placeholder="Enter the number of pages"
                          label="Number of pages"
                          value={splitPages}
                          onChange={(e) => {
                            e.target.value < 2 ? setSplitPages(2) : setSplitPages(e.target.value);
                          }}
                        />
                      </FormControl>
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowAddSplit(false);
                          if (nodes.length == 0) setShowFirst(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: 5 }}
                        onClick={() =>
                          selectedNodes.length == 0 ? addEvent("splitTest") : addAction("splitTest")
                        }
                        color="success"
                        variant="gradient"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
                {showEditSplit && (
                  <Card style={{ padding: 5, margin: 5 }}>
                    <MDBox>
                      <MDTypography variant="h4">Split test</MDTypography>
                    </MDBox>
                    {selectedSplitPages != null &&
                      selectedSplitPages.map((item, index) => {
                        return (
                          <MDBox key={index} display="flex">
                            <MDInput
                              label={item.Page}
                              type="number"
                              value={item.Percentage}
                              onChange={(e) => changePercentage(e.target.value, index)}
                              style={{ width: 80, marginTop: 5, margin: 5 }}
                            />
                          </MDBox>
                        );
                      })}
                    <MDBox display="flex">
                      {showPercError && (
                        <MDTypography color="error">
                          The sum of percentages must by 100%
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDBox display="flex">
                      <MDButton
                        onClick={() => {
                          setUpdateAction(false);
                          setShowEditSplit(false);
                          if (nodes.length == 0) setShowFirst(true);
                        }}
                        color="secondary"
                        variant="outlined"
                      >
                        Close
                      </MDButton>
                    </MDBox>
                  </Card>
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={9}>
            <Card style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
              <ReactFlowProvider>
                <Flow
                  ref={refFlow}
                  onSelectNode={(n) => nodeSelect(n)}
                  onClickAdd={(e, node) => onClickAdd(e, node)}
                  onClickEdit={(e, node) => onClickEdit(e, node)}
                  nodes={nodes}
                  edges={edges}
                />
              </ReactFlowProvider>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {openPage && (
        <Page
          openModal={openPage}
          closeModal={() => setOpenPage(false)}
          page={selectedPage}
          pages={pages}
          funnel={funnel}
          images={images}
          selectPage={(pag) => setSelectedPage(pag)}
          onDone={(pag) => {
            if (pag == null) {
              setOpenPage(false);
              if (nodes.length == 0) setShowFirst(true);
              return;
            }
            setOpenPage(false);
            setSavePage(true);
            //console.log("updateAction",updateAction);
            if (updateAction) {
              const idx = pages.findIndex((p) => p.pag_id == pag.pag_id);
              // console.log(idx);
              if (idx < 0) {
                setPages((prev) => [...prev, pag]);
              } else {
                let pags = pages;
                pags[idx] = pag;
                setPages(pags);
              }
            } else {
              setPages((prev) => [...prev, pag]);
            }
            setSelectedPage(pag);
          }}
        />
      )}
    </>
  );
}
