let linkCommandId = 'custom-image-pencil'

export default (editor) => {
    return {
        // extend: "image",
        // extendView: "image",
        // extend: "video",

        model: {

            defaults: {

                type: "custom-video",
                tagName: "div",

                // void: true,
                highlightable: false,

                stylable: false,
                editable: false,
                badgable: false,
                // draggable: false,
                droppable: false,
                resizable: { ratioDefault: 1 },
                traits: ['alt'],
                style: {

                    "position": "relative",
                    "justify-content": "center",
                    padding: "16px",
                    "box-sizing": "border-box",
                    width: "100%",
                    "max-width": "1140px",
                    margin: "12px auto",
                    display: "flex", // Example: display as inline-block
                    // Add more styles as needed

                },
                components: [
                    {
                        tagName: "div", classes: ["custom-video-edit"], components: [{
                            highlightable: false,
                            type: 'play-icon',
                            stylable: false,
                            editable: false,
                            badgable: false,
                            draggable: false,
                            droppable: false,
                            content: `<p style="font-size:48px;color:#3b97e3;font-weight:bold;"><svg  xmlns="http://www.w3.org/2000/svg"  width="64"  height="64"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-player-play"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 4v16l13 -8z" /></svg></p>`
                        }], styleable: false, stylable: false,
                        highlightable: false,
                        draggable: false,
                        stylable: false,
                        editable: false,
                        droppable: false,
                        badgable: false,
                        style: {
                            position: "absolute",
                            "background-color": "white",
                            "display": "flex",
                            "justify-content": 'center',
                            "align-items": "center", border: `2px dashed #3b97e3`,
                            top: 0,
                            left: 0,
                            "pointer-events": "none",
                            width: "100%",
                            height: "100%",
                        }
                    },
                    {
                        droppable: false,
                        draggable: false,
                        type: 'video',
                        style: {
                            // width: "100%",
                            margin: "0 auto",
                            width: "100%",
                            opacity: 0,
                            height: "100%",
                            position: "relative"
                        },

                    },

                ]
            },
            init() {

                const video = this.components().at(1)
                const container = this.components().at(0)

                const me = this
                this.listenTo(video, "change:src change:provider change:videoId ", (value) => {
                    const styles = video.getStyle()
                    const containerStyles = container.getStyle()
                    console.warn('atatat', video.attributes, video.attributes.src, video.attributes.provider, video.attributes.videoId)
                    if ((video.attributes.src && video.attributes.provider == "so") || (video.attributes.provider !== "so" && video.attributes.videoId)) {

                        video.setStyle({ ...styles, opacity: 1 })

                        container.setStyle({ ...containerStyles, border: "none", opacity: 0 })
                    }
                    else {
                        container.setStyle({ ...containerStyles, border: `2px dashed #3b97e3`, opacity: 1 })
                        video.setStyle({ opacity: 0 })
                    }
                })
            },


            // isComponent(el) {
            //   return toLowerCase(el.tagName) === 'img';
            // },

        }, view: {


            // Style the wrapper div if needed

        },
    }

}