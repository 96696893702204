/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/pixapage-logo.png";
import { CircularProgress, Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";

// ApiClient
import client from "ApiClient";
import MDSnackbar from "components/MDSnackbar";
import theme from "assets/theme";
import MSLeftPanel from "components/MSLeftPanel/MSLeftPanel";

export default function Illustration() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passVisible, setPassVisible] = useState(false);
  const [errorContent, setErrorContent] = useState("Invalid email or password");
  const navigate = useNavigate();
  const handleLogin = () => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `users/login`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.params = {
      email: email,
      password: window.btoa(password),
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (response == undefined) {
          setErrorSB(true);
          return;
        }
        if (response == "Unauthorized") {
          setErrorSB(true);
          setErrorContent("Your credentials are wrong.");
        } else if (response.toString().indexOf("http") !== -1) {
          window.location.href = response.replace("/#/", "/");
        } else if (response == "disabled") {
          setErrorContent(
            "Your account has been locked out. Please contact support for assistance"
          );
          setErrorSB(true);
        } else {
          switch (process.env.REACT_APP_MAIN_DOMAIN) {
            case "localhost":
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
            case "sendpaddev.com":
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
            case "sendpad.com":
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
            case "mailsense.ai":
              localStorage.setItem("unlayerID", 205839);
              localStorage.setItem("plName", "mailSense");
              break;
            case "funnelsense.ai":
              localStorage.setItem("unlayerID", 205839);
              localStorage.setItem("plName", "funnelsense");
              break;
            default:
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
          }
          const account = response;
          localStorage.setItem("account", JSON.stringify(account));
          localStorage.setItem("AuthorizationToken", JSON.stringify(response.user_token));
          localStorage.setItem("userName", response.user_name);
          localStorage.setItem(
            "userPicture",
            "https://cdn-icons-png.flaticon.com/512/149/149071.png"
          );

          if (
            account.user_subDomain == null ||
            account.user_subDomain == "" ||
            account.user_first == null ||
            account.user_first == "" ||
            account.user_last == null ||
            account.user_last == "" ||
            account.user_country == null ||
            account.user_country == "" ||
            account.user_city == null ||
            account.user_city == "" ||
            account.user_state == null ||
            account.user_state == ""
          )
            localStorage.setItem("profileCompleted", false);
          else localStorage.setItem("profileCompleted", true);

          navigate("/dashboard");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        setErrorSB(true);
      });
  };

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Login failed"
      content={errorContent}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      backgroundcolor={"error"}
    />
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        xl={6}
        style={{ height: "100vh" }}
        sx={{
          //You can copy the code below in your theme
          background: theme.palette.background.default,
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MSLeftPanel type="sign-in" />
      </Grid>
      <Grid
        item
        xs={12}
        xl={6}
        sx={{
          //You can copy the code below in your theme
          background: "#FFFFFF",
          "& .MuiPaper-root": {
            background: "#FFFFF",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MDBox mt={10} display="flex" justifyContent="center">
          <MDBox component="form" role="form" style={{ width: "40%" }}>
            <MDTypography variant="h2" fontWeight="medium" mb={2}>
              Sign in
            </MDTypography>
            <MDBox mb={2}>
              <MDInput
                autoComplete="email"
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                autoComplete="email"
                type={passVisible ? "text" : "password"}
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setPassVisible(!passVisible)}
                        color="secondary"
                        aria-label="prompt"
                      >
                        {!passVisible ? (
                          <Tooltip title="View password" color="text">
                            <VisibilityIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Hide password" color="text">
                            <VisibilityOffIcon />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox display="flex" justifyContent="right">
              <MDTypography
                component="a"
                href="/forgot"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                Forgot password?
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                disabled={email === "" || password === "" || isLoading}
                variant="gradient"
                color="info"
                size="large"
                fullWidth
                onClick={() => handleLogin()}
              >
                sign in {isLoading && <CircularProgress sx={{marginLeft:5}}/>}
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {renderErrorSB}
      </Grid>
    </Grid>
  );
}
