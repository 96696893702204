import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";

/* elint-disable */
export default function MSLeftPanel(props) {
  const [type] = useState(props);
  console.log(type.type);
  switch (process.env.REACT_APP_PLNAME) {
    case "sendpad":
      switch (type) {
        case "sign-in":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img src="/images/sendpad/sendpad-login-logo.32c527ca.svg" alt="logo" />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <img src="/images/sendpad/login-illustration.d210c087.svg" alt="illustration" />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  SendPad
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "70%" }}
                  display="block"
                  variant="h3"
                  textAlign="center"
                  fontWeight="medium"
                >
                  Create email broadcasts as quickly as the speed of light
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        case "password-reset":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img src="/images/sendpad/sendpad-login-logo.32c527ca.svg" alt="logo" />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <img src="/images/sendpad/signup-illustration.7613a235.svg" alt="illustration" />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  SendPad
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "70%" }}
                  display="block"
                  variant="h3"
                  textAlign="center"
                  fontWeight="medium"
                >
                  Create email broadcasts as quickly as the speed of light
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        case "forgot-done":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img src="/images/sendpad/sendpad-login-logo.32c527ca.svg" alt="logo" />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <img
                  src="/images/sendpad/forgot-password-notification-illustration.965a39a4.svg"
                  alt="illustration"
                />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  SendPad
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "70%" }}
                  display="block"
                  variant="h3"
                  textAlign="center"
                  fontWeight="medium"
                >
                  Create email broadcasts as quickly as the speed of light
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        case "forgot":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img src="/images/sendpad/sendpad-login-logo.32c527ca.svg" alt="logo" />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <img
                  src="/images/sendpad/forgot-password-illustration.56430e53.svg"
                  alt="illustration"
                />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  SendPad
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "70%" }}
                  display="block"
                  variant="h3"
                  textAlign="center"
                  fontWeight="medium"
                >
                  Create email broadcasts as quickly as the speed of light
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        case "sign-up":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img
                  src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapageL.png`}
                  alt="logo"
                />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  {process.env.REACT_APP_SITE_TITLE}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        case "subscription":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img
                  src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapageL.png`}
                  alt="logo"
                />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  {process.env.REACT_APP_SITE_TITLE}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        case "subscription-done":
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img src="/images/sendpad/sendpad-login-logo.32c527ca.svg" alt="logo" />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <img src="/images/sendpad/signup-illustration.7613a235.svg" alt="illustration" />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  SendPad
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "70%" }}
                  display="block"
                  variant="h3"
                  textAlign="center"
                  fontWeight="medium"
                >
                  Create email broadcasts as quickly as the speed of light
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        default:
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img
                  src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapageL.png`}
                  alt="logo"
                />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="body2" fontWeight="medium">
                  {process.env.REACT_APP_SITE_TITLE}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
      }
    case "pixapage":
      switch (type.type) {
        case "sign-in":
          return (
            <MDBox>
              <MDBox display="flex" justifyContent="center">
                <img
                  src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapage-logoBW.png`}
                  alt="logo"
                  width={"70%"}
                />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <img
                  src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/ffLogo.png`}
                  alt="logo"
                  width={"30%"}
                />
              </MDBox>
              <MDBox mt={2} mb={3} textAlign="center" display="flex" justifyContent="center">
                <MDTypography
                  display="block"
                  variant="h2"
                  fontWeight="medium"
                  style={{ color: "#000" }}
                >
                  Float Funnels is now PixaPage!
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "90%" }}
                  display="block"
                  variant="h6"
                  mb={2}
                >
                  We are thrilled to bring you the new and improved page and funnel builder,
                  PixaPage. PixaPage gives you access to all the great features for Floatfunnels but
                  also has some new exciting features such as:
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "90%" }}
                  display="block"
                  variant="h6"
                  mb={2}
                >
                  <ul>
                    <li>A new funnel functionality with split testing options</li>
                  </ul>
                  <ul>
                    <li>AI generated images </li>
                  </ul>
                  <ul>
                    <li>AI generated text suggestions </li>
                  </ul>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "90%" }}
                  display="block"
                  variant="h6"
                  mb={2}
                >
                  There is no urgent rush to connect to PixaPage if you aren't making changes
                  immediately. Pages will continue to work 'as is' on Float Funnels.
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "90%" }}
                  display="block"
                  variant="h6"
                  mb={2}
                >
                  The good news is that the page editor and most settings are just as they were in
                  Float Funnels, so you will be off and running right away. All your pages created
                  in Float Funnels have been imported to PixaPage as well as your billing details
                  and subscription.
                </MDTypography>
              </MDBox>
              {/*
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "90%" }}
                  display="block"
                  variant="h6"
                  mb={2}
                >
                  Use your Floatfunnels credentials to login There are 2 things you will need to do
                  to start using PixaPage:
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDTypography
                  style={{ color: "#000", width: "90%" }}
                  display="block"
                  variant="h6"
                  mb={2}
                >
                  <ul>
                    <li>First, you will need to update your profile details</li>
                  </ul>
                  <ul>
                    <li>
                      Second, you will need to update your domain settings to point to the Pixapage
                      application. (Follow the instructions once you login)
                    </li>
                  </ul>
                </MDTypography>
              </MDBox>
              */}
            </MDBox>
          );
        default:
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img
                  src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapage-logoBW.png`}
                  alt="logo"
                  width={"100%"}
                />
              </MDBox>
            </MDBox>
          );
      }

    case "funnelsense":
      switch (type) {
        case "sign-in":
          return (
            <MDBox>
              <MDBox mt={10} mb={5} display="flex" justifyContent="center">
                <img src="/images/email.png" alt="logo" />
              </MDBox>
              <MDBox mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="h1" fontWeight="bold">
                  MailSense
                </MDTypography>
              </MDBox>
            </MDBox>
          );
        default:
          return (
            <MDBox>
              <MDBox mt={10} mb={15} display="flex" justifyContent="center">
                <img src="/images/email.png" alt="logo" />
              </MDBox>
              <MDBox mt={10} mb={3} display="flex" justifyContent="center">
                <MDTypography display="block" variant="h1" fontWeight="medium">
                  MailSense
                </MDTypography>
              </MDBox>
            </MDBox>
          );
      }
    default:
      return (
        <MDBox>
          <MDBox mt={10} mb={15} display="flex" justifyContent="center">
            <img
              src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/images/pixapageL.png`}
              alt="logo"
              width={"100%"}
            />
          </MDBox>
          <MDBox mt={10} mb={3} display="flex" justifyContent="center">
            <MDTypography display="block" variant="body2" fontWeight="medium">
              {process.env.REACT_APP_SITE_TITLE}
            </MDTypography>
          </MDBox>
        </MDBox>
      );
  }
}
