// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples

//DNS Settings
const providers = [
  {
    name: "1&1 IONOS",
    url: "https://www.ionos.com/help/domains/glossary-important-terms-and-topics-explained/dns-settings/",
  },
  {
    name: "123 Reg",
    url: "https://www.123-reg.co.uk/support/domains/how-do-i-set-up-a-cname-record-on-my-domain-name/",
  },
  {
    name: "Blacknight Solutions",
    url: "https://help.blacknight.com/hc/en-us/articles/212512209-DNS-records-in-cp-blacknight-com",
  },
  {
    name: "Bluehost",
    url: "https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries",
  },
  {
    name: "Domain.com",
    url: "https://www.domain.com/help/article/dns-management-how-to-update-dns-records",
  },
  {
    name: "Enom",
    url: "https://help.enom.com/hc/en-us/articles/115000474012-How-to-Change-DNS-Host-Records",
  },
  { name: "GoDaddy", url: "https://ca.godaddy.com/help/manage-dns-records-680" },
  {
    name: "Google Domains",
    url: "https://support.google.com/domains/answer/3290309?hl=en&ref_topic=9018335",
  },
  { name: "HostGator", url: "https://www.hostgator.com/help/article/changing-dns-records" },
  {
    name: "Namecheap",
    url: "https://www.namecheap.com/support/knowledgebase/article.aspx/9837/46/how-to-connect-a-domain-to-a-server-or-hosting/",
  },
  {
    name: "Network Solutions",
    url: "https://customerservice.networksolutions.com/prweb/PRAuth/app/WebKM_/JfLhd8LVz0a16-h3GqsHOCqqFky5N_vd*/!autoThread0?pzuiactionzzz=CXtycX1jZTJkNDU3ODdkMmVlNjhiOTMyMTM3YzVlNmRjNTM4ZDI5YzY1NWUwY2M4ZTBjZWI1MmMxNzgzODUzMTVkMGVjMGI1NjJkYjA5MzkyNjAwZGMwYWZmZmNmZTFjMmRhMjg3ODIwMTc3YjQ0ZDI3OTY1MzZmZTc2MmNhYjAxMjhjZjAwY2E0NjFmMmM2NzYyMjlkZWI4NGE4NGIxZDczMjY4NWRkYzEzNWJhNDExN2VjYTcxYTJmMjYxMDhkZDQwODM%3D*",
  },
  {
    name: "OVHcloud",
    url: "https://docs.ovh.com/ca/en/domains/web_hosting_general_information_about_dns_servers/",
  },
  {
    name: "Register",
    url: "https://customerservice.register.com/prweb/PRAuth/app/WebKM_/JfLhd8LVz0a16-h3GqsHOCqqFky5N_vd*/!autoThread0?pzuiactionzzz=CXtycX03NTdkNzNlNzhiMzIzNmEwYWQ1MWFhMDZjNmIwMDc2ZjE3ZmI1ZTYxZTc3NDdlMDFhMDBkNWUxMzVkZWE3MTQ2MjQwZTY4MDVjODlhMDczMGJkMWMwMjg5NTQ1OTVjMTkyNjY3MDYxMmIyZTlmZjVlOTkyNGNkMzZhYjdmMGYwMTBhOWMxYmM1OGZlODlmMTI3MWQzZDNhMzI4ZGI3ZDI2YjExOWExODFkZGE5ZGY2NzFkNTgxY2MwNmY0NjY3NmM%3D*",
  },
  {
    name: "Wix",
    url: "https://support.wix.com/en/article/connecting-a-wix-domain-to-an-external-site",
  },
];

const DnsSettings = () => {
  return (
    <Grid container justifyContent="center" sx={{ my: 4 }}>
      <Grid item xs={12} lg={8}>
        <MDBox p={2}>
          <MDTypography variant="h5" component="div" gutterBottom>
            DNS Settings
          </MDTypography>
          <MDTypography variant="body1" component="p" gutterBottom>
            For further information on manually changing your DNS settings at your domain provider,
            please use the following links.
          </MDTypography>
          <Grid container spacing={2}>
            {providers.map((provider, index) => (
              <Grid item xs={6} sm={4} md={3} key={index}>
                <MDTypography
                  component="a"
                  href={provider.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body1"
                  color="info"
                  sx={{ cursor: "pointer" }}
                >
                  {provider.name}
                </MDTypography>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default DnsSettings;
