import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg,
  CardBody,
  CardText,
  Spinner,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// import server from '../../../../../apis/server'; // Adjust the import path according to your project structure
import { CiImageOn } from "react-icons/ci";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import client from "ApiClient";

const Favicon = ({ pageId, faviconUrl, setFavicon }) => {
  const [loading, setLoading] = useState(false);
  const [faviconPreview, setFaviconPreview] = useState(faviconUrl || null);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = (event) => {
    event.stopPropagation(); // Prevent the card onClick from triggering

    setDeleteModal(!deleteModal);
  };
  useEffect(() => {
    setFaviconPreview(faviconUrl || null);
  }, [faviconUrl]);

  const uploadFile = async (e) => {
    setLoading(true);
    const files = e.target.files[0];
    if (!files) {
      setLoading(false);
      return;
    }

    let fileSizeInBytes = files.size;
    let fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to megabytes

    if (fileSizeInMB > 1) {
      toast("Please upload a file smaller than 1 MB.");
      setLoading(false);
      return;
    }

    // Generate favicon preview
    const imageUrl = URL.createObjectURL(files);
    setFaviconPreview(imageUrl);

    const options = {
      method: "POST",
      url: `grapeai/uploadFavicon`,
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    options.data = formData;
    client
      .request(options)
      .then((response) => {
        setFavicon(response);
        setLoading(false);
        setFaviconPreview(response);
      })
      .catch((error) => {
        setLoading(false);
        toast(e.response, "error");
      });
  };
  const handleDeleteFavicon = async (event) => {
    toggleDeleteModal(event);
    setLoading(true);

    try {
      setFavicon(null);
      setFaviconPreview(null);
      toast("Favicon removed successfully", "success");
      // Reset the file input
      document.getElementById("favicon-upload").value = "";
    } catch (error) {
      toast("Error removing the favicon", "error");
    }

    setLoading(false);
  };
  return (
    <div className={"styleDetails"}>
      <Form>
        <FormGroup>
          <Label for="Favicon">Favicon</Label>
          <Input
            type={"file"}
            placeholder={"Upload an image"}
            onChange={uploadFile}
            style={{ display: "none" }}
            id="favicon-upload"
            accept=".ico"
          />
          <Card
            onClick={() => document.getElementById("favicon-upload").click()}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                }}
              >
                <Spinner color="primary" />
              </div>
            )}
            {faviconPreview ? (
              <>
                <CardImg top width="100%" src={faviconPreview} alt="Favicon preview" />
                <MdDelete
                  size={30}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                    color: "red",
                    zIndex: 3,
                  }}
                  onClick={toggleDeleteModal}
                />
              </>
            ) : (
              <CardBody>
                <CiImageOn size={50} />
                <CardText>Upload Favicon (.ico)</CardText>
              </CardBody>
            )}
          </Card>
        </FormGroup>
      </Form>

      <Modal isOpen={deleteModal} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete the Favicon?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteFavicon}>
            Clear
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Favicon;
