export function newBlocks(editor) {
  // Image Sources
  const AvatarGirl = `https://thumbs.pixapage.com/templates/images/newblocks/girl-avatar.jpg`;
  const AvatarBoy = `https://thumbs.pixapage.com/templates/images/newblocks/boy-avatar.jpg`;
  const AvatarGirlB = `https://thumbs.pixapage.com/templates/images/newblocks/girl-b.jpg`;
  const Image1 = `https://thumbs.pixapage.com/templates/images/newblocks/certificate_2874808.png`;
  const Image2 = `https://thumbs.pixapage.com/templates/images/newblocks/certificate_white.png`;
  const Image3 = `https://thumbs.pixapage.com/templates/images/newblocks/contract_4593263.png`;
  const Image4 = `https://thumbs.pixapage.com/templates/images/newblocks/contract_white.png`;
  const Image5 = `https://thumbs.pixapage.com/templates/images/newblocks/medal_7955227.png`;
  const Image6 = `https://thumbs.pixapage.com/templates/images/newblocks/medal_white.png`;
  const Image7 = `https://thumbs.pixapage.com/templates/images/newblocks/trust_14488547.png`;
  const Image8 = `https://thumbs.pixapage.com/templates/images/newblocks/trust_white.png`;
  const Image9 = `https://thumbs.pixapage.com/templates/images/newblocks/contact_fg.jpg`;

  // Thumbnails
  const AboutOne = "https://thumbs.pixapage.com/templates/images/newblocks/ab-tem-1.jpg";
  const AboutTwo = "https://thumbs.pixapage.com/templates/images/newblocks/ab-tem-2.jpg";
  const AboutThree = "https://thumbs.pixapage.com/templates/images/newblocks/ab-tem-3.jpg";
  const AboutFour = "https://thumbs.pixapage.com/templates/images/newblocks/ab-tem-4.jpg";
  const AboutFive = "https://thumbs.pixapage.com/templates/images/newblocks/ab-tem-5.jpg";
  const AboutSix = "https://thumbs.pixapage.com/templates/images/newblocks/ab-tem-6.jpg";

  const FaqOne = "https://thumbs.pixapage.com/templates/images/newblocks/f1.jpg";
  const FaqTwo = "https://thumbs.pixapage.com/templates/images/newblocks/f2.jpg";
  const FaqThree = "https://thumbs.pixapage.com/templates/images/newblocks/f3.jpg";
  const FaqFour = "https://thumbs.pixapage.com/templates/images/newblocks/f4.jpg";

  const TmZero = "https://thumbs.pixapage.com/templates/images/newblocks/tm-0.jpg";
  const TmOne = "https://thumbs.pixapage.com/templates/images/newblocks/tm-1.jpg";
  const TmThree = "https://thumbs.pixapage.com/templates/images/newblocks/tm-3.jpg";
  const TmEight = "https://thumbs.pixapage.com/templates/images/newblocks/tm-8.jpg";
  const TmSix = "https://thumbs.pixapage.com/templates/images/newblocks/tm-6.jpg";
  const TmFive = "https://thumbs.pixapage.com/templates/images/newblocks/tm-5.jpg";
  const TmTwo = "https://thumbs.pixapage.com/templates/images/newblocks/tm-2.jpg";
  const TmFour = "https://thumbs.pixapage.com/templates/images/newblocks/tm-4.jpg";
  const TmNine = "https://thumbs.pixapage.com/templates/images/newblocks/tm-9.jpg";
  const TmSeven = "https://thumbs.pixapage.com/templates/images/newblocks/tm-7.jpg";

  const CallToActionThumbnailOne =
    "https://thumbs.pixapage.com/templates/images/newblocks/call-to-actions-1.jpg";
  const CallToActionThumbnailTwo =
    "https://thumbs.pixapage.com/templates/images/newblocks/call-to-actions-2.jpg";
  const CallToActionThumbnailThree =
    "https://thumbs.pixapage.com/templates/images/newblocks/call-to-actions-3.jpg";
  const CallToActionThumbnailFour =
    "https://thumbs.pixapage.com/templates/images/newblocks/call-to-actions-4.jpg";
  const CallToActionThumbnailFive =
    "https://thumbs.pixapage.com/templates/images/newblocks/call-to-actions-5.jpg";

  const ContactUsThumbnailOne =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-1.jpg";
  const ContactUsThumbnailTwo =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-2.jpg";
  const ContactUsThumbnailThree =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-3.jpg";
  const ContactUsThumbnailFour =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-4.jpg";
  const ContactUsThumbnailFive =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-5.jpg";
  const ContactUsThumbnailSix =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-6.jpg";
  const ContactUsThumbnailSeven =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-7.jpg";
  const ContactUsThumbnailEight =
    "https://thumbs.pixapage.com/templates/images/newblocks/contact-us-8.jpg";

  const FeaturesThumbnailOne =
    "https://thumbs.pixapage.com/templates/images/newblocks/features-1.jpg";
  const FeaturesThumbnailTwo =
    "https://thumbs.pixapage.com/templates/images/newblocks/features-2.jpg";
  const FeaturesThumbnailThree =
    "https://thumbs.pixapage.com/templates/images/newblocks/features-3.jpg";
  const FeaturesThumbnailFour =
    "https://thumbs.pixapage.com/templates/images/newblocks/features-4.jpg";

  //SECTIONS
  editor.BlockManager.add("Button", {
    label: "Button",
    category: "Basic",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="27.633" height="24.151" viewBox="0 0 27.633 24.151">
    <g id="icon-button" transform="translate(789.271 -46.353)">
    <g id="Group_22571" data-name="Group 22571" transform="translate(-789.271 46.353)">
    <path id="Path_45394" data-name="Path 45394" d="M-764.2,46.353h-22.514a2.562,2.562,0,0,0-2.559,2.562V59.678a2.561,2.561,0,0,0,2.559,2.56h5.845a1.175,1.175,0,0,0,1.174-1.176,1.175,1.175,0,0,0-1.174-1.176h-5.845a.208.208,0,0,1-.207-.209V48.915a.208.208,0,0,1,.207-.209H-764.2a.211.211,0,0,1,.21.209V59.678a.211.211,0,0,1-.21.209h-1.236a1.176,1.176,0,0,0-1.176,1.176,1.176,1.176,0,0,0,1.176,1.176h1.236a2.562,2.562,0,0,0,2.56-2.56V48.915A2.564,2.564,0,0,0-764.2,46.353Z" transform="translate(789.271 -46.353)" fill="#b2b9c3"/>
    <path id="Path_45395" data-name="Path 45395" d="M-778.878,53.79a.493.493,0,0,0-.841.349V64.775l2.232-2.537,2.479,5.472,2.521-1.141-2.42-5.342h3.466Z" transform="translate(792.931 -43.559)" fill="#b2b9c3"/>
    <path id="Path_45396" data-name="Path 45396" d="M-778.217,53.039a.946.946,0,0,0,.664-.271l1.138-1.109a.949.949,0,0,0,.018-1.344.949.949,0,0,0-1.344-.017l-1.138,1.109a.949.949,0,0,0-.018,1.344A.951.951,0,0,0-778.217,53.039Z" transform="translate(793.142 -44.945)" fill="#b2b9c3"/>
    <path id="Path_45397" data-name="Path 45397" d="M-781.624,53.12l-1.331.869a.95.95,0,0,0-.277,1.317.95.95,0,0,0,.8.43.945.945,0,0,0,.519-.155l1.331-.87a.949.949,0,0,0,.275-1.315A.947.947,0,0,0-781.624,53.12Z" transform="translate(791.526 -43.82)" fill="#b2b9c3"/>
    <path id="Path_45398" data-name="Path 45398" d="M-780.837,50.036a.952.952,0,0,0-1.338-.141.951.951,0,0,0-.14,1.337l1.173,1.451a.949.949,0,0,0,.74.354.949.949,0,0,0,.6-.212.951.951,0,0,0,.141-1.337Z" transform="translate(791.855 -45.077)" fill="#b2b9c3"/>
    </g>
    </g>
    </svg>`,
    content: {
      type: "link",
      attributes: {
        "data-custom-id-new": "my-custom-button", // Ensure this line is correct
      },

      style: {
        width: "auto", // Changed width to auto
        display: "block",
        "justify-content": "center",
        "align-items": "center",
        "background-color": "#00a7ff",
        color: "#fff",
        padding: "10px 20px",
        "text-align": "center",
        "font-size": "16px",
        cursor: "pointer",
        border: "none",
        outline: "none",
        transition: "background-color 0.3s",
        "max-width": "1140px",
        margin: "12px auto",
      },
      components: `<span class="button-text">Button</span>`,
    },
    // activate: true, // Ensures the button's properties panel is opened upon addition
    // select: true, // Automatically select this component once it is dropped in
  });
  editor.BlockManager.add("TEXT", {
    id: "heading",
    label: "Headline",
    category: "Basic",
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25"><path d="M.781.03A1.186 1.186 0 0 0 .22.4c-.321.421-.287.981.083 1.351.278.278.384.302 1.318.302h.785v15.144h-.785c-.934 0-1.04.024-1.318.302-.502.502-.355 1.336.291 1.656l.16.079h5.358l.183-.09a1.06 1.06 0 0 0 .509-.563c.089-.238.075-.593-.033-.807a1.145 1.145 0 0 0-.467-.476c-.16-.085-.16-.085-1.002-.095l-.842-.01v-6.541h10.33v6.541l-.842.01c-.812.009-.847.012-.986.085a1.007 1.007 0 0 0-.57.871.864.864 0 0 0 .054.422c.091.24.282.452.509.563l.183.09h5.358l.16-.079c.646-.32.793-1.154.291-1.656-.278-.278-.384-.302-1.318-.302h-.785V2.053h.785c.71 0 .801-.006.959-.065.818-.306.889-1.432.117-1.857l-.209-.115h-5.358l-.183.09c-.631.31-.768 1.182-.261 1.663.068.065.188.151.268.193.139.073.174.076.986.085l.842.01v6.541H4.46V2.057l.842-.01c.842-.01.842-.01 1.002-.095a.996.996 0 0 0 .554-.861A.865.865 0 0 0 6.8.66a1.105 1.105 0 0 0-.528-.565l-.16-.079L3.497.011C2.059.008.837.017.781.03" fill="#b2b9c3" fill-rule="evenodd"/></svg>`,
    // attributes: { class: 'gjs-fonts gjs-f-h1p' },

    content: {
      type: "text",
      content: "Insert Headline here",
      style: {
        "font-weight": "700",
        "font-size": "48px !important",
        "max-width": "1140px",
        margin: "0 auto",
      },
      activeOnRender: true,
    },
  });

  editor.BlockManager.add("link_block", {
    label: "Link Block",
    category: "Basic",

    media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>
      </svg>`,
    content: {
      type: "link",
      editable: false,
      droppable: true,
      style: {
        "min-height": "50px",
        "min-width": "50px",
        padding: "5px",
        "max-width": "1140px",
        margin: "0 auto",
        display: "block",
      },
    },
  });

  editor.BlockManager.add("spacer", {
    label: "Spacer",
    category: "Basic",
    content: {
      type: "spacer",
      components: '<div style="height:50px;"></div>',
    },
    attributes: {
      class: "fa fa-arrows-v",
    },
  });

  editor.DomComponents.addType("spacer", {
    isComponent: (el) => {
      if (el.tagName === "DIV" && el.style.height) {
        return { type: "spacer" };
      }
    },
    model: {
      defaults: {
        droppable: false, // Prevent dropping other components inside the spacer

        resizable: {
          tl: 0, // Top left
          tc: 1, // Top center (height)
          tr: 0, // Top right
          cl: 0, // Center left
          cr: 0, // Center right
          bl: 0, // Bottom left
          bc: 1, // Bottom center (height)
          br: 0, // Bottom right
        },
        traits: [
          {
            type: "number",
            label: "Height",
            name: "height",
            changeProp: 1,
            min: 10, // Minimum height
            max: 500, // Maximum height
          },
        ],
      },
      init() {
        this.on("change:height", this.updateHeight);
      },
      updateHeight() {
        this.components(`<div style="height:${this.get("height")}px;"></div>`);
      },
    },
  });

  editor.BlockManager.add("Grid-Item", {
    id: "grid-items",
    label: "Grid Items",
    category: "Basic",
    attributes: { class: "fa fa-th" },
    content: `<div>
<table class="grid-item-row" style="box-sizing: border-box; margin: 0 auto 10px auto; padding: 5px 0; width: 100%;" width="100%">
<tbody style="box-sizing: border-box;">
<tr style="box-sizing: border-box;">
<td class="grid-item-cell2-l" style="box-sizing: border-box; vertical-align: top; padding-right: 10px; width: 50%;" width="50%" valign="top">
<table class="grid-item-card" style="box-sizing: border-box; width: 100%; padding: 5px 0 5px 0; margin-bottom: 10px;" width="100%">
<tbody style="box-sizing: border-box;">
    <tr style="box-sizing: border-box;">
        <td class="grid-item-card-cell" style="box-sizing: border-box; background-color: rgb(255, 255, 255); overflow: hidden; border-radius: 3px; text-align: center; padding: 0;" bgcolor="rgb(255, 255, 255)" align="center">
            <img src="https://via.placeholder.com/100" alt="Image" class="grid-item-image" style="box-sizing: border-box; line-height: 150px; font-size: 50px; color: rgb(120, 197, 214); margin-bottom: 15px; width: 100%;">
            <table class="grid-item-card-body" style="box-sizing: border-box;">
                <tbody style="box-sizing: border-box;">
                    <tr style="box-sizing: border-box;">
                        <td class="grid-item-card-content" style="box-sizing: border-box; font-size: 13px; color: rgb(111, 119, 125); padding: 0 10px 20px 10px; width: 100%; line-height: 20px;" width="100%">
                            <h4 class="card-title" style="box-sizing: border-box;">Title here</h4>
                            <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                            <div style="display: block; margin: auto;">
                                <a style="margin: auto; background-color: #dcdce0; border: 1px solid #47525d; padding: 5px 10px; font-size: 14px; text-decoration: none; border-radius: 8px; display: block; width: 80px; text-align: center;" data-gjs-type="link">Button</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</tbody>
</table>
</td>
<td class="grid-item-cell2-r" style="box-sizing: border-box; vertical-align: top; padding-left: 10px; width: 50%;" width="50%" valign="top">
<table class="grid-item-card" style="box-sizing: border-box; width: 100%; padding: 5px 0 5px 0; margin-bottom: 10px;" width="100%">
<tbody style="box-sizing: border-box;">
    <tr style="box-sizing: border-box;">
        <td class="grid-item-card-cell" style="box-sizing: border-box; background-color: rgb(255, 255, 255); overflow: hidden; border-radius: 3px; text-align: center; padding: 0;" bgcolor="rgb(255, 255, 255)" align="center">
            <img src="https://via.placeholder.com/100" alt="Image" class="grid-item-image" style="box-sizing: border-box; line-height: 150px; font-size: 50px; color: rgb(120, 197, 214); margin-bottom: 15px; width: 100%;">
            <table class="grid-item-card-body" style="box-sizing: border-box;">
                <tbody style="box-sizing: border-box;">
                    <tr style="box-sizing: border-box;">
                        <td class="grid-item-card-content" style="box-sizing: border-box; font-size: 13px; color: rgb(111, 119, 125); padding: 0 10px 20px 10px; width: 100%; line-height: 20px;" width="100%">
                            <h4 class="card-title" style="box-sizing: border-box;">Title here</h4>
                            <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                            <div style="display: block; margin: auto;">
                                <a style="margin: auto; background-color: #dcdce0; border: 1px solid #47525d; padding: 5px 10px; font-size: 14px; text-decoration: none; border-radius: 8px; display: block; width: 80px; text-align: center;" data-gjs-type="link">Button</a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
     </div>`,
    //   activeOnRender:true
  });

  editor.BlockManager.add("List-Item", {
    id: "list-items",
    label: "List Items",
    category: "Basic",
    attributes: { class: "fa fa-th-list" },
    content: `<div><table class="grid-item-row" style="box-sizing: border-box; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: auto; padding-top: 5px; padding-right: 0px; padding-bottom: 5px; padding-left: 0px; width: 100%;" 
    width="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
      </tr>
    </tbody>
  </table>
  <table class="list-item" style="box-sizing: border-box; height: auto; width: 100%; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px;" width="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
        <td class="list-item-cell" style="box-sizing: border-box; background-color: #ffffff !important; border-top-left-radius: 3px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; overflow-x: hidden; overflow-y: hidden; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" bgcolor="rgb(255, 255, 255)">
          <table class="list-item-content" style="box-sizing: border-box; border-collapse: collapse; margin-top: 0px; margin-right: auto; margin-bottom: 0px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; height: 150px; width: 100%;" width="100%" height="150">
            <tbody style="box-sizing: border-box;">
              <tr class="list-item-row" style="box-sizing: border-box;">
                <td class="list-cell-left" style="box-sizing: border-box; width: 30%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" width="30%">
                  <img src="https://via.placeholder.com/100" alt="Image" class="list-item-image" style="box-sizing: border-box; color: rgb(217, 131, 166); font-size: 45px; width: 100%;">
                </td>
                <td class="list-cell-right" style="box-sizing: border-box; width: 70%; padding-top: 10px; padding-right: 20px; padding-bottom: 0px; padding-left: 20px;" width="70%">
                  <h4 class="card-title" style="box-sizing: border-box;">Title here
                  </h4>
                  <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  </p>
                                  <div style="display:block; margin:auto;"><a style="background-color: #dcdce0;border: 1px solid #47525d;padding: 5px 10px;font-size: 14px; text-decoration:none;border-radius: 8px; display: block;width:80px;text-align:center"data-gjs-type="link" >
Button
</a></div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="list-item" style="box-sizing: border-box; height: auto; width: 100%; margin-top: 0px; margin-right: auto; margin-bottom: 10px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px;" width="100%">
    <tbody style="box-sizing: border-box;">
      <tr style="box-sizing: border-box;">
        <td class="list-item-cell" style="box-sizing: border-box; background-color: #ffffff !important; border-top-left-radius: 3px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; overflow-x: hidden; overflow-y: hidden; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" bgcolor="rgb(255, 255, 255)">
          <table class="list-item-content" style="box-sizing: border-box; border-collapse: collapse; margin-top: 0px; margin-right: auto; margin-bottom: 0px; margin-left: auto; padding-top: 5px; padding-right: 5px; padding-bottom: 5px; padding-left: 5px; height: 150px; width: 100%;" width="100%" height="150">
            <tbody style="box-sizing: border-box;">
              <tr class="list-item-row" style="box-sizing: border-box;">
                <td class="list-cell-left" style="box-sizing: border-box; width: 30%; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" width="30%">
                  <img src="https://via.placeholder.com/100" alt="Image" class="list-item-image" style="box-sizing: border-box; color: rgb(217, 131, 166); font-size: 45px; width: 100%;">
                </td>
                <td class="list-cell-right" style="box-sizing: border-box; width: 70%; padding-top: 10px; padding-right: 20px; padding-bottom: 0px; padding-left: 20px;" width="70%">
                  <h4 class="card-title" style="box-sizing: border-box;">Title here
                  </h4>
                  <p class="card-text" style="box-sizing: border-box;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  </p>
                                 <div style="display:block; margin:auto;"><a style="background-color: #dcdce0;border: 1px solid #47525d;padding: 5px 10px;font-size: 14px; text-decoration:none;border-radius: 8px; display: block;width:80px;text-align:center" data-gjs-type="link">
Button
</a></div>
                </td>
              </tr> 
            </tbody> 
          </table>
        </td>
      </tr>
    </tbody> </table></div>`,
    activeOnRender: true,
  });

  editor.BlockManager.get("column1").set({
    content: `<div class=\"gjs-row gjs-grid r1\" data-gjs-type = \"row-1\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name=\"Row\">
        <div  class=\"cell gjs-clm col1\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
      </div>
      <style>
      .gjs-clm{
        padding:5px 0;
      }
      .gjs-grid{
        min-height:auto;
        padding:10px 0;
      }
      </style>`,
  });

  editor.BlockManager.get("column2").set({
    content: `<div class=\"gjs-row gjs-grid r2\" data-gjs-type = \"grid-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name=\"Row\">
        <div  class=\"cell gjs-clm clm-2\"  data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
        <div  class=\"cell gjs-clm clm-1\"  data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
      </div>
      <style>
      .gjs-clm{
        padding:5px 0;
      }
      .gjs-grid{
        min-height:auto;
        padding:10px 0;
      }
      </style>`,
  });

  editor.BlockManager.get("column3").set({
    content: `<div class=\"gjs-row gjs-grid r3\" data-gjs-type = \"grid-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name=\"Row\">
        <div  class=\"cell gjs-clm cl-3\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
        <div  class=\"cell gjs-clm cl-2\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
        <div  class=\"cell gjs-clm cl1\" data-gjs-type = \"grid-item\" data-gjs-draggable=\".gjs-row\" data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name=\"Cell\"></div>
      </div>
      <style>
      .gjs-clm{
        padding:5px 0;
      }
      .gjs-grid{
        min-height:auto;
        padding:10px 0;
      }
      </style>`,
  });

  // About Us Section
  // About-1
  editor.BlockManager.add("About-1", {
    id: "about-1",
    label: `
    <div>
      <img src="${AboutOne}" />
    </div>
  `,

    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#ffffff",
          "text-align": "center",
          "padding-top": "2rem",
          "padding-bottom": "2rem",
          width: "fit-content",
          display: "flex",
          "flex-wrap": "wrap",
          "justify-content": "center",
          margin: "auto",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              width: "fit-content",
              "max-width": "512px",
              "text-align": "left",
              padding: "0 0.5rem",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style='font-weight: 700; font: 24pt Tahoma, sans-serif;'>Meet Helerine.</span>",
                style: {
                  margin: "1rem auto 1rem",
                  "line-height": "30px",
                },
              },
              {
                type: "text",
                content: `
                    <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                      The creator of Modern PT, Helerine is committed to the well-being of every individual who enters. Helerine established this center a decade ago to provide a compassionate, communicative space for people of all ages to manage their pain. When Helerine isn't applying her expert touch to relieve pain and enhance mobility, she's participating in classes and conferences to incorporate the latest practices and technologies to meet her patients' needs.<br> <br>
                      Book a tour to experience Helerine's unique method of physical therapy.
                    </span>`,
                style: {
                  width: "90%",
                  "line-height": "25px",
                  margin: "0.5rem auto 1rem 0",
                },
              },
              {
                type: "link",
                attributes: { class: "" },
                content: `Schedule a Session`,
                style: {
                  "text-align": "center",
                  display: "block",
                  "white-space": "break-spaces",
                  width: "fit-content",
                  "background-color": "#6542FF",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  padding: "10px 15px",
                  border: "none",
                  margin: "2rem 0 2rem",
                  font: "12pt Tahoma, sans-serif",
                },
              },
            ],
          },
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "left",
              "max-width": "512px",
              "min-width": "290px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                name: "dfy-image",
                removable: true,
                stylable: true,
                draggable: true,
                attributes: {
                  src: AvatarGirl,
                  alt: "Image",
                  class: "list-item-image",
                },
                style: {
                  "text-align": "left",
                  "max-width": "512px",
                  "min-width": "290px",
                  width: "100%", // Ensures the image stretches to fit the container
                  margin: "1.5rem auto 0rem", // Center the image vertically within its container
                },
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // About-2
  editor.BlockManager.add("About-2", {
    id: "about-2",
    label: `
    <div>
      <img src="${AboutTwo}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget right-space-issue" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#ffffff",
          "text-align": "center",
          "padding-top": "2rem",
          "padding-bottom": "2rem",
          width: "fit-content",
          display: "flex",
          "flex-wrap": "wrap",
          "justify-content": "center",
          margin: "auto",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            attributes: {
              class: "custom-class hide-from-widget right-space-issue",
            },
            style: {
              "text-align": "left",
              "max-width": "512px",
              "min-width": "290px",
            },
            components: [
              {
                type: "image",
                tagName: "img",
                name: "dfy-image",
                removable: true,
                stylable: true,
                draggable: true,
                attributes: {
                  src: AvatarGirl,
                  alt: "Image",
                  class: "list-item-image-right",
                },
                style: {
                  "text-align": "left",
                  "max-width": "512px",
                  "min-width": "290px",
                  width: "100%", // Ensures the image stretches to fit the container
                  margin: "1.5rem auto 0rem", // Center the image vertically within its container
                },
              },
            ],
          },
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              width: "fit-content",
              "max-width": "512px",
              "text-align": "left",
              padding: "0 0.5rem",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style='font-weight: 700; font: 24pt Tahoma, sans-serif;'>Meet Helerine.</span>",
                style: {
                  margin: "1rem auto 1rem",
                  "line-height": "30px",
                },
              },
              {
                type: "text",
                content: `
                    <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                      The creator of Modern PT, Helerine is committed to the well-being of every individual who enters. Helerine established this center a decade ago to provide a compassionate, communicative space for people of all ages to manage their pain. When Helerine isn't applying her expert touch to relieve pain and enhance mobility, she's participating in classes and conferences to incorporate the latest practices and technologies to meet her patients' needs.<br> <br>
                      Book a tour to experience Helerine's unique method of physical therapy.
                    </span>`,
                style: {
                  width: "90%",
                  "line-height": "25px",
                  margin: "0.5rem 0 1rem",
                },
              },
              {
                type: "link",
                attributes: { class: "" },
                content: `Schedule a Session`,
                style: {
                  "text-align": "center",
                  display: "block",
                  "white-space": "break-spaces",
                  width: "fit-content",
                  "background-color": "#6542FF",
                  cursor: "pointer",
                  color: "#FFFFFF",
                  padding: "10px 15px",
                  border: "none",
                  margin: "2rem 0 2rem",
                  font: "12pt Tahoma, sans-serif",
                },
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // About-3
  editor.BlockManager.add("About-3", {
    id: "about-3",
    label: `
    <div>
      <img src="${AboutThree}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#000",
          "text-align": "center",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "auto",
            },
            components: [
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  width: "fit-content",
                  "max-width": "512px",
                  "text-align": "left",
                  padding: "0 0.5rem",
                },
                components: [
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 24pt Tahoma, sans-serif;color: #fff;'>Meet Helerine.</span>",
                    style: {
                      margin: "1rem auto 1rem",
                      "line-height": "30px",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 12pt Tahoma, sans-serif; text-align: left;color: #fff;'>
                      The creator of Modern PT, Helerine is committed to the well-being of every individual who enters. Helerine established this center a decade ago to provide a compassionate, communicative space for people of all ages to manage their pain. When Helerine isn't applying her expert touch to relieve pain and enhance mobility, she's participating in classes and conferences to incorporate the latest practices and technologies to meet her patients' needs.<br> <br>
                      Book a tour to experience Helerine's unique method of physical therapy.
                    </span>`,
                    style: {
                      width: "90%",
                      "line-height": "25px",
                      margin: "0.5rem auto 1rem 0",
                    },
                  },
                  {
                    type: "link",
                    attributes: { class: "" },
                    content: `Schedule a Session`,
                    style: {
                      "text-align": "center",
                      display: "block",
                      "white-space": "break-spaces",
                      width: "fit-content",
                      "background-color": "#6542FF",
                      cursor: "pointer",
                      color: "#FFFFFF",
                      padding: "10px 15px",
                      border: "none",
                      margin: "2rem 0 2rem",
                      font: "12pt Tahoma, sans-serif",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  "text-align": "left",
                  "max-width": "512px",
                  "min-width": "290px",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    name: "dfy-image",
                    removable: true,
                    stylable: true,
                    draggable: true,
                    attributes: {
                      src: AvatarGirl,
                      alt: "Image",
                      class: "list-item-image",
                    },
                    style: {
                      "text-align": "left",
                      "max-width": "512px",
                      "min-width": "290px",
                      width: "100%", // Ensures the image stretches to fit the container
                      margin: "1.5rem auto 0rem", // Center the image vertically within its container
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // About-4
  editor.BlockManager.add("About-4", {
    id: "about-4",
    label: `
    <div>
      <img src="${AboutFour}" />
    </div>
  `,
    attributes: { class: "custom-class right-space-issue hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#000",
          "text-align": "center",
        },
        components: [
          {
            tagName: "div",
            style: {
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "auto",
            },
            components: [
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                attributes: {
                  class: "custom-class right-space-issue hide-from-widget",
                },
                style: {
                  "text-align": "left",
                  "max-width": "512px",
                  "min-width": "290px",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    name: "dfy-image",
                    removable: true,
                    stylable: true,
                    draggable: true,
                    attributes: {
                      src: AvatarGirl,
                      alt: "Image",
                      class: "list-item-image-right",
                    },
                    style: {
                      "text-align": "left",
                      "max-width": "512px",
                      "min-width": "290px",
                      width: "100%", // Ensures the image stretches to fit the container
                      margin: "1.5rem auto 0rem", // Center the image vertically within its container
                    },
                  },
                ],
              },
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  width: "fit-content",
                  "max-width": "512px",
                  "text-align": "left",
                  padding: "0 0.5rem",
                },
                components: [
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 24pt Tahoma, sans-serif;color: #fff;'>Meet Helerine.</span>",
                    style: {
                      margin: "1rem auto 1rem",
                      "line-height": "30px",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='color: #fff;font: 12pt Tahoma, sans-serif; text-align: left;'>
                      The creator of Modern PT, Helerine is committed to the well-being of every individual who enters. Helerine established this center a decade ago to provide a compassionate, communicative space for people of all ages to manage their pain. When Helerine isn't applying her expert touch to relieve pain and enhance mobility, she's participating in classes and conferences to incorporate the latest practices and technologies to meet her patients' needs.<br> <br>
                      Book a tour to experience Helerine's unique method of physical therapy.
                    </span>`,
                    style: {
                      width: "90%",
                      "line-height": "25px",
                      margin: "0.5rem 0 1rem",
                    },
                  },
                  {
                    type: "link",
                    attributes: { class: "" },
                    content: `Schedule a Session`,
                    style: {
                      "text-align": "center",
                      display: "block",
                      "white-space": "break-spaces",
                      width: "fit-content",
                      "background-color": "#6542FF",
                      cursor: "pointer",
                      color: "#FFFFFF",
                      padding: "10px 15px",
                      border: "none",
                      margin: "2rem 0 2rem",
                      font: "12pt Tahoma, sans-serif",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // About-5
  editor.BlockManager.add("About-5", {
    id: "about-5",
    label: `
    <div>
      <img src="${AboutFive}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#ececec",
          width: "100%",
          "padding-bottom": "2rem",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "1050px",
            },
            components: [
              {
                type: "text",
                content: "<span style='font: bold 36pt Tahoma, sans-serif;'>About Us</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                  "text-align": "left",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: 15pt Tahoma, sans-serif; text-align: left;'>
                 Modern PT is a privately owned outpatient clinic focused on reducing and eliminating patients' 
                 pain. Our goal is to become unnecessary. We offer a personalized PT strategy to get 
                 you back in the game, so you won't need us anymore. But we also create a fun space with 
                 unique full-body treatments, so you'll want to return to continuously
                  enhance your structure and movement. </br> </br> Meet the Modern PT experts below!
                </span>`,
                style: {
                  width: "100%",
                  margin: "1.5rem auto 2rem",
                  padding: "15px",
                  "text-align": "left",
                  "line-height": "1.5rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "3rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "0 auto",
            },
            components: [
              {
                tagName: "div",
                style: {
                  padding: "20px",
                  margin: " 5px 10px",
                  width: "fit-content",
                  "max-width": "340px",
                  background: "#fff",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirl,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 20pt Tahoma, sans-serif;'> Helerine Christopher </span>",
                    style: {
                      margin: "1.5rem auto 1.5rem",
                    },
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 16pt Tahoma, sans-serif; color: rgba(102, 102, 102, 1);'>Founder and President</span>",
                    style: {
                      margin: "0 auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 14pt Tahoma, sans-serif; text-align: left; color:#7D7D7D;'>
                The founder of Modern PT, Helerine Christopher is dedicated to the wellness of every person who walks through the door. Helerine opened this center 10 years ago to be a caring, communicative haven for people of all ages to address their pain. When Helerine isn't using her magic touch to ease pain and increase range of motion, she's attending classes and conferences to implement the most innovative practices and technologies to meet her patients' needs.
                </span>`,
                    style: {
                      margin: "0 auto 1.5rem",
                      "text-align": "left",
                      "line-height": "1.5rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  padding: "20px",
                  margin: " 5px 10px",
                  width: "fit-content",
                  "max-width": "340px",
                  background: "#fff",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarBoy,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 20pt Tahoma, sans-serif;'> Singam Balosa </span>",
                    style: {
                      margin: "1.5rem auto 1.5rem",
                    },
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 16pt Tahoma, sans-serif; color: rgba(102, 102, 102, 1);'>Physical Therapist, MSPT</span>",
                    style: {
                      margin: "0 auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 14pt Tahoma, sans-serif; text-align: left; color:#7D7D7D;'>
               Singam Balosa entered the PT world after he blew out his ACL in a basketball game. He kept searching for the right physical therapist to help him through his injury, but no one provided the attention and care he needed. So, he went back to school to study physical therapy, aiming to become that missing link for injured athletes. His PT goal is not just to reduce your pain but to get you back in the game quickly and healthfully.
                </span>`,
                    style: {
                      margin: "0 auto 1.5rem",
                      "text-align": "left",
                      "line-height": "1.5rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  padding: "20px",
                  margin: " 5px 10px",
                  width: "fit-content",
                  "max-width": "340px",
                  background: "#fff",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirlB,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 20pt Tahoma, sans-serif;'> Anjellina Fiter</span>",
                    style: {
                      margin: "1.5rem auto 1.5rem",
                    },
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 16pt Tahoma, sans-serif; color: rgba(102, 102, 102, 1);'>Physical Therapist, LPT</span>",
                    style: {
                      margin: "0 auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 14pt Tahoma, sans-serif; text-align: left; color:#7D7D7D;'>
"She always finds the pain!" Anjellina Fiter is called "the miracle worker" in the clinic. With 6 years of expertise, she can see your posture and movement and identify exactly what's causing your discomfort and why. She focuses on treating pain from arthritis and other degenerative bone and tissue diseases. Her warm demeanor and bright smile make her the ideal therapist for seniors and children.                </span>`,
                    style: {
                      margin: "0 auto 1.5rem",
                      "text-align": "left",
                      "line-height": "1.5rem",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // About-6
  editor.BlockManager.add("About-6", {
    id: "about-6",
    label: `
    <div>
      <img src="${AboutSix}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#ececec",
          width: "100%",
          "padding-bottom": "2rem",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "800px",
            },
            components: [
              {
                type: "text",
                content: "<span style='font: bold 36pt Tahoma, sans-serif;'>About Us</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                  "text-align": "left",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: 15pt Tahoma, sans-serif; text-align: left;'>
                 Modern PT is a privately owned outpatient clinic focused on reducing and eliminating patients' 
                 pain. Our goal is to become unnecessary. We offer a personalized PT strategy to get 
                 you back in the game, so you won't need us anymore. But we also create a fun space with 
                 unique full-body treatments, so you'll want to return to continuously
                  enhance your structure and movement. </br> </br> Meet the Modern PT experts below!
                </span>`,
                style: {
                  width: "100%",
                  margin: "1.5rem auto 2rem",
                  padding: "15px",
                  "text-align": "left",
                  "line-height": "1.5rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "3rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "0 auto",
            },
            components: [
              {
                tagName: "div",
                style: {
                  padding: "20px",
                  margin: " 5px 10px",
                  width: "fit-content",
                  "max-width": "400px",
                  background: "#fff",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirl,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 20pt Tahoma, sans-serif;'> Helerine Christopher </span>",
                    style: {
                      margin: "1.5rem auto 1.5rem",
                    },
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 16pt Tahoma, sans-serif; color: rgba(102, 102, 102, 1);'>Founder and President</span>",
                    style: {
                      margin: "0 auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 14pt Tahoma, sans-serif; text-align: left; color:#7D7D7D;'>
                The founder of Modern PT, Helerine Christopher is dedicated to the wellness of every person who walks through the door. Helerine opened this center 10 years ago to be a caring, communicative haven for people of all ages to address their pain. When Helerine isn't using her magic touch to ease pain and increase range of motion, she's attending classes and conferences to implement the most innovative practices and technologies to meet her patients' needs.
                </span>`,
                    style: {
                      margin: "0 auto 1.5rem",
                      "text-align": "left",
                      "line-height": "1.5rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  padding: "20px",
                  margin: " 5px 10px",
                  width: "fit-content",
                  "max-width": "400px",
                  background: "#fff",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarBoy,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                          width: "100%",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 20pt Tahoma, sans-serif;'> Singam Balosa </span>",
                    style: {
                      margin: "1.5rem auto 1.5rem",
                    },
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font: bold 16pt Tahoma, sans-serif; color: rgba(102, 102, 102, 1);'>Physical Therapist, MSPT</span>",
                    style: {
                      margin: "0 auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 14pt Tahoma, sans-serif; text-align: left; color:#7D7D7D;'>
               Singam Balosa entered the PT world after he blew out his ACL in a basketball game. He kept searching for the right physical therapist to help him through his injury, but no one provided the attention and care he needed. So, he went back to school to study physical therapy, aiming to become that missing link for injured athletes. His PT goal is not just to reduce your pain but to get you back in the game quickly and healthfully.
                </span>`,
                    style: {
                      margin: "0 auto 1.5rem",
                      "text-align": "left",
                      "line-height": "1.5rem",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });

  // FAQs Section
  // FAQ-1

  editor.BlockManager.add("Faq-1", {
    id: "Faq-1",
    label: `
    <div>
      <img src="${FaqOne}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#FFFFFF",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "3rem",
              "padding-bottom": "3rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "1040px",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style=' font: bold .875rem Tahoma, sans-serif; color: rgb(125, 125, 125);'>FREQUENTLY ASKED QUESTIONS</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: bold 2.5rem Tahoma, sans-serif; text-align: center;'>
                We have answers for your questions.                </span>`,
                style: {
                  width: "100%",
                  margin: "1rem auto 1rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "520px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>What is the cost?\n</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Opting for the Pro version costs $20, which includes the app download, lifetime access to our community, and a one-on-one onboarding session with a trainer. While future app upgrades may offer new features, no additional payments are required unless you choose to upgrade.                </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Is it suitable for beginners?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Absolutely! If you're new to cycling, our app is an excellent way to start logging more miles safely and comfortably. You'll gain valuable insights into cycling best practices, terminology, and even repairs through our forums.            </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>When can I begin?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Immediately! Since May 2017, our app has been out of beta and our community is expanding by dozens of members each week. We recommend joining as soon as possible, as the low introductory price won't last forever.       </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>What types of challenges are available?\n</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        You can compete in challenges tailored to your preferred rides, whether they are road, gravel, or mountain biking. We'll present you with individual and group challenges based on your preferences, which you can adjust anytime in your profile settings.      </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "520px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>How can I tell if it's right for me?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        If you love riding your bike, this app is for you. Many members have started with just 5 miles a week and have progressed to 50-mile day trips within a few months.   </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>How much do I need to participate?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Your level of participation is entirely up to you. Completing your profile and engaging in the forums can enhance your experience, but you can also use the platform solely as a fitness app if that suits you better.
                        </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Does my location affect my usage?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Anyone can use the app and join the community. However, the Find My Ride feature is currently only available in Canada and the U.S. We are working on expanding this feature to other countries soon.
                        </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>What types of prizes can I win?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        We are continuously adding new prizes each month through our growing list of sponsors. You can expect opportunities to win gear, gift certificates, and even trips to bike-friendly destinations.
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // FAQ-2
  editor.BlockManager.add("Faq-2", {
    id: "Faq-2",
    label: `
    <div>
      <img src="${FaqTwo}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#fff",
          width: "100%",
          margin: "auto",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "3rem",
              "padding-bottom": "3rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "800px",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style=' font: bold 3rem Tahoma, sans-serif; color: #000;'>FAQs</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
              color: "#000",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "800px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Where is the Event Full held?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        This year's Event Full will take place at the Dubai World Trade Center from December 20-27.                </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Where should I stay?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                      We recommend staying at a hotel downtown within a few blocks of the Dubai World Trade Center for convenient access to the conference
                       throughout the week. Check out our list of recommended hotels <span style="text-decoration: underline;">here</span>   </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>How do I sign up to host a workshop?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        We're excited about your interest in hosting a workshop at Event Full. While this year's slots are all filled, 
                        we have many openings for next year. <span style="text-decoration: underline;"> Sign up here </span> to secure your spot.       </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>\n" +
                          "Are tickets refundable?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       Yes, all tickets are 100% refundable within 30 days of the event. 
                       If you need a refund within this period, we might have individuals on a ticket waiting list.
                        Please <span style="text-decoration: underline;"> contact our support team to request a refund.  </span>   </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // FAQ-3
  editor.BlockManager.add("Faq-3", {
    id: "Faq-3",
    label: `
    <div>
      <img src="${FaqThree}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#000",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "3rem",
              "padding-bottom": "3rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "1040px",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style=' font: bold .875rem Tahoma, sans-serif; color: #fff;'>FREQUENTLY ASKED QUESTIONS</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: bold 2.5rem Tahoma, sans-serif; text-align: center;  color: #fff'>
                We have answers for your questions.                </span>`,
                style: {
                  width: "100%",
                  margin: "1rem auto 1rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
              color: "#fff",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "520px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>What is the cost?\n</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Opting for the Pro version costs $20, which includes the app download, lifetime access to our community, and a one-on-one onboarding session with a trainer. While future app upgrades may offer new features, no additional payments are required unless you choose to upgrade.                </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Is it suitable for beginners?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Absolutely! If you're new to cycling, our app is an excellent way to start logging more miles safely and comfortably. You'll gain valuable insights into cycling best practices, terminology, and even repairs through our forums.            </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>When can I begin?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Immediately! Since May 2017, our app has been out of beta and our community is expanding by dozens of members each week. We recommend joining as soon as possible, as the low introductory price won't last forever.       </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>What types of challenges are available?\n</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        You can compete in challenges tailored to your preferred rides, whether they are road, gravel, or mountain biking. We'll present you with individual and group challenges based on your preferences, which you can adjust anytime in your profile settings.      </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "520px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>How can I tell if it's right for me?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        If you love riding your bike, this app is for you. Many members have started with just 5 miles a week and have progressed to 50-mile day trips within a few months.   </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>How much do I need to participate?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Your level of participation is entirely up to you. Completing your profile and engaging in the forums can enhance your experience, but you can also use the platform solely as a fitness app if that suits you better.
                        </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Does my location affect my usage?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        Anyone can use the app and join the community. However, the Find My Ride feature is currently only available in Canada and the U.S. We are working on expanding this feature to other countries soon.
                        </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                      "min-height": "200px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>What types of prizes can I win?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        We are continuously adding new prizes each month through our growing list of sponsors. You can expect opportunities to win gear, gift certificates, and even trips to bike-friendly destinations.
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // FAQ-4
  editor.BlockManager.add("Faq-4", {
    id: "Faq-4",
    label: `
    <div>
      <img src="${FaqFour}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#000",
          width: "100%",
          margin: "auto",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "3rem",
              "padding-bottom": "3rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "800px",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style=' font: bold 3rem Tahoma, sans-serif; color: #fff;'>FAQs</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
              color: "#fff",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "800px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Where is the Event Full held?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        This year's Event Full will take place at the Dubai World Trade Center from December 20-27.                </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>Where should I stay?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                      We recommend staying at a hotel downtown within a few blocks of the Dubai World Trade Center for convenient access to the conference
                       throughout the week. Check out our list of recommended hotels <span style="text-decoration: underline;">here</span>   </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>How do I sign up to host a workshop?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                        We're excited about your interest in hosting a workshop at Event Full. While this year's slots are all filled, 
                        we have many openings for next year. <span style="text-decoration: underline;"> Sign up here </span> to secure your spot.       </span>`,
                        style: {},
                      },
                    ],
                  },

                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'>\n" +
                          "Are tickets refundable?</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       Yes, all tickets are 100% refundable within 30 days of the event. 
                       If you need a refund within this period, we might have individuals on a ticket waiting list.
                        Please <span style="text-decoration: underline;"> contact our support team to request a refund.  </span>   </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });

  // Testimonials Section
  // Testimonials-0
  editor.BlockManager.add("Testimonials-0", {
    id: "testimonials-0",
    label: `
    <div>
      <img src="${TmZero}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#FFF",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "100px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "max-width": "100px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 40pt Tahoma, sans-serif;text-align: left;color: rgb(101 66 255);'> \" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  width: "fit-content",
                  "max-width": "685px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: center;'> I was thrilled to launch my online store, but soon realized my copy wasn't good enough to rank on the first page of search results. Stellar Copy provides everything I need for my site every month. </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: center;line-height: 1.625;'>
                       - Lisa M. 
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "100px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "max-width": "100px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 40pt Tahoma, sans-serif;text-align: left;color: rgb(101 66 255);'> \"  </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-1
  editor.BlockManager.add("Testimonials-1", {
    id: "testimonials-1",
    label: `
    <div>
      <img src="${TmOne}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#FFFFFF",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "450px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "170px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"I've received invaluable advice from Jamie, truly transformative.\" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                      - Taylor (Newton, MA)       </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "450px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "170px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"I've been a Gritness subscriber for nearly a year, and my recipe collection has significantly improved.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       - Emma (Austin, TX)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-2
  editor.BlockManager.add("Testimonials-2", {
    id: "testimonials-2",
    label: `
    <div>
      <img src="${TmThree}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#FFFFFF",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"I've received invaluable advice from Jamie, truly transformative.\" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                      - Taylor (Newton, MA)       </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"I've been a Gritness subscriber for nearly a year, and my recipe collection has significantly improved.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       - Emma (Austin, TX)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"\n" +
                          "If you've been doing this alone, you're missing out. Alex will elevate your skills.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       - Jordan (Chicago, IL)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-4
  editor.BlockManager.add("Testimonials-4", {
    id: "testimonials-4",
    label: `
    <div>
      <img src="${TmEight}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#FFFFFF",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirlB,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "text-align": "left",
                          height: "100px",
                          width: "100px", // Ensures the image stretches to fit the container
                          margin: "1.5rem auto 2rem", // Center the image vertically within its container
                          display: "block",
                          "border-radius": "100%",
                          "object-fit": "cover",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"I've received invaluable advice from Jamie, truly transformative.\" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                      - Taylor (Newton, MA)       </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirl,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "text-align": "left",
                          height: "100px",
                          width: "100px", // Ensures the image stretches to fit the container
                          margin: "1.5rem auto 2rem", // Center the image vertically within its container
                          display: "block",
                          "border-radius": "100%",
                          "object-fit": "cover",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"I've been a Gritness subscriber for nearly a year, and my recipe collection has significantly improved.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       - Emma (Austin, TX)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarBoy,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "text-align": "left",
                          height: "100px",
                          width: "100px", // Ensures the image stretches to fit the container
                          margin: "1.5rem auto 2rem", // Center the image vertically within its container
                          display: "block",
                          "border-radius": "100%",
                          "object-fit": "cover",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;'> \"\n" +
                          "If you've been doing this alone, you're missing out. Alex will elevate your skills.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;'>
                       - Jordan (Chicago, IL)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-3
  editor.BlockManager.add("Testimonials-3", {
    id: "Testimonials-3",
    label: `
    <div>
      <img src="${TmSix}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#fff",
          width: "100%",
          margin: "auto",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "1.5rem",
              "padding-bottom": "1.5rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "800px",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style=' font: bold 2rem Tahoma, sans-serif; color: #000;'>Our Customers Are Delighted</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
              "flex-direction": "column",
              color: "#000",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "800px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: center;line-height: 1.625;'>
                         “The food at Unique Noodles is consistently fresh and incredibly delicious. I usually get the Classic Bowl and pile on the veggies and toppings, which keeps me full and satisfied for hours. Being health-conscious, I never feel guilty after eating at Unique Noodles. I go there twice a week because I simply can't get enough!"             </span>`,
                        style: {
                          "margin-bottom": "1rem",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 12pt Tahoma, sans-serif;text-align: left;'>Michael Reed</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "800px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: center;line-height: 1.625;'>
                         “I moved here from Japan four years ago, and I've been searching everywhere for food that reminds me of home. Unique Noodles is the first place that takes me back to my childhood with an authentic, rich experience."             </span>`,
                        style: {
                          "margin-bottom": "1rem",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 12pt Tahoma, sans-serif;text-align: left;'>Hiroshi Tanaka</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-8
  editor.BlockManager.add("Testimonials-8", {
    id: "testimonials-8",
    label: `
    <div>
      <img src="${TmFive}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#000",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "100px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "max-width": "100px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 40pt Tahoma, sans-serif;text-align: left;color: rgb(101 66 255);'> \" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  width: "fit-content",
                  "max-width": "685px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: center;color: #fff;'> I was thrilled to launch my online store, but soon realized my copy wasn't good enough to rank on the first page of search results. Stellar Copy provides everything I need for my site every month. </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: center;line-height: 1.625;color: #fff;'>
                       - Lisa M. 
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "100px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "max-width": "100px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 40pt Tahoma, sans-serif;text-align: left;color: rgb(101 66 255);'> \"  </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-9
  editor.BlockManager.add("Testimonials-9", {
    id: "testimonials-9",
    label: `
    <div>
      <img src="${TmTwo}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#000",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "450px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "170px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left; color:#fff;'> \"I've received invaluable advice from Jamie, truly transformative.\" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left; line-height: 1.625; color:#fff;'>
                      - Taylor (Newton, MA)       </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "450px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "170px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left; color:#fff;'> \"I've been a Gritness subscriber for nearly a year, and my recipe collection has significantly improved.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color:#fff;'>
                       - Emma (Austin, TX)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-10
  editor.BlockManager.add("Testimonials-10", {
    id: "testimonials-10",
    label: `
    <div>
      <img src="${TmFour}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#000",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left; color:#fff;'> \"I've received invaluable advice from Jamie, truly transformative.\" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color:#fff;'>
                      - Taylor (Newton, MA)       </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;color:#fff;'> \"I've been a Gritness subscriber for nearly a year, and my recipe collection has significantly improved.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color:#fff;'>
                       - Emma (Austin, TX)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;color:#fff;'> \"\n" +
                          "If you've been doing this alone, you're missing out. Alex will elevate your skills.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color:#fff;'>
                       - Jordan (Chicago, IL)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-12
  editor.BlockManager.add("Testimonials-12", {
    id: "testimonials-12",
    label: `
    <div>
      <img src="${TmNine}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#000",
          width: "100%",
          padding: "2rem 0",
        },
        components: [
          {
            tagName: "div",
            removable: true,
            stylable: true,
            draggable: true,
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirlB,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "text-align": "left",
                          height: "100px",
                          width: "100px", // Ensures the image stretches to fit the container
                          margin: "1.5rem auto 2rem", // Center the image vertically within its container
                          display: "block",
                          "border-radius": "100%",
                          "object-fit": "cover",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;color: #fff;'> \"I've received invaluable advice from Jamie, truly transformative.\" </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color: #fff;'>
                      - Taylor (Newton, MA)       </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarGirl,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "text-align": "left",
                          height: "100px",
                          width: "100px", // Ensures the image stretches to fit the container
                          margin: "1.5rem auto 2rem", // Center the image vertically within its container
                          display: "block",
                          "border-radius": "100%",
                          "object-fit": "cover",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;color: #fff;'> \"I've been a Gritness subscriber for nearly a year, and my recipe collection has significantly improved.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color: #fff;'>
                       - Emma (Austin, TX)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "left",
                  width: "fit-content",
                  "max-width": "320px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "1rem",
                      padding: "0 18px",
                      "min-height": "140px",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: AvatarBoy,
                          alt: "Image",
                          class: "list-item-image",
                        },
                        style: {
                          "text-align": "left",
                          height: "100px",
                          width: "100px", // Ensures the image stretches to fit the container
                          margin: "1.5rem auto 2rem", // Center the image vertically within its container
                          display: "block",
                          "border-radius": "100%",
                          "object-fit": "cover",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 18pt Tahoma, sans-serif;text-align: left;color: #fff;'> \"\n" +
                          "If you've been doing this alone, you're missing out. Alex will elevate your skills.\"\n" +
                          "\n </span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: left;line-height: 1.625;color: #fff;'>
                       - Jordan (Chicago, IL)
                        </span>`,
                        style: {},
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Testimonials-11
  editor.BlockManager.add("Testimonials-11", {
    id: "Testimonials-11",
    label: `
    <div>
      <img src="${TmSeven}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          "background-color": "#000",
          width: "100%",
          margin: "auto",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "1.5rem",
              "padding-bottom": "1.5rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
              "max-width": "800px",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style=' font: bold 2rem Tahoma, sans-serif; color: #fff;'>Our Customers Are Delighted</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "1rem auto 0",
              "flex-direction": "column",
              color: "#000",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "800px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: center;line-height: 1.625;color: #fff;'>
                         “The food at Unique Noodles is consistently fresh and incredibly delicious. I usually get the Classic Bowl and pile on the veggies and toppings, which keeps me full and satisfied for hours. Being health-conscious, I never feel guilty after eating at Unique Noodles. I go there twice a week because I simply can't get enough!"             </span>`,
                        style: {
                          "margin-bottom": "1rem",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 12pt Tahoma, sans-serif;text-align: left;color: #fff;'>Michael Reed</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "800px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      "margin-bottom": "3rem",
                      padding: "0 18px",
                    },
                    components: [
                      {
                        type: "text",
                        content: `
                        <span style='font: 14pt Tahoma, sans-serif; text-align: center;line-height: 1.625;color: #fff;'>
                         “I moved here from Japan four years ago, and I've been searching everywhere for food that reminds me of home. Unique Noodles is the first place that takes me back to my childhood with an authentic, rich experience."             </span>`,
                        style: {
                          "margin-bottom": "1rem",
                        },
                      },
                      {
                        type: "text",
                        content:
                          "<span style='font: bold 12pt Tahoma, sans-serif;text-align: left;color: #fff;'>Hiroshi Tanaka</span>",
                        style: { margin: "1rem auto 1.5rem" },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });

  // Call To Action Section
  // CallToAction-Item-1
  editor.BlockManager.add("CallToAction-Item-1", {
    id: "calltoaction-item-1",
    label: `
    <div>
          <img src="${CallToActionThumbnailOne}" />
    </div>
  `,

    attributes: { class: "custom-class hide-from-widget" },

    content: `<div style="background-color: #ffffff;text-align: center;padding: 2rem;"><div style="padding: 2rem; text-align: center; width: fit-content; display: block; margin: auto;"><div style="text-align: center; color: #666666; font: 12px Lato, sans-serif;  ">SPECIAL OFFER</div>
    <br/><br/><div style="font-weight: bold; font-size: 32px;">Discover your power sooner. Join as a member.</div><br/><div style='font: 16px Lato, sans-serif; margin: 1rem 1rem 2rem'>A monthly membership might be exactly what you need to take charge of your life.</div><br/><br/>
     <a style="margin: auto; background-color: #6542FF; color: #ffffff; border: 1px solid #47525d; padding: 5px 10px;font-size: 14px; text-decoration:none;  display: block;width:80px;text-align:center" data-gjs-type="link">
  Buy Now
  </a></div></div>
    `,

    activeOnRender: true,
    editable: true,
  });
  // CallToAction-Item-2
  editor.BlockManager.add("CallToAction-Item-2", {
    id: "calltoaction-item-2",
    label: `
    <div>
          <img src="${CallToActionThumbnailTwo}" />
    </div>
  `,

    attributes: { class: "custom-class hide-from-widget" },

    content: `<div style="background-color: #000000;text-align: center; color: #ffffff;padding: 2rem;"><div style="padding: 2rem; text-align: center; width: fit-content; display: block; margin: auto;"><div style="text-align: center; font: 12px Lato, sans-serif;  ">SPECIAL OFFER</div>
    <br/><br/><div style="font-weight: bold; font-size: 32px;">Discover your power sooner. Join as a member.</div><br/><div style='font: 16px Lato, sans-serif; margin: 1rem 1rem 2rem'>A monthly membership might be exactly what you need to take charge of your life.</div><br/><br/>
     <a style="margin: auto; background-color: #6542FF; color: #ffffff; border: 1px solid #47525d; padding: 5px 10px;font-size: 14px; text-decoration:none;  display: block;width:80px;text-align:center" data-gjs-type="link">
  Buy Now
  </a></div></div>
    `,

    activeOnRender: true,
  });
  // CallToAction-Item-3
  editor.BlockManager.add("CallToAction-Item-3", {
    id: "calltoaction-item-3",
    label: `
    <div>
          <img src="${CallToActionThumbnailThree}" />
    </div>
  `,

    attributes: { class: "custom-class hide-from-widget" },

    content: `<div style="background-color: #cccccc;text-align: center; padding: 2rem;"><div style="background-color: #ffffff;padding: 2rem; text-align: center; width: fit-content; display: block; margin: auto;"><div style="text-align: center; font: 12px Lato, sans-serif;  ">SPECIAL OFFER</div>
    <br/><br/><div style="font-weight: bold; font-size: 32px;">Discover your power sooner. Join as a member.</div><br/><br/><div style='font: 16px Lato, sans-serif; margin: 1rem 1rem 2rem'>A monthly membership might be exactly what you need to take charge of your life.</div><br/><br/>
     <a style="margin: auto; background-color: #6542FF; color: #ffffff; border: 1px solid #47525d; padding: 5px 10px;font-size: 14px; text-decoration:none;  display: block;width:80px;text-align:center" data-gjs-type="link">
  Buy Now
  </a></div></div>
    `,

    activeOnRender: true,
  });

  // CallToAction-Item-4
  editor.BlockManager.add("CallToAction-Item-4", {
    id: "calltoaction-item-4",
    label: `
    <div>
      <img src="${CallToActionThumbnailFour}" />
    </div>
  `,
    attributes: { class: "custom-class" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#000000",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              background: "#000000",
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "auto",
            },
            components: [
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  width: "fit-content",
                  "max-width": "512px",
                  "text-align": "left",
                  "padding-left": "1rem",
                  "padding-right": "2.5rem",
                  color: "#ffffff",
                },
                components: [
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 18pt Tahoma, sans-serif;'>UNLOCK EXCLUSIVE OFFERS.</span>",
                    style: {
                      margin: "1rem auto 1rem",
                      "line-height": "30px",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 34pt Tahoma, sans-serif; text-align: left;'>
                     Join our <br/>Mailing List<br/> Today!
                    </span>`,
                    style: {
                      width: "90%",
                      "line-height": "25px",
                      margin: "0.5rem auto 1.5rem 0",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                     A monthly newsletter might be just what you need to take control of your life.
                    </span>`,
                    style: {
                      width: "90%",
                      "max-width": "285px",
                      "line-height": "28px",
                      margin: "0.5rem auto 1.5rem 0",
                    },
                  },
                  {
                    tagName: "input",
                    type: "text",
                    attributes: { placeholder: "Email" },
                    style: {
                      width: "100%",
                      "max-width": "300px",
                      display: "block",
                      margin: "2rem 0 0.5rem",
                      padding: "0.5rem 0",
                    },
                  },

                  {
                    type: "link",
                    attributes: { class: "" },
                    content: `Sign Up`,
                    style: {
                      "text-align": "center",
                      display: "block",
                      width: "100%",
                      "max-width": "300px",
                      "background-color": "#6542FF",
                      cursor: "pointer",
                      color: "#FFFFFF",
                      padding: "0.5rem 2px",
                      border: "none",
                      margin: "0.5rem 0 2rem",
                      font: "12pt Tahoma, sans-serif",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  "text-align": "left",
                  "max-width": "512px",
                  "min-width": "290px",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    name: "dfy-image",
                    removable: true,
                    stylable: true,
                    draggable: true,
                    attributes: {
                      src: Image9,
                      alt: "Image",
                      class: "calltoaction-image",
                    },
                    style: {
                      "text-align": "left",
                      "max-width": "512px",
                      "min-width": "290px",
                      width: "100%",
                      margin: "0 auto",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // CallToAction-Item-5
  editor.BlockManager.add("CallToAction-Item-5", {
    id: "calltoaction-item-5",
    label: `
    <div>
      <img src="${CallToActionThumbnailFive}" />
    </div>
  `,
    attributes: { class: "custom-class" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#ffffff",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              background: "#ffffff",
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "auto",
            },
            components: [
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  width: "fit-content",
                  "max-width": "512px",
                  "text-align": "left",
                  "padding-left": "1rem",
                  "padding-right": "2.5rem",
                  color: "#000000",
                },
                components: [
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 18pt Tahoma, sans-serif;'>UNLOCK EXCLUSIVE OFFERS.</span>",
                    style: {
                      margin: "1rem auto 1rem",
                      "line-height": "30px",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 34pt Tahoma, sans-serif; text-align: left;'>
                     Join our <br/>Mailing List<br/> Today!
                    </span>`,
                    style: {
                      width: "90%",
                      "line-height": "25px",
                      margin: "0.5rem auto 1.5rem 0",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                     A monthly newsletter might be just what you need to take control of your life.
                    </span>`,
                    style: {
                      width: "90%",
                      "max-width": "285px",
                      "line-height": "28px",
                      margin: "0.5rem auto 1.5rem 0",
                    },
                  },
                  {
                    tagName: "input",
                    type: "text",
                    attributes: { placeholder: "Email" },
                    style: {
                      width: "100%",
                      "max-width": "300px",
                      display: "block",
                      margin: "2rem 0 0.5rem",
                      padding: "0.5rem 0",
                    },
                  },

                  {
                    type: "link",
                    attributes: { class: "" },
                    content: `Sign Up`,
                    style: {
                      "text-align": "center",
                      display: "block",
                      width: "100%",
                      "max-width": "300px",
                      "background-color": "#6542FF",
                      cursor: "pointer",
                      color: "#FFFFFF",
                      padding: "0.5rem 2px",
                      border: "none",
                      margin: "0.5rem 0 2rem",
                      font: "12pt Tahoma, sans-serif",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  "text-align": "left",
                  "max-width": "512px",
                  "min-width": "290px",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    name: "dfy-image",
                    removable: true,
                    stylable: true,
                    draggable: true,
                    attributes: {
                      src: Image9,
                      alt: "Image",
                      class: "calltoaction-image",
                    },
                    style: {
                      "text-align": "left",
                      "max-width": "512px",
                      "min-width": "290px",
                      width: "100%",
                      margin: "0 auto",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });

  // Contact Us Section
  // ContactUs-Item-1
  editor.BlockManager.add("ContactUs-Item-1", {
    id: "contactus-item-1",
    label: `
    <div>
           <img src="${ContactUsThumbnailOne}" />
     </div>
   `,
    attributes: { class: "custom-class hide-from-widget" },
    content: `<div style="text-align: center; background-color: #fff;">

          <div style="padding: 2rem; text-align: center; width: fit-content; display: block; margin: auto;">
              <div style="margin: 1rem 1rem 1.5rem; color: #666; font: 1.5vh Lato, sans-serif;"> CONTACT US</div>
              <div style="margin: 1.8rem 1rem 2rem; font: 4.0vh Ariel; font-weight: bold;">You have inquiries.<br>We have responses.</div>
              <div style="margin: 1rem; font: 1.8vh Verdana, sans-serif; font-weight: normal;">Get in touch with us today.</div>
              <form style="padding: 1rem; text-align: left; width: 90%; display: block; position: relative; margin: auto;">
                  <p style="font: 1.5vh Lato, sans-serif;">Email</p>
                  <input style="width: 100%; display: block; margin: 0.5rem 0 1rem 0; padding: 0.5rem 0;" type="text" placeholder="Email Address" />
                  <a style="margin: auto; border: none; padding: 15px 2px;  background-color: #6542FF; color: #ffffff; font-size: 1rem; text-decoration:none;  display: block;width:100%; text-align:center" data-gjs-type="link">
                    Sign Up
                  </a>
              </form>
          </div>
    </div>`,

    activeOnRender: true,
  });
  // ContactUs-Item-2
  editor.BlockManager.add("ContactUs-Item-2", {
    id: "contactus-item-2",
    label: `
    <div>
          <img src="${ContactUsThumbnailTwo}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: `
    <div style="background-color: #ffffff; text-align: center; width: 100%;">
      <div style="position:relative; padding-top: 2rem; padding-bottom: 2rem; text-align: left; width: 100%; max-width: 800px; display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: auto;">
         <div style="width: 96%; max-width: 20rem; min-width: 300px;text-align: justify; padding: 0 0rem ">
            <div style="margin: 2rem auto 2.5rem; line-height: 2.5rem; letter-spacing: 0.3rem; font-weight: bold; font: 4.5vh Tahoma, sans-serif;">Let's get<br> moving.</div>
            <div style='line-height: 1.563rem; letter-spacing: 0.3rem;font: 1.5rem Tahoma, sans-serif; font-weight: 300; text-align: left;'>I've been a licensed physical therapist for more than 15 years. Growing up as an athlete, I've always been intrigued by what drives them.</div>
            <div style='margin: 1.5rem auto 2rem;line-height: 1.25rem;font: 1.2rem Tahoma, sans-serif; font-weight: 300;'>info@modernpt.com <br>555.867.5309</div>
         </div>
         <div style="width: fit-content; min-width: 300px;position:relative;">
            <form  style="text-align: left; position:relative; ">
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif;  position:relative;'>First Name
                    <input name="first_name" type="text" placeholder="First Name" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Email
                    <input name="email_add" type="text" placeholder="Email Address" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style="text-align: left; width: 91%; display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: 1.2rem;">

                    <div style=" width: fit-content; display: block;">
                        <label for="country_code" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Country Code: </label>
                        <select id="country_code" name="country_code" style="display: block; padding: 0.5rem 0; margin-top: 0.5rem;">
                            <option value="1">United States</option>
                        </select>
                    </div>
                     <div style="width: fit-content; display: block;">
                        <label for="phone_number" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Phone Number: </label>
                        <input id="phone_number" name="phone_number" placeholder="Phone number" style ="padding: 0.5rem;display: block;margin-top: 0.5rem;"/>
                    </div>

                </div>
                 <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Comment
                   <textarea placeholder="Comment" style="width: 90%; max-width: 300px; max-height: 300px; display: block; margin-top: 0.5rem; padding: 0.5rem 0; min-height: 90px;"></textarea>
                </div>
                <div style='margin: 1.5rem auto 0rem; position:relative;'>
                    <a style="margin-top: 1rem; border: none; padding: 15px 0px;  background-color: #6542FF; color: #ffffff; font-size: 1rem; text-decoration:none;  display: block;width:90%; text-align:center" data-gjs-type="link">
                    Send
                </a>
                </div>
               

            </form>
         </div>
      </div>
    </div>
    `,

    activeOnRender: true,
  });
  // ContactUs-Item-3
  editor.BlockManager.add("ContactUs-Item-3", {
    id: "contactus-item-3",
    label: `
    <div>
          <img src="${ContactUsThumbnailThree}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: `
    <div style="background-color: #ffffff; text-align: center; width: 100%;">
      <div style="position:relative; padding-top: 2rem; padding-bottom: 2rem; text-align: left; width: 100%; max-width: 800px; display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: auto;">
      <div style="width: fit-content; min-width: 300px;position:relative;">
            <form  style="text-align: left; position:relative; ">
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif;  position:relative;'>First Name
                    <input name="first_name" type="text" placeholder="First Name" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Email
                    <input name="email_add" type="text" placeholder="Email Address" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style="text-align: left; width: 91%; display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: 1.2rem;">

                    <div style=" width: fit-content; display: block;">
                        <label for="country_code" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Country Code: </label>
                        <select id="country_code" name="country_code" style="display: block; padding: 0.5rem 0; margin-top: 0.5rem;">
                            <option value="1">United States</option>
                        </select>
                    </div>
                     <div style="width: fit-content; display: block;">
                        <label for="phone_number" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Phone Number: </label>
                        <input id="phone_number" name="phone_number" placeholder="Phone number" style ="padding: 0.5rem;display: block;margin-top: 0.5rem;"/>
                    </div>

                </div>
                 <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Comment
                   <textarea placeholder="Comment" style="width: 90%; max-width: 300px; max-height: 300px; display: block; margin-top: 0.5rem; padding: 0.5rem 0; min-height: 90px;"></textarea>
                </div>
                <div style='margin: 1.5rem auto 0rem; position:relative;'>
                    <a style="margin-top: 1rem; border: none; padding: 15px 0px;  background-color: #6542FF; color: #ffffff; font-size: 1rem; text-decoration:none;  display: block;width:90%; text-align:center" data-gjs-type="link">
                    Send
                </a>
                </div>
               

            </form>
         </div>   
      <div style="width: 90%; max-width: 20rem; min-width: 300px;text-align: justify; padding: 0 0 ">
            <div style="margin: 2rem auto 2.5rem; line-height: 2.5rem; letter-spacing: 0.3rem; font-weight: bold; font: 4.5vh Tahoma, sans-serif;">Let's get<br> moving.</div>
            <div style='line-height: 1.563rem; letter-spacing: 0.3rem;font: 1.5rem Tahoma, sans-serif; font-weight: 300; text-align: left;'>I've been a licensed physical therapist for more than 15 years. Growing up as an athlete, I've always been intrigued by what drives them.</div>
            <div style='margin: 1.5rem auto 2rem;line-height: 1.25rem;font: 1.2rem Tahoma, sans-serif; font-weight: 300;'>info@modernpt.com <br>555.867.5309</div>
         </div>
         
      </div>
    </div>
    `,

    activeOnRender: true,
  });
  // ContactUs-Item-4
  editor.BlockManager.add("ContactUs-Item-4", {
    id: "contactus-item-4",
    label: `
    <div>
      <img src="${ContactUsThumbnailFour}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#ffffff",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              background: "#ffffff",
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "auto",
            },
            components: [
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  "max-width": "512px",
                  "min-width": "290px",
                  "margin-bottom": "1rem",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    name: "dfy-image",
                    removable: true,
                    stylable: true,
                    draggable: true,
                    attributes: {
                      src: Image9,
                      alt: "Image",
                      class: "calltoaction-image",
                    },
                    style: {
                      "max-width": "512px",
                      "min-width": "290px",
                      width: "80%",
                      margin: "0 auto",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  width: "fit-content",
                  "max-width": "512px",
                  "text-align": "left",
                  "padding-left": "0.5rem",
                  "padding-right": "0.5rem",
                  color: "#000000",
                },
                components: [
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 12pt Tahoma, sans-serif;'>CONTACT US</span>",
                    style: {
                      margin: "0rem auto 1.5rem",
                      "line-height": "10px",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 20pt Tahoma, sans-serif; text-align: left;'>
                     You have inquiries.<br/>We have responses.
                    </span>`,
                    style: {
                      width: "100%",
                      "letter-spacing": "1px",
                      "line-height": "18px",
                      margin: "3.5rem auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 14pt Tahoma, sans-serif; text-align: left;'>
                     Get in touch with us today.
                    </span>`,
                    style: {
                      width: "100%",
                      "max-width": "285px",
                      "line-height": "12px",
                      margin: "2rem auto 1.5rem",
                    },
                  },
                  {
                    tagName: "input",
                    type: "text",
                    attributes: { placeholder: "Email Address" },
                    style: {
                      width: "100%",
                      "max-width": "300px",
                      display: "block",
                      margin: "4rem 0 0.5rem",
                      padding: "0.5rem 0",
                    },
                  },

                  {
                    type: "link",
                    attributes: { class: "" },
                    content: `Sign Up`,
                    style: {
                      "text-align": "center",
                      display: "block",
                      "white-space": "normal",
                      width: "100%",
                      "max-width": "300px",
                      "background-color": "#6542FF",
                      cursor: "pointer",
                      color: "#FFFFFF",
                      padding: "0.5rem 2px",
                      border: "none",
                      margin: "0.5rem 0 2rem",
                      font: "12pt Tahoma, sans-serif",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // ContactUs-Item-5
  editor.BlockManager.add("ContactUs-Item-5", {
    id: "contactus-item-5",
    label: `
    <div>
           <img src="${ContactUsThumbnailFive}" />
     </div>
   `,
    attributes: { class: "custom-class hide-from-widget" },
    content: `<div style="text-align: center; background-color: #000000;">

          <div style="padding: 2rem; text-align: center; width: fit-content; display: block; margin: auto; color: #FFFFFF;">
              <div style="margin: 1rem 1rem 1.5rem; color: #666; font: 1.5vh Lato, sans-serif;"> CONTACT US</div>
              <div style="margin: 1.8rem 1rem 2rem; font: 4.0vh Ariel; font-weight: bold;">You have inquiries.<br>We have responses.</div>
              <div style="margin: 1rem; font: 1.8vh Verdana, sans-serif; font-weight: normal;">Get in touch with us today.</div>
              <form style="padding: 1rem; text-align: left; width: 90%; display: block; position: relative; margin: auto;">
                  <p style="font: 1.5vh Lato, sans-serif;">Email</p>
                  <input style="width: 100%; display: block; margin: 0.5rem 0 1rem 0; padding: 0.5rem 0;" type="text" placeholder="Email Address" />
                  <a style="margin: auto; border: none; padding: 15px 2px;  background-color: #6542FF; color: #ffffff; font-size: 1rem; text-decoration:none;  display: block;width:100%; text-align:center" data-gjs-type="link">
                    Sign Up
                  </a>
              </form>
          </div>
    </div>`,

    activeOnRender: true,
  });
  // ContactUs-Item-6
  editor.BlockManager.add("ContactUs-Item-6", {
    id: "contactus-item-6",
    label: `
    <div>
          <img src="${ContactUsThumbnailSix}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: `
    <div style="background-color: #000000; text-align: center; width: 100%;">
      <div style="color: #FFFFFF;position:relative; padding-top: 2rem; padding-bottom: 2rem; text-align: left; width: 100%; max-width: 800px; display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: auto;">
         <div style="width: auto; max-width: 20rem; min-width: 300px;text-align: justify; padding: 0 0 ">
            <div style="margin: 2rem auto 2.5rem; line-height: 2.5rem; letter-spacing: 0.3rem; font-weight: bold; font: 4.5vh Tahoma, sans-serif;">Let's get<br> moving.</div>
            <div style='line-height: 1.563rem; letter-spacing: 0.3rem;font: 1.5rem Tahoma, sans-serif; font-weight: 300; text-align: left;'>I've been a licensed physical therapist for more than 15 years. Growing up as an athlete, I've always been intrigued by what drives them.</div>
            <div style='margin: 1.5rem auto 2rem;line-height: 1.25rem;font: 1.2rem Tahoma, sans-serif; font-weight: 300;'>info@modernpt.com <br>555.867.5309</div>
         </div>
         <div style="width: fit-content; min-width: 300px;position:relative;">
            <form  style="text-align: left; position:relative; ">
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif;  position:relative;'>First Name
                    <input name="first_name" type="text" placeholder="First Name" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Email
                    <input name="email_add" type="text" placeholder="Email Address" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style="text-align: left; width: 91%; display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: 1.2rem;">

                    <div style=" width: fit-content; display: block;">
                        <label for="country_code" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Country Code: </label>
                        <select id="country_code" name="country_code" style="display: block; padding: 0.5rem 0; margin-top: 0.5rem;">
                            <option value="1">United States</option>
                        </select>
                    </div>
                     <div style="width: fit-content; display: block;">
                        <label for="phone_number" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Phone Number: </label>
                        <input id="phone_number" name="phone_number" placeholder="Phone number" style ="padding: 0.5rem;display: block;margin-top: 0.5rem;"/>
                    </div>

                </div>
                 <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Comment
                   <textarea placeholder="Comment" style="width: 90%; max-width: 300px; max-height: 300px; display: block; margin-top: 0.5rem; padding: 0.5rem 0; min-height: 90px;"></textarea>
                </div>
                <div style='margin: 1.5rem auto 0rem; position:relative;'>
                    <a style="margin-top: 1rem; border: none; padding: 15px 0px;  background-color: #6542FF; color: #ffffff; font-size: 1rem; text-decoration:none;  display: block;width:90%; text-align:center" data-gjs-type="link">
                    Send
                </a>
                </div>
               

            </form>
         </div>
      </div>
    </div>
    `,

    activeOnRender: true,
  });
  // ContactUs-Item-7
  editor.BlockManager.add("ContactUs-Item-7", {
    id: "contactus-item-7",
    label: `
    <div>
          <img src="${ContactUsThumbnailSeven}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: `
    <div style="background-color: #000000; text-align: center; width: 100%;">
      <div style="color: #FFFFFF;position:relative; padding-top: 2rem; padding-bottom: 2rem; text-align: left; width: 100%; max-width: 800px; display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: auto;">
      <div style="width: fit-content; min-width: 300px;position:relative;">
            <form  style="text-align: left; position:relative; ">
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif;  position:relative;'>First Name
                    <input name="first_name" type="text" placeholder="First Name" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Email
                    <input name="email_add" type="text" placeholder="Email Address" style="display: block; margin: 0.5rem 0 0.5rem; padding: 0.5rem 0; width: 90%;" />
                </div>
                <div style="text-align: left; width: 91%; display: flex; flex-wrap: wrap; justify-content: space-between; margin-top: 1.2rem;">

                    <div style=" width: fit-content; display: block;">
                        <label for="country_code" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Country Code: </label>
                        <select id="country_code" name="country_code" style="display: block; padding: 0.5rem 0; margin-top: 0.5rem;">
                            <option value="1">United States</option>
                        </select>
                    </div>
                     <div style="width: fit-content; display: block;">
                        <label for="phone_number" style="font: 1.5vh Lato, sans-serif; margin-top: 0.5rem;"> Phone Number: </label>
                        <input id="phone_number" name="phone_number" placeholder="Phone number" style ="padding: 0.5rem;display: block;margin-top: 0.5rem;"/>
                    </div>

                </div>
                 <div style='margin: 1.5rem auto 0rem; font: 1.5vh Lato, sans-serif; position:relative;'>Comment
                   <textarea placeholder="Comment" style="width: 90%; max-width: 300px; max-height: 300px; display: block; margin-top: 0.5rem; padding: 0.5rem 0; min-height: 90px;"></textarea>
                </div>
                <div style='margin: 1.5rem auto 0rem; position:relative;'>
                    <a style="margin-top: 1rem; border: none; padding: 15px 0px;  background-color: #6542FF; color: #ffffff; font-size: 1rem; text-decoration:none;  display: block;width:90%; text-align:center" data-gjs-type="link">
                    Send
                </a>
                </div>
               

            </form>
         </div>   
      <div style="width: auto; max-width: 20rem; min-width: 300px;text-align: justify; padding: 0 0 ">
            <div style="margin: 2rem auto 2.5rem; line-height: 2.5rem; letter-spacing: 0.3rem; font-weight: bold; font: 4.5vh Tahoma, sans-serif;">Let's get<br> moving.</div>
            <div style='line-height: 1.563rem; letter-spacing: 0.3rem;font: 1.5rem Tahoma, sans-serif; font-weight: 300; text-align: left;'>I've been a licensed physical therapist for more than 15 years. Growing up as an athlete, I've always been intrigued by what drives them.</div>
            <div style='margin: 1.5rem auto 2rem;line-height: 1.25rem;font: 1.2rem Tahoma, sans-serif; font-weight: 300;'>info@modernpt.com <br>555.867.5309</div>
         </div>
         
      </div>
    </div>
    `,

    activeOnRender: true,
  });
  // ContactUs-Item-8
  editor.BlockManager.add("ContactUs-Item-8", {
    id: "contactus-item-8",
    label: `
    <div>
      <img src="${ContactUsThumbnailEight}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#000000",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              background: "#000000",
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "auto",
            },
            components: [
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  "max-width": "512px",
                  "min-width": "290px",
                  "margin-bottom": "1rem",
                },
                components: [
                  {
                    type: "image",
                    tagName: "img",
                    name: "dfy-image",
                    removable: true,
                    stylable: true,
                    draggable: true,
                    attributes: {
                      src: Image9,
                      alt: "Image",
                      class: "calltoaction-image",
                    },
                    style: {
                      "max-width": "512px",
                      "min-width": "290px",
                      width: "80%",
                      margin: "0 auto",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                removable: true,
                stylable: true,
                draggable: true,
                style: {
                  width: "fit-content",
                  "max-width": "512px",
                  "text-align": "left",
                  "padding-left": "0.5rem",
                  "padding-right": "0.5rem",
                  color: "#FFFFFF",
                },
                components: [
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 12pt Tahoma, sans-serif;'>CONTACT US</span>",
                    style: {
                      margin: "0rem auto 1.5rem",
                      "line-height": "10px",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 20pt Tahoma, sans-serif; text-align: left;'>
                     You have inquiries.<br/>We have responses.
                    </span>`,
                    style: {
                      width: "100%",
                      "letter-spacing": "1px",
                      "line-height": "18px",
                      margin: "3.5rem auto 2rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                    <span style='font: 14pt Tahoma, sans-serif; text-align: left;'>
                     Get in touch with us today.
                    </span>`,
                    style: {
                      width: "100%",
                      "max-width": "285px",
                      "line-height": "12px",
                      margin: "2rem auto 1.5rem",
                    },
                  },
                  {
                    tagName: "input",
                    type: "text",
                    attributes: { placeholder: "Email Address" },
                    style: {
                      width: "100%",
                      "max-width": "300px",
                      display: "block",
                      margin: "4rem 0 0.5rem",
                      padding: "0.5rem 0",
                    },
                  },

                  {
                    type: "link",
                    attributes: { class: "" },
                    content: `Sign Up`,
                    style: {
                      "text-align": "center",
                      display: "block",
                      "white-space": "normal",
                      width: "100%",
                      "max-width": "300px",
                      "background-color": "#6542FF",
                      cursor: "pointer",
                      color: "#FFFFFF",
                      padding: "0.5rem 2px",
                      border: "none",
                      margin: "0.5rem 0 2rem",
                      font: "12pt Tahoma, sans-serif",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });

  // Features Section
  // Features-Item-1
  editor.BlockManager.add("Features-Item-1", {
    id: "features-item-1",
    label: `
    <div>
      <img src="${FeaturesThumbnailOne}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#FFFFFF",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style='font-weight: 700; font: 28pt Tahoma, sans-serif;'>Your dream home is well taken care of.</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                 Our contractors are eager to begin work on your dream home.
                </span>`,
                style: {
                  width: "100%",
                  margin: "0.5rem auto 2rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "0 auto",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "335px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image1,
                          alt: "Image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>CERTIFIED CONTRACTORS</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 That's correct. Every one of our contractors has been certified for over 10 years.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "335px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image7,
                          alt: "Image",
                          class: "features-image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>EMPLOYEES YOU CAN TRUST</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 We love what we do, and it shows in our work and on our employees' faces.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "335px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image3,
                          alt: "Image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>YOUR DREAM IS OUR PLAN</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 It's your dream, we're here to help you achieve it. No egos involved.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Features-Item-2
  editor.BlockManager.add("Features-Item-2", {
    id: "features-item-2",
    label: `
    <div>
      <img src="${FeaturesThumbnailTwo}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#FFFFFF",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style='font-weight: 700; font: 28pt Tahoma, sans-serif;'>Your dream home is well taken care of.</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                 Our contractors are eager to begin work on your dream home.
                </span>`,
                style: {
                  width: "100%",
                  margin: "0.5rem auto 2rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "0 auto",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "290px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image1,
                          alt: "Image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>CERTIFIED CONTRACTORS</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 That's correct. Every one of our contractors has been certified for over 10 years.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "290px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image7,
                          alt: "Image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>EMPLOYEES YOU CAN TRUST</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 We love what we do, and it shows in our work and on our employees' faces.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "290px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image3,
                          alt: "Image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>YOUR DREAM IS OUR PLAN</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 It's your dream, we're here to help you achieve it. No egos involved.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "290px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image5,
                          alt: "Image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>100% QUALITY GUARANTEE</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 We strive for 100% satisfaction and won't stop until we achieve it.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Features-Item-3
  editor.BlockManager.add("Features-Item-3", {
    id: "features-item-3",
    label: `
    <div>
      <img src="${FeaturesThumbnailThree}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#FFFFFF",
          width: "100%",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "1rem",
              "padding-bottom": "1rem",
              width: "fit-content",
              margin: "0 auto",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style='font-weight: 700; font: 28pt Tahoma, sans-serif;'>Replicate outcomes. Not effort.</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },

              {
                tagName: "div",
                style: {
                  width: "90%",
                  "max-width": "680px",
                  margin: "0 auto",
                },
                components: [
                  {
                    type: "text",
                    content: `
                          <span style='font: 11pt Tahoma, sans-serif; text-align: left;'>
                           Why do extra work when you could be growing your business? Using our tested process, we'll help you find clarity in your needs and maximize your efforts from start to finish.
                          </span>`,
                    style: {
                      width: "100%",
                      margin: "0.5rem auto 1rem",
                    },
                  },
                ],
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "1rem",
              "padding-bottom": "1rem",
              width: "fit-content",
              margin: "0 auto",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "1rem",
                  width: "fit-content",
                  "max-width": "900px",
                  margin: "0 auto",
                  display: "flex",
                  "justify-content": "space-between",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "15%",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='color: #6542FF; font-weight: bold; font: 34pt Tahoma, sans-serif;'>01</span>",
                        style: {
                          margin: "auto",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "div",
                    style: {
                      width: "fit-content",
                      // margin: "0 auto",
                      padding: "1rem",
                      "text-align": "left",
                      "max-width": "590px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>Explore</span>",
                        style: {
                          // margin: "1rem auto 0.5rem",
                        },
                      },
                      {
                        type: "text",
                        content: `
                    <span style='font: 11pt Tahoma, sans-serif; text-align: justify;'>
                     This is not a templated approach. We know every business and client is unique. We'll work with you to explore, research, and understand your business.
                    </span>`,
                        style: {
                          width: "fit-content",
                          margin: "0.5rem auto 0",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "1rem",
                  width: "fit-content",
                  "max-width": "900px",
                  margin: "0 auto",
                  display: "flex",
                  "justify-content": "space-between",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "15%",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='color: #6542FF; font-weight: bold; font: 34pt Tahoma, sans-serif;'>02</span>",
                        style: {
                          margin: "auto",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "div",
                    style: {
                      width: "fit-content",
                      // margin: "0 auto",
                      padding: "1rem",
                      "text-align": "left",
                      "max-width": "590px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>Opportunity & Research</span>",
                        style: {
                          // margin: "1rem auto 0.5rem",
                        },
                      },
                      {
                        type: "text",
                        content: `
                    <span style='font: 11pt Tahoma, sans-serif; text-align: justify;'>
                     Your competitors might be doing the same, but we'll help you navigate opportunities. There's always room to improve and innovate.
                    </span>`,
                        style: {
                          width: "fit-content",
                          margin: "0.5rem auto 0",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "1rem",
                  width: "fit-content",
                  "max-width": "900px",
                  margin: "0 auto",
                  display: "flex",
                  "justify-content": "space-between",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "15%",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='color: #6542FF; font-weight: bold; font: 34pt Tahoma, sans-serif;'>03</span>",
                        style: {
                          margin: "auto",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "div",
                    style: {
                      width: "fit-content",
                      // margin: "0 auto",
                      padding: "1rem",
                      "text-align": "left",
                      "max-width": "590px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>Plan, Create, & Execute</span>",
                        style: {
                          // margin: "1rem auto 0.5rem",
                        },
                      },
                      {
                        type: "text",
                        content: `
                    <span style='font: 11pt Tahoma, sans-serif; text-align: justify;'>
                     Using an agile approach, we'll create a roadmap to completion, develop, and execute. This is where your product will truly come to life.
                    </span>`,
                        style: {
                          width: "fit-content",
                          margin: "0.5rem auto 0",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "1rem",
                  width: "fit-content",
                  "max-width": "900px",
                  margin: "0 auto",
                  display: "flex",
                  "justify-content": "space-between",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "15%",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='color: #6542FF; font-weight: bold; font: 34pt Tahoma, sans-serif;'>04</span>",
                        style: {
                          margin: "auto",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "div",
                    style: {
                      width: "fit-content",
                      // margin: "0 auto",
                      padding: "1rem",
                      "text-align": "left",
                      "max-width": "590px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>Optimization</span>",
                        style: {
                          // margin: "1rem auto 0.5rem",
                        },
                      },
                      {
                        type: "text",
                        content: `
                    <span style='font: 11pt Tahoma, sans-serif; text-align: justify;'>
                     Insights, usage, and analytics will guide us on optimizing to continually reach your customers and maximize your growth potential.
                    </span>`,
                        style: {
                          width: "fit-content",
                          margin: "0.5rem auto 0",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "1rem",
                  width: "fit-content",
                  "max-width": "900px",
                  margin: "0 auto",
                  display: "flex",
                  "justify-content": "space-between",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "15%",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='color: #6542FF; font-weight: bold; font: 34pt Tahoma, sans-serif;'>05</span>",
                        style: {
                          margin: "auto",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "div",
                    style: {
                      width: "fit-content",
                      // margin: "0 auto",
                      padding: "1rem",
                      "text-align": "left",
                      "max-width": "590px",
                    },
                    components: [
                      {
                        type: "text",
                        content:
                          "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>Tracking, Reporting, & Growing</span>",
                        style: {
                          // margin: "1rem auto 0.5rem",
                        },
                      },
                      {
                        type: "text",
                        content: `
                    <span style='font: 11pt Tahoma, sans-serif; text-align: justify;'>
                    Your business is ever-evolving. Through tracking and reporting, we'll continually partner with you to ensure your growth.
                    </span>`,
                        style: {
                          width: "fit-content",
                          margin: "0.5rem auto 0",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
  // Features-Item-4
  editor.BlockManager.add("Features-Item-4", {
    id: "features-item-4",
    label: `
    <div>
      <img src="${FeaturesThumbnailFour}" />
    </div>
  `,
    attributes: { class: "custom-class hide-from-widget" },
    content: [
      {
        tagName: "div",
        style: {
          background: "#000000",
          width: "100%",
          color: "#FFFFFF",
        },
        components: [
          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              "align-items": "start",
              margin: "auto",
            },
            components: [
              {
                type: "text",
                content:
                  "<span style='font-weight: 700; font: 28pt Tahoma, sans-serif;'>Your dream home is well taken care of.</span>",
                style: {
                  margin: "1rem auto 0.5rem",
                },
              },
              {
                type: "text",
                content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: left;'>
                 Our contractors are eager to begin work on your dream home.
                </span>`,
                style: {
                  width: "100%",
                  margin: "0.5rem auto 2rem",
                },
              },
            ],
          },

          {
            tagName: "div",
            style: {
              "text-align": "center",
              "padding-top": "2rem",
              "padding-bottom": "2rem",
              width: "fit-content",
              display: "flex",
              "flex-wrap": "wrap",
              "justify-content": "center",
              margin: "0 auto",
            },
            components: [
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "335px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image2,
                          alt: "Image",
                          class: "features-image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>CERTIFIED CONTRACTORS</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 That's correct. Every one of our contractors has been certified for over 10 years.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "335px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image8,
                          alt: "Image",
                          class: "features-image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>EMPLOYEES YOU CAN TRUST</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 We love what we do, and it shows in our work and on our employees' faces.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                style: {
                  "text-align": "center",
                  "padding-bottom": "2rem",
                  width: "fit-content",
                  "max-width": "335px",
                  margin: "0 auto",
                },
                components: [
                  {
                    tagName: "div",
                    style: {
                      width: "100%",
                      "min-height": "112px",
                      margin: "auto",
                    },
                    components: [
                      {
                        type: "image",
                        tagName: "img",
                        name: "dfy-image",
                        removable: true,
                        stylable: true,
                        draggable: true,
                        attributes: {
                          src: Image4,
                          alt: "Image",
                          class: "features-image",
                          width: "90px",
                        },
                        style: {
                          "min-width": "50px",
                          margin: "0 auto",
                        },
                      },
                    ],
                  },
                  {
                    type: "text",
                    content:
                      "<span style='font-weight: 700; font: 14pt Tahoma, sans-serif;'>YOUR DREAM IS OUR PLAN</span>",
                    style: {
                      margin: "1rem auto 0.5rem",
                    },
                  },
                  {
                    type: "text",
                    content: `
                <span style='font: 12pt Tahoma, sans-serif; text-align: justify;'>
                 It's your dream, we're here to help you achieve it. No egos involved.
                </span>`,
                    style: {
                      width: "80%",
                      margin: "0.5rem auto 2rem",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    activeOnRender: true,
  });
}
