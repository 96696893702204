import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Controls,
} from "reactflow";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import FirstNode from "./firstNode";
import ItemNode from "./itemNode";
import StatNode from "./statNode";

const nodeTypes = {
  firstNode: FirstNode,
  itemNode: ItemNode,
  statNode: StatNode,
};
let nodeId = 0;

const initialNodes = [];
const initialEdges = [];

/*eslint-disable*/
const StatFlow = forwardRef((props, ref) => {
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { pages } = props.pages;

  const rfInstance = useReactFlow();

  // const { getNodes, getEdges } = useReactFlow();

  // const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  useImperativeHandle(ref, () => ({
    getNodes() {
      return rfInstance.getNodes();
    },
    fitView() {
      setSelectedNodes([]);
      rfInstance.fitView();
      props.onSelectNode(nodes);
    },
  }));

  useEffect(() => {
    //  console.log("props.nodes", props.nodes);
    //  console.log("props.edges", props.edges);
    // console.log("pages", props.pages);

    if (props.nodes.length === 0) return;
    let newNodes = [];
    let lasPos = {
      x: 0,
      y: 0,
    };
    props.nodes.map((node) => {
      const nNode = node;
      if (node.data.fus_type === "FUNNELITEM_PAGE") {
        const pag = props.pages.filter((page) => page.pag_id === node.data.fus_pag_id)[0];
        nNode.type = "statNode";
        nNode.data.pag_thumbnailURL = pag.pag_thumbnailURL;
        nNode.data.pag_name = pag.pag_name;
        nNode.data.pag_totalVisits = pag.pag_totalVisits;
      }

      newNodes.push(nNode);
    });
    //newNodes.length > 0 ? (nodeId = Number(newNodes[0].id) + 1) : (nodeId = 0);
    setNodes(newNodes);
    setEdges(props.edges);
    rfInstance.fitView();
  }, [props.nodes]);

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      edges={edges}
      nodesConnectable={false}
      elementsSelectable={false}
      draggable={true}
      zoomOnScroll={true}
      panOnScroll={true}
      panOnScrollMode={true}
      zoomOnDoubleClick={true}
      panOnDrag={true}
      fitView
      attributionPosition="top-right"
    >
      {" "}
      <Controls />
    </ReactFlow>
  );
});

export default StatFlow;
