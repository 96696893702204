import React, { useState } from "react";
import { Form, FormGroup, Label, Input, FormText, Button, Spinner } from "reactstrap";

const SeoSettings = ({ isLoading, initialData, updatePageSettings }) => {
  console.warn("initialData", initialData);
  const { pag_title, pag_keywords, pag_description, pag_slug } = initialData ?? {};

  const [seoTitle, setSeoTitle] = useState(pag_title || "");
  const [seoKeyword, setSeoKeyword] = useState(pag_keywords || "");
  const [seoDescription, setSeoDescription] = useState(pag_description || "");
  const [seoSlug, setSeoSlug] = useState(pag_slug || "");

  const handleSave = async () => {
    updatePageSettings(seoTitle, seoKeyword, seoDescription, seoSlug);
  };

  return (
    <div className={"styleDetails"}>
      <Form>
        <FormGroup>
          <Label for="pageTitle">Page Title</Label>
          <Input
            type={"text"}
            value={seoTitle}
            onChange={(e) => setSeoTitle(e.target.value)}
            placeholder={"Enter SEO title"}
          />
          <FormText className={"text-white"}>Max 70 characters</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="keywords">Keywords (comma separated)</Label>
          <Input
            type={"text"}
            value={seoKeyword}
            onChange={(e) => setSeoKeyword(e.target.value)}
            placeholder={"Marketing, Subscription, Email etc."}
          />
        </FormGroup>
        <FormGroup>
          <Label for="Description">Description</Label>
          <Input
            type="textarea"
            value={seoDescription}
            onChange={(e) => setSeoDescription(e.target.value)}
            placeholder={"Enter SEO description"}
          />
          <FormText className={"text-white"}>Max 200 words</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="pageSlug">
            Page Slug<sup>*</sup>
          </Label>
          <Input
            type={"text"}
            value={seoSlug}
            onChange={(e) => setSeoSlug(e.target.value)}
            placeholder={"Enter the page slug"}
          />
          <FormText className={"text-white"}>Max 70 characters</FormText>
        </FormGroup>
        {/* <Button color="primary" onClick={handleSave}>Save</Button> */}
        {/* <Button color="primary" className={'btn primary-btn-modal shadow-none'} onClick={handleSave} >
                    {isLoading ? <span className={''}> Saving  <Loader /> </span> : 'Save'}
                </Button> */}
        <Button className={"primary-btn-modal shadow-none"} onClick={handleSave}>
          {isLoading ? (
            <span className={"spinnerContainer"}>
              {" "}
              Saving <Spinner>Loading...</Spinner>
            </span>
          ) : (
            "Save"
          )}
        </Button>{" "}
      </Form>
    </div>
  );
};

export default SeoSettings;
