import type grapesjs from 'grapesjs';
import { PluginOptions } from '.';
import { typeCustomCode } from './utils.ts';

export default (editor: grapesjs.Editor, { blockCustomCode }: PluginOptions = {}) => {
  const { Blocks } = editor;

  blockCustomCode && Blocks.add(typeCustomCode, {
    label: 'Dialog Box',
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 512 640" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><g><path d="M486,490H26c-2.209,0-4-1.791-4-4V26c0-2.209,1.791-4,4-4h460c2.209,0,4,1.791,4,4v460C490,488.209,488.209,490,486,490z      M30,482h452V30H30V482z"/></g><g><path d="M486,130H26c-2.209,0-4-1.791-4-4V26c0-2.209,1.791-4,4-4h460c2.209,0,4,1.791,4,4v100C490,128.209,488.209,130,486,130z      M30,122h452V30H30V122z"/></g><g><path d="M446,80H256c-2.209,0-4-1.791-4-4s1.791-4,4-4h190c2.209,0,4,1.791,4,4S448.209,80,446,80z"/></g><g><path d="M76,80H66c-2.209,0-4-1.791-4-4s1.791-4,4-4h10c2.209,0,4,1.791,4,4S78.209,80,76,80z"/></g><g><path d="M126,80h-10c-2.209,0-4-1.791-4-4s1.791-4,4-4h10c2.209,0,4,1.791,4,4S128.209,80,126,80z"/></g><g><path d="M416,440H96c-2.209,0-4-1.791-4-4V176c0-2.209,1.791-4,4-4h320c2.209,0,4,1.791,4,4v260C420,438.209,418.209,440,416,440     z M100,432h312V180H100V432z"/></g><g><path d="M416,242.4H96c-2.209,0-4-1.791-4-4V176c0-2.209,1.791-4,4-4h320c2.209,0,4,1.791,4,4v62.4     C420,240.609,418.209,242.4,416,242.4z M100,234.4h312V180H100V234.4z"/></g><g><path d="M286,300H156c-2.209,0-4-1.791-4-4s1.791-4,4-4h130c2.209,0,4,1.791,4,4S288.209,300,286,300z"/></g><g><path d="M286,340H156c-2.209,0-4-1.791-4-4s1.791-4,4-4h130c2.209,0,4,1.791,4,4S288.209,340,286,340z"/></g><g><path d="M286,380H156c-2.209,0-4-1.791-4-4s1.791-4,4-4h130c2.209,0,4,1.791,4,4S288.209,380,286,380z"/></g></g></g><text x="0" y="527" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">Created by Flatart</text><text x="0" y="532" fill="#000000" font-size="5px" font-weight="bold" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif">from the Noun Project</text></svg>
    `,
    category: 'Extra',
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...blockCustomCode
  }); 
}
