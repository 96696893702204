
export const CONTAINER_DEFINITION = {
  // extend: "image",
  // extendView: "image",
  model: {
    defaults: {
      type: "container",
      tagName: "div",
      void: true,
      droppable: 0,
      editable: 1,
      highlightable: 0,
      resizable: { ratioDefault: 1 },

      style: {
        width: "100%",
        "max-width": "1140",
        display: "flex",
      },

    },



    // isComponent(el) {
    //   return toLowerCase(el.tagName) === 'img';
    // },

  },


}
