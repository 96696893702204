import { Chip, Divider, Icon } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function StatNode(node) {
  return (
    <div
      style={{
        width: "130px",
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#96FF9D22",
      }}
    >
      <Handle type="target" position={Position.Top} />

      <MDBox display="flex">
        <Icon fontSize="small">web</Icon>
        <MDTypography variant="body2" fontWeight="medium" ml={1}>
          {node.data.fus_additionalData}{" "}
        </MDTypography>
      </MDBox>

      <Divider />
      <MDBox alignItems="center">
        <img
          align="center"
          src={node.data.pag_thumbnailURL}
          alt={node.data.fus_additionalData}
          style={{ height: "50px" }}
        />
      </MDBox>
      <MDBox alignItems="center">
        <Chip
          style={{ height: "15px" }}
          size="small"
          label={`Visits:${node.data.pag_totalVisits}`}
        />
      </MDBox>
      <Handle type="source" position={Position.Bottom}></Handle>
    </div>
  );
}
