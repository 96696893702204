
export function utils(
  editor, 
 ) {

let sectionPanel = null;


editor.Commands.add('set-background-image', {
  run(editor, sender, options) {
    const selectedComponent = editor.getSelected();
    
      // Get the wrapper and modify its style properties
      const wrapper = editor.DomComponents.getWrapper();
      const style = wrapper.getStyle();
      style['background-image'] = `url(${options.imageUrl})`;
      style['background-size'] = 'cover';
      style['background-repeat'] = 'no-repeat';
      wrapper.setStyle(style);

      

  }
});
// editor.runCommand('set-background-image', {
//   imageUrl: 'https://fastly.picsum.photos/id/170/200/300.jpg?hmac=8MNcDgapCZN2mHu0jnLxPWkxuwz9rq4TMQfQsYTINCk'
// });

editor.DomComponents.addType("Text", {
  isComponent: (el) => el.tagName === "IFRAME",
  model: {
    defaults: {
      type: "iframe",
      traits: [
        {
          type: "text",
          label: "src",
          name: "src",
        },
      ],
    },
  }, 
});
editor.Panels.getPanel("views").buttons.models.forEach((button) => {
  button.on("click", () => {
    console.warn("button", button);
    if (button.id !== "section") {
      if (sectionPanel != null) {
        sectionPanel.innerHTML = "";
      }
    }
  });
});

const desiredModels = editor.StyleManager.getSectors().models.filter(
  (value, idx, self) => {
    return (
      idx ===
      self.findIndex((t) => t.id === value.id && t.name === value.name)
    );
  }
);

editor.StyleManager.getSectors().models = desiredModels;
}