export const SendPadForm = (editor, opts = {}) => {
  editor.DomComponents.addType("sendpad-form", {
    isComponent: (el) => el.tagName === "DIV" && el.classList.contains("sendpad-form"),
    model: {
      defaults: {
        traits: [
          {
            type: "select", // Type of the trait
            name: "formSendPad", // (required) The name of the attribute/property to use on component
            label: "SendPad form", // The label you will see in Settings
            options: opts.forms,
            changeProp: 1,
          },
        ],
      },
      style: {
        width: "100%",
        size: "10",
      },
      init() {
        this.on("change:formSendPad", this.updateForm);
      },
      updateForm() {
        //console.log("updateForm",this.get("formSendPad"));
        this.components(this.get("formSendPad"));
      },
      /*
      updated(property, value, prevValue) {
        if (property === "formSendPad") {
          const el = this.view.el; // target your div
          el.innerHTML = value;
        }
      },*/
    },
  });

  editor.BlockManager.add("SendPadForm", {
    tagName: "sendpad-form",
    category: "Forms",
    label: "SendPad Froms",
    view: ' <div class="gjs-block-forms" data-gjs-type="SendPadForm" data-gjs-title="SendPad Form"></div>',
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" xmlns:v="https://vecta.io/nano"><g fill="#b2b9c3"><use xlink:href="#B"/><use xlink:href="#B" x="-4.99" y="4.99"/><path d="M5.626 5.627l4.989-4.99a.9.9 0 0 1 1.273 0l4.989 4.989a.9.9 0 0 1 0 1.273l-4.989 4.989a.9.9 0 0 1-1.273 0L5.626 6.899a.9.9 0 0 1 0-1.273zm5.626-3.08L7.535 6.263l3.717 3.717 3.717-3.717zm.001 19.585H1.274a.9.9 0 0 1-.636-1.536l4.99-4.99a.9.9 0 0 1 1.273 0l4.99 4.99a.9.9 0 0 1-.636 1.536zm-7.806-1.8h5.634l-2.817-2.817z"/><path d="M1.273.373h9.979a.9.9 0 0 1 .636 1.536l-4.99 4.99a.9.9 0 0 1-1.273 0l-4.99-4.99A.9.9 0 0 1 1.271.373zm7.806 1.8H3.445L6.262 4.99z"/><path d="M.637 10.616l4.989-4.989a.9.9 0 0 1 1.273 0l4.989 4.989a.9.9 0 0 1 0 1.273l-4.989 4.989a.9.9 0 0 1-1.273 0L.637 11.888a.9.9 0 0 1 0-1.273zm5.626-3.08l-3.717 3.717 3.717 3.717 3.717-3.717z"/></g><defs ><path id="B" d="M10.616 10.616l4.989-4.989a.9.9 0 0 1 1.273 0l4.989 4.989a.9.9 0 0 1 0 1.273l-4.989 4.989a.9.9 0 0 1-1.273 0l-4.989-4.989a.9.9 0 0 1 0-1.273zm5.626-3.08l-3.717 3.717 3.717 3.717 3.717-3.717z"/></defs></svg>
    `,
    content: `<div class="sendpad-form">${opts.forms.map((f) => f.value)[0]}</div>`,
  });
};
