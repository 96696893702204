import type { Component, Plugin } from "grapesjs";
import type tuiImageEditor from "tui-image-editor";
//import server from "./api/server";
import imageCompression from "browser-image-compression";
import toast from "../../utils/toast";
import client from "ApiClient";
import moment from "moment";

type ImageEditor = tuiImageEditor.ImageEditor;
type IOptions = tuiImageEditor.IOptions;
type Constructor<K> = { new (...any: any): K };

export type PluginOptions = {
  /**
   * TOAST UI's configurations
   * https://nhn.github.io/tui.image-editor/latest/ImageEditor
   */
  config?: IOptions;

  /**
   * Pass the editor constructor.
   * By default, the `tui.ImageEditor` will be used.
   */
  constructor?: any;

  /**
   * Label for the image editor (used in the modal)
   * @default 'Image Editor'
   */
  labelImageEditor?: string;

  /**
   * Label used on the apply button
   * @default 'Apply'
   */
  labelApply?: string;

  /**
   * Default editor height
   * @default '650px'
   */
  height?: string;

  /**
   * Default editor width
   * @default '100%'
   */
  width?: string;

  /**
   * Id to use to create the image editor command
   * @default 'tui-image-editor'
   */
  commandId?: string;

  /**
   * Icon used in the image component toolbar. Pass an empty string to avoid adding the icon.
   */
  toolbarIcon?: string;

  /**
   * Hide the default editor header
   * @default true
   */
  hideHeader?: boolean;

  /**
   * By default, GrapesJS takes the modified image, adds it to the Asset Manager and update the target.
   * If you need some custom logic you can use this custom 'onApply' function.
   * @example
   * onApply: (imageEditor, imageModel) => {
   *    const dataUrl = imageEditor.toDataURL();
   *    editor.AssetManager.add({ src: dataUrl }); // Add it to Assets
   *    imageModel.set('src', dataUrl); // Update the image component
   * }
   */
  onApply?: ((imageEditor: ImageEditor, imageModel: Component) => void) | null;

  /**
   * If no custom `onApply` is passed and this option is `true`, the result image will be added to assets
   * @default true
   */
  addToAssets?: boolean;

  /**
   * If no custom `onApply` is passed, on confirm, the edited image, will be passed to the
   * AssetManager's uploader and the result (eg. instead of having the dataURL you'll have the URL)
   * will be passed to the default `onApply` process (update target, etc.)
   */
  upload?: boolean;

  /**
   * The apply button (HTMLElement) will be passed as an argument to this function, once created.
   * This will allow you a higher customization.
   */
  onApplyButton?: (btn: HTMLElement) => void;

  /**
   * Scripts to load dynamically in case no TOAST UI editor instance was found
   */
  script?: string[];

  /**
   * In case the script is loaded this style will be loaded too
   */
  style?: string[];
};

const plugin: Plugin<PluginOptions> = (editor, options = {}) => {
  const opts: Required<PluginOptions> = {
    config: {
      includeUI: {
        initMenu: "filter",
      },
    },
    constructor: "",
    labelImageEditor: "Image Editor",
    labelApply: "Apply",
    height: "650px",
    width: "100%",
    commandId: "tui-image-editor",
    toolbarIcon: `<svg viewBox="0 0 24 24">
                    <path d="M20.71 7.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25z">
                    </path>
                  </svg>`,
    hideHeader: true,
    addToAssets: true,
    upload: false,
    onApplyButton: (btn) => {
      // console.log("btn", btn);
    },
    onApply: null,
    script: [
      "https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js",
      "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js",
      "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js",
    ],
    style: [
      "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css",
      "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css",
    ],
    ...options,
  };

  const { script, style, height, width, hideHeader, onApply, upload, addToAssets, commandId } =
    opts;
  const hasWindow = typeof window !== "undefined";

  const getConstructor = (): Constructor<ImageEditor> => {
    return opts.constructor || (hasWindow && (window as any).tui?.ImageEditor);
  };

  let constr = getConstructor();

  // Dynamic loading of the image editor scripts and styles
  if (!constr && script?.length && hasWindow) {
    const { head } = document;
    const scripts = Array.isArray(script) ? [...script] : [script];
    const styles = (Array.isArray(style) ? [...style] : [style]) as string[];
    const appendStyle = (styles: string[]) => {
      if (styles.length) {
        const link = document.createElement("link");
        link.href = styles.shift()!;
        link.rel = "stylesheet";
        head.appendChild(link);
        appendStyle(styles);
      }
    };
    const appendScript = (scripts: string[]) => {
      if (scripts.length) {
        const scr = document.createElement("script");
        scr.src = scripts.shift()!;
        scr.onerror = scr.onload = appendScript.bind(null, scripts);
        head.appendChild(scr);
      } else {
        constr = getConstructor();
      }
    };
    appendStyle(styles);
    appendScript(scripts);
  }

  // Update image component toolbar
  if (opts.toolbarIcon) {
    editor.Components.addType("image", {
      extendFn: ["initToolbar"],
      model: {
        initToolbar() {
          const tb = this.get("toolbar");
          const tbExists = tb?.some((item) => item.command === commandId);

          if (!tbExists) {
            tb?.unshift({
              command: commandId,
              label: opts.toolbarIcon,
            });
            this.set("toolbar", tb);
          }
        },
      },
    });
  }

  // Add the image editor command
  const errorOpts = { level: "error", ns: commandId };

  let loader: HTMLDivElement | null = null; // Declare loader variable
  let error: HTMLDivElement | null = null; // Declare error variable

  editor.Commands.add(commandId, {
    imageEditor: null as tuiImageEditor | null,

    run(ed, s, options: { target?: Component } = {}) {
      if (!constr) {
        ed.log("TOAST UI Image editor not found", errorOpts);
        return ed.stopCommand(commandId);
      }

      const target = (options.target || ed.getSelected()) as Component;

      if (!target) {
        ed.log("Target not available", errorOpts);
        return ed.stopCommand(commandId);
      }

      const content = this.createContent();
      const title = opts.labelImageEditor;
      const btn = content.children[1] as HTMLElement;
      btn.style.backgroundColor = "#008000";
      loader = document.createElement("div");
      loader.style.width = "100%";
      loader.style.height = "100%";
      loader.style.display = "none"; // Initially hide the loader
      loader.style.alignItems = "center";
      loader.style.justifyContent = "center";
      loader.style.alignSelf = "center";
      loader.style.position = "absolute";
      loader.style.top = "0";
      loader.style.bottom = "0";
      loader.style.left = "0";
      loader.style.right = "0";
      loader.style.background = "#eeeeeecf";
      loader.style.zIndex = "111111";

      const componentLoader = document.createElement("div");
      componentLoader.style.width = "100%";
      componentLoader.style.height = "100%";
      componentLoader.style.display = "flex";
      componentLoader.style.alignItems = "center";
      componentLoader.style.justifyContent = "center";

      const img = document.createElement("img");
      img.style.maxWidth = "100%";

      // Set the image URL dynamically (replace 'YOUR_IMAGE_URL' with the actual URL)
      img.src = "https://thumbs.pixapage.com/images/preview.gif";
      //"https://firebasestorage.googleapis.com/v0/b/floatfunnels.appspot.com/o/preview.gif?alt=media&token=3548e241-0c81-43e0-bd4e-d221fa0df4f7";
      img.alt = "Loading...";

      componentLoader.appendChild(img);
      loader.appendChild(componentLoader);
      btn.parentNode?.appendChild(loader);

      error = document.createElement("div");
      error.style.width = "100%";
      error.style.height = "100%";
      error.style.display = "none"; // Initially hide the loader
      error.style.alignItems = "center";
      error.style.justifyContent = "center";
      error.style.alignSelf = "center";
      error.style.position = "absolute";
      error.style.top = "0";
      error.style.bottom = "0";
      error.style.left = "0";
      error.style.right = "0";
      error.style.background = "#eeeeeecf";
      error.style.zIndex = "111111";

      const componentError = document.createElement("div");
      componentError.style.width = "50%";
      componentError.style.height = "100%";
      componentError.style.float = "left";
      //componentError.style.display = "flex";
      componentError.style.alignItems = "center";
      componentError.style.justifyContent = "center";

      const imgError = document.createElement("img");
      imgError.style.maxWidth = "100%";
      imgError.src = "https://thumbs.pixapage.com/images/error-icon.png";
      imgError.alt = "error...";

      const errorText = document.createTextNode(
        "Error loading image from server. Please try again in a few seconds."
      );
      componentError.appendChild(errorText);
      componentError.style.color = "red";
      componentError.appendChild(imgError);
      error.appendChild(componentError);
      btn.parentNode?.appendChild(error);

      ed.Modal.open({ title, content }).onceClose(() => ed.stopCommand(commandId));

      const editorConfig = this.getEditorConfig(target.get("src"));
      //console.log("content.children[0]", content.children[0]);
      this.imageEditor = new constr(content.children[0], editorConfig);
      ed.getModel().setEditing(true);

      btn.onclick = () => this.applyChanges(target);
      opts.onApplyButton(btn);
    },
    stop(ed) {
      (this.imageEditor as tuiImageEditor)?.destroy();
      ed.getModel().setEditing(false);
    },

    getEditorConfig(path: string): IOptions {
      const config: IOptions = { ...opts.config };

      if (!config.includeUI) config.includeUI = {};

      config.includeUI = {
        theme: {},
        ...config.includeUI,
        loadImage: { path, name: path.split("/").pop() },
        uiSize: { height, width },
      };
      if (hideHeader) {
        // @ts-ignore
        config.includeUI.theme["header.display"] = "none";
      }

      return config;
    },

    createContent(): HTMLDivElement {
      const content = document.createElement("div");
      content.style.position = "relative";
      content.innerHTML = `
        <div></div>
        <button class="tui-image-editor__apply-btn" style="
          position: absolute;
          top: 0; right: 0;
          margin: 10px;
          background-color: #fff;
          font-size: 1rem;
          border-radius: 3px;
          border: none;
          padding: 10px 20px;
          cursor: pointer
        ">
          ${opts.labelApply}
        </button>
      `;

      return content;
    },

    applyChanges(target: Component) {
      const ied = this.imageEditor as ImageEditor;
      if (onApply) {
        onApply(ied, target);
      } else {
        if (ied.getDrawingMode() === "CROPPER") {
          ied.crop(ied.getCropzoneRect()).then(() => {
            this.showLoader();
            this.uploadImage(ied, target);
          });
        } else {
          this.showLoader();
          this.uploadImage(ied, target);
        }
      }
    },
    showLoader() {
      if (loader) {
        loader.style.display = "flex";
      }
    },
    async uploadImage(imageEditor: ImageEditor, target: Component) {
      const am = editor.Assets;
      const file = this.dataUrlToFile(imageEditor.toDataURL(), target);
      //  console.log("file", file);
      try {
        const options = {
          method: "POST",
          url: "/grapeai/uploadImageBin",
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        /*
        const maxSizeWithoutCompressionMB = 1.5;

        let compressedFile: Blob | undefined;

        if (file.size > maxSizeWithoutCompressionMB * 1024 * 1024) {
          // alert('kk')
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,            
          };

          compressedFile = await imageCompression(file, options);
          console.log("compressedFile instanceof Blob", compressedFile instanceof Blob); // true
          // alert(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        }
        console.log("compressedFile", compressedFile);
        console.log("file", file);
*/
        const formData = new FormData();
        formData.append("files", file);
        options.data = formData;

        client
          .request(options)
          .then((response) => {
            addToAssets &&
              am.add({
                src: response.data[0].src,
                name: response.data[0].src.split("/").pop(),
              });

            this.applyToTarget(response.data[0].src, target);

            // console.log("response", response);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        loader.style.display = "none"; // Hide loader after upload
        console.log("ERROR1", e);
      }
    },

    applyToTarget(result: string, target: Component) {
      // Create an image element
      //https://storage.googleapis.com/pixapage-thumbs/images/8/b62dd8551b414bfc87c6cc22a1a5781c1729710699.png
      const imgSrc = result; //`https://storage.googleapis.com/pixapage-thumbs/images/${JSON.parse(localStorage.getItem("account")).user_id}/${result.split("/").pop()}`;
      const img = new Image();
      // Set up an onload event listener
      try {
        img.onload = () => {
          // Once the image is loaded, set the target's src and close the modal
          target.set("src", imgSrc);
          editor.Modal.close();

          loader.style.display = "none"; // Hide loader after upload
        };
        img.alt = result.split("/").pop(); // Set the image alt attribute
        // Set the image source
        img.src = imgSrc; // result + "?t=" + new Date().getTime();

        setTimeout(function () {
          if (!img.complete || !img.naturalWidth) {
            // Image is broken, replace with a placeholder
            error.style.display = "flex";
            loader.style.display = "none"; // Hide loader after upload
            setTimeout(function () {
              error.style.display = "none";
            }, 5000);
          }
        }, 5000);
      } catch (e) {
        loader.style.display = "none"; // Hide loader after upload
        console.log("ERROR", e);
      }
    },
    dataUrlToFile(dataURL: string, target: Component) {
      const data = dataURL.split(",");
      const byteStr = window.atob(data[1]);
      const type = data[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteStr.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteStr.length; i++) {
        ia[i] = byteStr.charCodeAt(i);
      }
      const fileName = (target.get("src") || "").split("/").pop();
      const uniqFileName =
        fileName?.split(".")[0] + moment().unix() + "." + fileName?.split(".")[1];

      return new File([ab], uniqFileName, { type });
      //return new Blob([ab], { type },);
    },
  });
};

export default plugin;
