export const NextPageButton = (editor, opts = {}) => {
  editor.BlockManager.add("nextPageButton", {
    tagName: "nextPageButton",
    category: "Forms",
    label: "Next Page Button",
    media: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/></svg>`,
    content: `<a data-custom-id="next-page-button" href="[NEXT_PAGE]" style="width: 200px;
                                                          justify-content: center;
                                                          align-items: center;
                                                          background-color: #2884e4;
                                                          color: #fff;
                                                          padding: 22px 20px 22px 20px;
                                                          text-align: center;
                                                          font-size: 24px;
                                                          text-decoration: none;
                                                          cursor: pointer;
                                                          border: none;
                                                          outline: none;
                                                          transition: background-color 0.3s;
                                                          max-width: 1140px;
                                                          margin: 12px auto;
                                                          font-family: Arial, Helvetica, sans-serif;
                                                          display: block;" href="[NEXT_PAGE]"
              >
                <span
                  data-gjs-highlightable="true"
                  data-gjs-type="text"
                  draggable="true"
                  class="button-text"
                >
                  Next Page
                </span>
             </a>`,
  });
};
