import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Fragment, useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDInput from "components/MDInput";
import { Chip } from "@material-ui/core";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/Domains/components/DefaultCell";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import parse from "html-react-parser";
import MDTypography from "components/MDTypography";
import ConfirmationAlert from "components/ConfirmationAlert";
import { ReactFlowProvider } from "reactflow";
import { CardHeader } from "reactstrap";
import StatFlow from "./components/statFlow";

/* eslint-disable */
export default function Funnels() {
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [dnsMessage, setDnsMessage] = useState("");
  const [openUnpublish, setOpenUnpublish] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [items, setItems] = useState([]);
  const [stats, setStats] = useState(null);
  const [pathStats, setPathStats] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const account = JSON.parse(localStorage.getItem("account"));
  const options = {
    method: "GET",
    url: "funnels",
  };

  const handleSave = () => {
    const options = {
      method: "POST",
      url: `funnels`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      fun_name: name,
      fun_description: description,
      fun_url: "",
      fun_slug: "",
      fun_status: 3,
      fun_json: "",
    });

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenAdd(false);
        navigate(`/funnels/add?id=${response.fun_id}`);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteItem = () => {
    const options = {
      method: "DELETE",
      url: `funnels?id=${selectedItem.fun_id}`,
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const unpublish = async (funnel) => {
    setIsLoading(true);
    options.method = "PUT";
    options.url = `funnels`;
    funnel.fun_status = 3;
    funnel.fun_url = "";
    options.data = funnel;
    await client
      .request(options)
      .then((response) => {
        setOpenUnpublish(false);
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `funnels`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setItems(response.reverse());
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getStats = async (id) => {
    setStats(null);
    setIsLoading(true);
    options.method = "GET";
    options.url = `funnels/stats?fun_id=${id}`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setStats(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const verifyDns = async () => {
    const options = {
      method: "GET",
      url: "dashboard/DNSCheck",
    };

    await client
      .request(options)
      .then((response) => {
        setDnsMessage(response);
        /*
      if (JSON.stringify(response) !== "") setDnsMessage("FAILED DNS VERIFICATION");
      else setDnsMessage("ERROR DNS FAILURE");
      */
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    verifyDns();
    getData();
  }, []);

  const dataTableData = {
    columns: [
      {
        Header: "Actions",
        accessor: "fun_id",
        width: 5,
        Cell: ({ value, index }) => (
          <Fragment>
            <Tooltip title="Edit" placement="bottom">
              <IconButton
                onClick={() => {
                  navigate(`/funnels/add?id=${value}`);
                }}
              >
                <EditIcon color="info" fontSize="small" />
              </IconButton>
            </Tooltip>

            {items.find((item) => item.fun_id === value).fun_status !== 3 && (
              <Tooltip title="Stats" placement="bottom">
                <IconButton
                  onClick={() => {
                    const funn = items.find((item) => item.fun_id === value);
                    setSelectedItem(funn);
                    getStats(value);
                    // getPathStats(value);
                    setShowStats(true);
                  }}
                >
                  <Icon color="info" fontSize="small">
                    query_stats
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            {items.find((item) => item.fun_id === value).fun_status !== 3 && (
              <Tooltip title="Unpublish" placement="bottom">
                <IconButton
                  onClick={() => {
                    setSelectedItem(items.find((item) => item.fun_id === value));
                    setOpenUnpublish(true);
                  }}
                >
                  <Icon color="warning" fontSize="small">
                    unpublished
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                onClick={() => {
                  setSelectedItem(items.find((item) => item.fun_id === value));
                  setOpenAlert(true);
                }}
              >
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
          </Fragment>
        ),
      },
      {
        Header: "status",
        accessor: "fun_status",
        Cell: ({ value }) => {
          let status;
          switch (value) {
            case 0:
              return (
                <Chip
                  label="Published"
                  color="success"
                  size="small"
                  style={{ backgroundColor: "#4caf50", color: "#fff", width: "100px" }}
                />
              );
            case 3:
              return (
                <Chip
                  label="Draft"
                  color="info"
                  size="small"
                  style={{ backgroundColor: "#0088F5", color: "#fff", width: "100px" }}
                />
              );
          }
        },
      },
      {
        Header: "Name",
        accessor: "fun_name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "fun_description",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "URL",
        accessor: "fun_url",
        Cell: ({ value }) => (
          <DefaultCell
            value={
              <a href={value} target="_blank">
                {value}
              </a>
            }
          />
        ),
      },
      {
        Header: "Total visits",
        accessor: "totalVisits",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Total conversions",
        accessor: "totalConversions",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Conversion rate",
        accessor: "conversionRate",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Conversion percentage",
        accessor: "conversionPercentage",
        Cell: ({ value }) => <DefaultCell value={`${value}%`} />,
      },
      {
        Header: "Creation date",
        accessor: "fun_creationDate",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
      },
    ],

    rows: items,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={() => deleteItem()}
        yesText="Yes"
        title={`Are you sure you want to delete your funnel (${selectedItem?.fun_name}) from ${process.env.REACT_APP_SITE_TITLE}?`}
      />
      <ConfirmationAlert
        openModal={openUnpublish}
        onClose={() => setOpenUnpublish(false)}
        onYes={() => unpublish(selectedItem)}
        yesText="Yes"
        title={`Are you sure you want to unpublish your funnel (${selectedItem?.fun_name}) from "${selectedItem?.fun_url}" ?`}
      />
      <Dialog open={openAdd}>
        <DialogContent>
          <MDBox>
            <MDInput
              style={{ padding: 10 }}
              type="text"
              label="Funnel name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </MDBox>
          <MDBox>
            <MDInput
              style={{ padding: 10 }}
              type="text"
              label="Description"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </MDBox>
        </DialogContent>
        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setOpenAdd(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </MDButton>
          <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
            Save
          </MDButton>
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={showStats} maxWidth="xl" fullScreen>
        {selectedItem && (
          <MDBox p={2} pl={5} style={{ backgroundColor: "#ccc" }}>
            <MDTypography variant="h3" textTransform="capitalize">
              {selectedItem.fun_name}
            </MDTypography>
            <MDTypography
              component={Link}
              to={selectedItem.fun_url}
              target="_blank"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
              style={{ marginLeft: 10 }}
            >
              {selectedItem.fun_url}
            </MDTypography>
          </MDBox>
        )}
        <DialogContent>
          {stats ? (
            <MDBox textAlign="center">
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="weekend"
                      title="Total convertions"
                      count={stats.totalConversions}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="info"
                      icon="weekend"
                      title="Convertion rate"
                      count={stats.conversionRate}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      color="info"
                      icon="weekend"
                      title="Convertion percentage"
                      count={stats.conversionPercentage + "%"}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <Card style={{ height: "55vh", backgroundColor: "white" }}>
                    <CardHeader>
                      <MDTypography variant="h4">Stats flow</MDTypography>
                    </CardHeader>
                    <ReactFlowProvider>
                      <StatFlow
                        pages={stats.page_stats}
                        nodes={JSON.parse(selectedItem?.fun_json).nodes}
                        edges={JSON.parse(selectedItem?.fun_json).edges}
                      />
                    </ReactFlowProvider>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          ) : (
            <MDBox textAlign="center">
              <CircularProgress
                size={30}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            </MDBox>
          )}
        </DialogContent>
        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setShowStats(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      {dnsMessage !== "" && dnsMessage !== undefined && (
        <Grid item xs={12}>
          <MDBox
            mx={2}
            mt={1}
            py={3}
            px={2}
            bgColor="badgeColors.error"
            borderRadius="lg"
            variant="gradient"
          >
            <MDTypography variant="h4">Domain Not Connected</MDTypography>
            <MDBox p={2} mx={2}>
              {parse(dnsMessage)}
            </MDBox>
          </MDBox>
        </Grid>
      )}
      <MDBox my={3}>
        {localStorage.getItem("profileCompleted") == "true" ? (
          <Card style={{ padding: 20, marginBottom: 5 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
              <MDButton variant="gradient" color="primary" onClick={() => setOpenAdd(true)}>
                new funnel
              </MDButton>
            </MDBox>
          </Card>
        ) : (
          <Card style={{ padding: 20, marginBottom: 5 }}>
            <MDTypography variant="h6" color="text">
              You need to complete your profile in order to create a funnel on{" "}
              {process.env.REACT_APP_SITE_TITLE}
            </MDTypography>
            <MDButton
              variant="gradient"
              color="primary"
              component={Link}
              to="/profile"
              style={{ width: 200 }}
            >
              Go to profile
            </MDButton>
          </Card>
        )}
        <Card>
          <DataTable table={dataTableData} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
