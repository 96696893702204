import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import PageView from "./components/PageView";
import MDTypography from "components/MDTypography";
import moment from "moment";
import MDInput from "components/MDInput";
import ConfirmationAlert from "components/ConfirmationAlert";
import { Card } from "reactstrap";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import PieChart from "examples/Charts/PieChart";

export default function Pages() {
  const [toDeleteItem, setToDeleteItem] = useState({});
  const [openUnpublish, setOpenUnpublish] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dnsMessage, setDnsMessage] = useState("");
  const [searchPage, setSearchPage] = useState("");
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0); // Para controlar el número de la página
  const [hasMore, setHasMore] = useState(true); // Para controlar si hay más datos
  const [openNewPage, setOpenNewPage] = useState(false);
  const [failText, setFailText] = useState("");
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("");
  const [selectedPage, setSelectedPage] = useState(null);
  const [countryData, setCountryData] = useState(null);

  const [lastScrollTop, setLastScrollTop] = useState(0);

  const account = JSON.parse(localStorage.getItem("account"));

  const initialized = useRef(false);
  const gettingData = useRef(false);

  const options = {
    method: "GET",
    url: "pages",
    headers: {
      "content-type": "application/json",
    },
  };

  const getStats = async (id) => {
    options.method = "GET";
    options.url = `dashboard?page_id=${id}`;

    await client
      .request(options)
      .then((response) => {
        setCountryData(response);
      })
      .catch((error) => {
        console.log("ERRORE", error);
      });
  };

  const getData = async () => { 
    if (gettingData.current || !hasMore) return;
    gettingData.current = true;
    setIsLoading(true);
    options.method = "GET";
    options.url = `pages?page=${page}&limit=10&search=${searchPage}`; // Añadir paginación (asegúrate de que la API soporte estos parámetros)
    try {
      await client
        .request(options)
        .then((response) => {
          if (response.length > 0) {
            const pages = response;
            page == 0 ? setItems(pages) : setItems((prevItems) => [...prevItems, ...pages]);
            setPage((prevPage) => prevPage + 1);
          } else {
            setHasMore(false); // Si no hay más datos, dejamos de cargar
          }
          gettingData.current = false;
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  };

  const addNewPage = () => {
    setIsLoading(true);
    setFailText("");
    options.method = "POST";
    options.url = `pages`;

    options.data = JSON.stringify({
      pag_status: 3,
      pag_user_id: 0,
      pag_fun_id: 0,
      pag_url: "",
      pag_html: "<body><div></div></body>",
      pag_css: "",
      pag_json: "",
      pag_name: pageName,
      pag_title: pageName,
      pag_description: "",
      pag_keywords: "",
      pag_version: 1,
      pag_slug: pageName.replace(/\s/g, "-").toLowerCase(),
      pag_favicon: null,
      pag_bgImageURL: null,
      pag_totalVisits: 0,
      pag_ff_account_id: 0,
      pag_ff_page_id: 0,
      pag_description: "",
      pag_keywords: "",
      pag_googleConversionID: "",
      pag_googleConversionLabel: "",
      pag_googleMetaTag: "",
      pag_googleAnalyticsID: "",
      pag_MSVerificationTag: "",
      pag_MSUETTagID: "",
      pag_customHead: "",
      pag_customBody: "",
      pag_customFooter: "",
    });

    client
      .request(options)
      .then((response) => {
       // console.log(response);
        if (response) {
          navigate(`/pages/add?id=${response.pag_id}`);
          window.location.reload();
        } else {
          setFailText("Slug already exists");
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        // setIsSaving(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const verifyDns = async () => {
    const options = {
      method: "GET",
      url: "dashboard/DNSCheck",
    };

    await client
      .request(options)
      .then((response) => {
        setDnsMessage(response);
        /*
      if (JSON.stringify(response) !== "") setDnsMessage("FAILED DNS VERIFICATION");
      else setDnsMessage("ERROR DNS FAILURE");
      */
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const handleScroll = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop && window.innerHeight + window.scrollY >= document.body.offsetHeight * page) {
      getData();
    }
    setLastScrollTop(st <= 0 ? 0 : st);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      verifyDns();
      getData();
    }
  }, []);

  const handleDeletePage = async () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `pages?id=${toDeleteItem.id}`;
    await client
      .request(options)
      .then((response) => {
        navigate("/pages");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const unpublish = async (page) => {
    options.method = "PUT";
    options.url = `pages`;
    page.pag_status = 3;
    page.pag_url = "";
    options.data = page;
    await client
      .request(options)
      .then((response) => {
        setOpenUnpublish(false);
        navigate("/pages");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleGetUnpublish = async (id) => {
    setIsLoading(true);
    setPage(0);
    setItems([]);
    options.method = "GET";
    options.url = `pages/byid?page_id=${id}`;
    try {
      const response = await client.request(options);
      unpublish(response);
    } catch (error) {
      console.log(error);
    }
  };

  const search = () => {
    setPage(0);
    setHasMore(true);
    setItems([]);
    setIsSearching(true);
  };

  useEffect(() => {
    if (isSearching) {
      //  console.log("INTO USE EFFECT SEARCH");
      getData();
    }
  }, [isSearching]);

  const actionButtons = (id, version, status, pageTitle) => {
    return (
      <>
        <Tooltip title="Edit" placement="bottom">
          <MDTypography variant="body1" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
            <Icon
              onClick={() => {
                navigate(`/pages/add?id=${id}`);
                window.location.reload();
              }}
              fontSize="small"
              color="info"
            >
              edit
            </Icon>
          </MDTypography>
        </Tooltip>
        {status == 0 && (
          <Tooltip title="Stats" placement="bottom">
            <MDTypography variant="body1" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
              <Icon
                onClick={() => {
                  setSelectedPage(items.find((item) => item.pag_id === id));
                  getStats(id); // navigate(`/dashboard/${id}/${pageTitle}`);
                  setShowStats(true);
                }}
                fontSize="small"
                color="info"
              >
                query_stats
              </Icon>
            </MDTypography>
          </Tooltip>
        )}
        {status == 0 && (
          <Tooltip title="Unpublish" placement="bottom">
            <MDTypography variant="body1" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
              <Icon
                onClick={() => {
                  setToDeleteItem({
                    id: id,
                    page: pageTitle, // Pasar el dom_domain aquí
                  });
                  setOpenUnpublish(true);
                }}
                color="warning"
                fontSize="small"
              >
                unpublished
              </Icon>
            </MDTypography>
          </Tooltip>
        )}

        <Tooltip title="Delete" placement="bottom">
          <MDTypography
            variant="body1"
            color="error"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 3 }}
          >
            <Icon
              onClick={() => {
                setToDeleteItem({
                  id: id,
                  page: pageTitle, // Pasar el dom_domain aquí
                });
                setOpenAlert(true);
              }}
              fontSize="small"
              color="error"
            >
              delete
            </Icon>
          </MDTypography>
        </Tooltip>
      </>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={showStats}
        maxWidth="xl"
        fullScreen
        onClose={() => {
          setCountryData(null);
          setSelectedPage(null);
        }}
      >
        {selectedPage && (
          <MDBox p={2} pl={5} style={{ backgroundColor: "#ccc" }}>
            <MDTypography variant="h3" textTransform="capitalize">
              {selectedPage.pag_name}
            </MDTypography>
            <MDTypography
              component={Link}
              to={selectedPage.pag_url}
              target="_blank"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
              style={{ marginLeft: 10 }}
            >
              {selectedPage.pag_url}
            </MDTypography>
          </MDBox>
        )}
        <DialogContent>
          {countryData ? (
            <MDBox py={3}>
              <MDBox mt={1.5}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="success"
                        icon="weekend"
                        title="Total visits today"
                        count={countryData.total_last_24}
                        percentage={{
                          color:
                            countryData.total_last_48 - countryData.total_last_24 > 0
                              ? "error"
                              : "success",
                          amount: Math.abs(countryData.total_last_48 - countryData.total_last_24),
                          label:
                            countryData.total_last_48 - countryData.total_last_24 > 0
                              ? " less than yesterday"
                              : " more than yesterday",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="info"
                        icon="weekend"
                        title="Total visits yesterday"
                        count={countryData.total_last_48}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="secondary"
                        icon="weekend"
                        title="Total visits this month"
                        count={countryData.total_last_30_days}
                        percentage={{
                          color:
                            countryData.total_last_60_days - countryData.total_last_30_days > 0
                              ? "error"
                              : "success",
                          amount: Math.abs(
                            countryData.total_last_60_days - countryData.total_last_30_days
                          ),
                          label:
                            countryData.total_last_60_days - countryData.total_last_30_days > 0
                              ? " less than last month"
                              : " more than last month",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="dark"
                        icon="weekend"
                        title="Total visits last month"
                        count={countryData.total_last_60_days}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {countryData.map_markers.length > 0 && (
                <>
                  <Grid container mt={2}>
                    <SalesByCountry salesTableData={countryData} />
                  </Grid>
                  <MDBox mt={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <MDBox mb={3}>
                          <DefaultLineChart
                            title="Daily visits"
                            chart={countryData.traffic_by_day}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                          <PieChart title="Traffic by OS" chart={countryData.traffic_by_os_pie} />
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                          <PieChart
                            title="traffic by browser"
                            chart={countryData.traffic_by_browser_pie}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                          <PieChart
                            title="Traffic by language"
                            chart={countryData.traffic_by_language_pie}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                          <PieChart
                            title="Traffic by page"
                            chart={countryData.traffic_by_page_pie}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                          <PieChart
                            title="Traffic by screen resolution"
                            chart={countryData.traffic_by_screenres_pie}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <MDBox mb={3}>
                          <PieChart
                            title="Traffic by source"
                            chart={countryData.traffic_by_source_pie}
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </>
              )}
            </MDBox>
          ) : (
            <MDBox textAlign="center">
              <CircularProgress
                size={30}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            </MDBox>
          )}
        </DialogContent>
        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setShowStats(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openNewPage}>
        <MDBox
          variant="gradient"
          bgColor="secondary"
          coloredShadow="secondary"
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white">
            Set page name
          </MDTypography>
        </MDBox>
        <DialogContent>
          <MDInput
            autofocus
            type={"text"}
            label="Page Name"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            fullWidth
            style={{ marginBottom: 20 }}
          />
          {failText != "" && (
            <MDTypography variant="body1" color="error" textAlign="center">
              {failText}
            </MDTypography>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => {
              setOpenNewPage(false);
            }}
            variant="outlined"
            color="secondary"
            disabled={isLoading}
          >
            Cancel
          </MDButton>
          <MDButton onClick={addNewPage} variant="contained" color="success" disabled={isLoading}>
            Create
          </MDButton>
          {isLoading && (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </DialogActions>
      </Dialog>
      {dnsMessage !== "" && dnsMessage !== undefined && (
        <Grid item xs={12}>
          <MDBox
            mx={2}
            mt={1}
            py={3}
            px={2}
            bgColor="badgeColors.error"
            borderRadius="lg"
            variant="gradient"
          >
            <MDTypography variant="h4">Domain Not Connected</MDTypography>
            <MDBox p={2} mx={2}>
              {parse(dnsMessage)}
            </MDBox>
          </MDBox>
        </Grid>
      )}
      <MDBox my={3}>
        {localStorage.getItem("profileCompleted") == "true" ? (
          <Card style={{ padding: 20, marginBottom: 50 }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
              <MDButton variant="gradient" color="primary" onClick={() => setOpenNewPage(true)}>
                new page
              </MDButton>
              <MDInput
                style={{ width: 400 }}
                type="text"
                label="Search"
                value={searchPage}
                onChange={(e) => setSearchPage(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MDBox display="flex" flexDirection="row">
                        <IconButton
                          onClick={() => {
                            search();
                          }}
                          sx={{ fontWeight: "bold" }}
                          color="info"
                          aria-label="prompt"
                        >
                          <Tooltip id="button-report" title="Search">
                            <Icon fontSize="small">search</Icon>
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setSearchPage("");
                            search();
                          }}
                          sx={{ fontWeight: "bold" }}
                          color="error"
                          aria-label="prompt"
                        >
                          <Tooltip id="button-report" title="Clear">
                            <Icon fontSize="small">close</Icon>
                          </Tooltip>
                        </IconButton>
                      </MDBox>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
          </Card>
        ) : (
          <Card style={{ padding: 20, marginBottom: 30 }}>
            <MDTypography variant="h6" color="text">
              You need to complete your profile in order to create a page on{" "}
              {process.env.REACT_APP_SITE_TITLE}
            </MDTypography>
            <MDButton
              variant="gradient"
              color="primary"
              component={Link}
              to="/profile"
              style={{ width: 200 }}
            >
              Go to profile
            </MDButton>
          </Card>
        )}
        {isLoading && (
          <MDBox textAlign="center">
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          </MDBox>
        )}

        {!isSearching && items.length == 0 && !isLoading && !hasMore ? (
          <MDBox textAlign="center">
            <MDTypography>No pages found</MDTypography>
          </MDBox>
        ) : (
          <>
            <Grid container spacing={10}>
              {items.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} xl={4} key={item.pag_id}>
                  <PageView
                    key={item.pag_id}
                    title={item.pag_name}
                    date={moment(item.pag_creationDate).format("DD/MM/YYYY")}
                    url={item.pag_url}
                    image={item.pag_thumbnailURL}
                    action={actionButtons(
                      item.pag_id,
                      item.pag_version,
                      item.pag_status,
                      item.pag_name
                    )}
                    status={item.pag_status}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </MDBox>

      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={() => handleDeletePage()}
        yesText="Yes"
        title={`Are you sure you want to delete your page (${toDeleteItem.page}) from ${process.env.REACT_APP_SITE_TITLE}?`}
      />
      <ConfirmationAlert
        openModal={openUnpublish}
        onClose={() => setOpenUnpublish(false)}
        onYes={() => handleGetUnpublish(toDeleteItem.id)}
        yesText="Yes"
        title={`Are you sure you want to unpublish your page (${toDeleteItem.page}) from ${process.env.REACT_APP_SITE_TITLE}?`}
      />
    </DashboardLayout>
  );
}
