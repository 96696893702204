import { Chip, Divider, Icon, withStyles, Tooltip, Menu } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function ItemNode(node) {
  /*
    public const string FUNNELITEM_SPLITTEST = "FUNNELITEM_SPLITTEST";
    public const string FUNNELITEM_PAGE = "FUNNELITEM_PAGE";
    public const string FUNNELITEM_CALLURL = "FUNNELITEM_CALLURL";
    public const string FUNNELITEM_EMAIL = "FUNNELITEM_EMAIL";
    public const string FUNNELITEM_ADDSUBSCRIBER = "FUNNELITEM_ADDSUBSCRIBER";
  */
  // const [splitArr, setSlpitArr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const onClick = (event) => node.data.onClickAdd(event, node);
  const open = Boolean(anchorEl);

  const StyleChip = withStyles({
    root: {
      backgroundColor: "gray",
      borderColor: "white",
      "& .MuiChip-label": {
        fontSize: 8,
        color: "white",
      },
    },
  })(Chip);

  const [triggerMethod] = useState([
    { method: "GET", value: 0 },
    { method: "POST", value: 1 },
    { method: "PUT", value: 2 },
    { method: "DELETE", value: 3 },
  ]);

  const getIcon = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return (
          <Icon fontSize="small" mt={3} rotate={180}>
            call_split
          </Icon>
        );
      case "FUNNELITEM_PAGE":
        return (
          <Icon fontSize="small" mt={3}>
            web
          </Icon>
        );
      case "FUNNELITEM_CALLURL":
        return (
          <Icon fontSize="small" mt={3}>
            webhook
          </Icon>
        );
      case "FUNNELITEM_EMAIL":
        return (
          <Icon fontSize="small" mt={3}>
            mail
          </Icon>
        );
      case "FUNNELITEM_ADDSUBSCRIBER":
        return (
          <Icon fontSize="small" mt={3}>
            person_add
          </Icon>
        );
      default:
        return "error";
    }
  };

  const getAction = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return "Split test";
      case "FUNNELITEM_PAGE":
        return "Page";
      case "FUNNELITEM_CALLURL":
        return "Call a webhook";
      case "FUNNELITEM_EMAIL":
        return "Send a email";
      case "FUNNELITEM_ADDSUBSCRIBER":
        return "Add a subscriber into SendPad";
      default:
        return "error";
    }
  };

  const getData = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        const splitArr = JSON.parse(node.data.fus_additionalData);
        
        return (
          <MDBox>
            {splitArr.map((item, index) => {
              return (
                <MDBox display="flex">
                  <MDTypography variant="caption" fontWeight="medium">
                    Page {index + 1}:
                  </MDTypography>
                  <MDTypography variant="caption">{item.Percentage}%</MDTypography>
                </MDBox>
              );
            })}
          </MDBox>
        );
      case "FUNNELITEM_PAGE":
        return (
          <MDBox>
            {" "}
            <MDTypography variant="caption" fontWeight="medium">
              Name:{" "}
            </MDTypography>
            <MDTypography variant="caption">{node.data.fus_additionalData}</MDTypography>
          </MDBox>
        );
      case "FUNNELITEM_CALLURL":
        return (
          <MDBox>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="medium">
                URL:{" "}
              </MDTypography>
              <MDTypography variant="caption">
                {JSON.parse(node.data.fus_triggerDetails).tri_targetURL}{" "}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="medium">
                METHOD:{" "}
              </MDTypography>
              <MDTypography variant="caption">
                {
                  triggerMethod.filter(
                    (met) => met.value == JSON.parse(node.data.fus_triggerDetails).tri_method
                  )[0].method
                }
              </MDTypography>
            </MDBox>
            <MDBox display="flex">
              <MDTypography variant="caption" fontWeight="medium">
                BODY:{" "}
              </MDTypography>
              <MDTypography variant="caption">
                {JSON.parse(node.data.fus_triggerDetails).tri_body}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
      case "FUNNELITEM_EMAIL":
        return (
          <MDBox display="flex" mt={2}>
            <MDTypography variant="caption">Pfuse for: </MDTypography>
            <MDTypography variant="caption" fontWeight="medium">
              {getIntervalMinutes(node.data.fus_intervalMinutes, node.data.fus_additionalData)}{" "}
              {node.data.fus_additionalData}
            </MDTypography>
          </MDBox>
        );
      case "FUNNELITEM_ADDSUBSCRIBER":
        return (
          <MDBox display="flex" mt={2}>
            <MDTypography variant="caption">Pfuse for: </MDTypography>
            <MDTypography variant="caption" fontWeight="medium">
              {getIntervalMinutes(node.data.fus_intervalMinutes, node.data.fus_additionalData)}{" "}
              {node.data.fus_additionalData}
            </MDTypography>
          </MDBox>
        );
      default:
        return "";
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);   
  };

  const handleClose = (event) => {
  //  console.log(event.currentTarget.value);
    onClick(event.currentTarget.value);
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        height: "80px",
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#96FF9D22",
      }}
    >
      <Handle type="target" position={Position.Top} />

      <MDBox display="flex">
        {getIcon(node.data.fus_type)}
        <MDBox display="flex" style={{ width: "100%" }}>
          <MDTypography variant="caption" fontWeight="medium" ml={1} style={{ width: "100px" }}>
            {getAction(node.data.fus_type)}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="right" style={{ width: "100%" }}>
          {node.selected && node.data.fus_type === "FUNNELITEM_PAGE" && (
            <Tooltip title="edit" placement="top">
              <Icon
                color="action"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onEdit(e, node)}
              >
                edit
              </Icon>
            </Tooltip>
          )}
          {node.selected && (
            <Tooltip title="delete" placement="top">
              <Icon
                color="error"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onDelete(e, node)}
              >
                delete
              </Icon>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      {getData(node.data.fus_type)}

      <Handle type="source" position={Position.Bottom}>
        {node.data.fus_type != "FUNNELITEM_SPLITTEST" && node.selected && (
          <div>
            <Tooltip title="Add a new item" placement="top">
              <Icon color="action" fontSize="small" onClick={handleClick} style={{ marginLeft: -8 }}>
                add_circle
              </Icon>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose} value="0">Add a Page</MenuItem>
              <MenuItem onClick={handleClose} value="1">Add a split test</MenuItem>
              <MenuItem onClick={handleClose} value="2">Call a webhook</MenuItem>
            </Menu>
          </div>
        )}
      </Handle>
    </div>
  );
}

/*

<NodeToolbar isVisible={true} position="right">
        <button>delete</button>
        <button>copy</button>
        <button>expand</button>
      </NodeToolbar>
      
 <FormControl className={classes.formControl}>
        <InputLabel>pick the tags to use</InputLabel>
        {suggestionsTag.length > 0 && (
          <Select
            placeholder="Select the tags to use"
            multiple
            value={tags}
            onChange={handleChangeTags}
            input={<Input />}
            renderValue={(selected) => selected.join(",")}
            MenuProps={MenuProps}
          >
            {suggestionsTag.map((tag) => (
              <MenuItem key={tag.id} value={tag.name}>
                <Checkbox checked={tags.indexOf(tag.name) > -1} />
                <ListItemText primary={tag.name} />
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      */
