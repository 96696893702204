import spinner from "../../../assets/spinner.gif";
import client from "ApiClient";

export const ImageAI = (editor, opts = {}) => {
  const keyCustomCode = "image-ai-plugin__code";
  const typeCustomCode = "image-ai";
  const commandNameCustomCode = "image-ai:open-modal";
  let timedInterval = null;
  let modal = null;
  let prompt = "";

  const dataStyle = {
    Photo: true,
    Painting: false,
    Sketch: false,
    Watercolor: false,
    "3D": false,
  };

  const options = {
    blockCustomCode: {},
    propsCustomCode: {},
    toolbarBtnCustomCode: {},
    placeholderScript: `<div style="pointer-events: none; padding: 10px;">
      <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
        <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
        </svg>
      Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
    </div>`,
    modalTitle: "Get the perfect unique and custom images for your design with AI.",
    codeViewOptions: {},
    buttonLabel: "Generate image",
    commandCustomCode: {},
    ...opts,
  };

  editor.DomComponents.addType("script", {
    view: {
      onRender() {
        // @ts-ignore
        const { model, el } = this;
        const isCC = model.closestType(typeCustomCode);
        isCC && (el.innerHTML = "");
      },
    },
  });

  editor.DomComponents.addType(typeCustomCode, {
    model: {
      defaults: {
        name: "Image AI",
        editable: true,
        components: {
          tagName: "span",
          components: { type: "image", content: "" },
        },
        ...options.propsCustomCode,
      },

      /**
       * Initilize the component
       */
      init() {
        // @ts-ignore
        this.on(`change:${keyCustomCode}`, this.onCustomCodeChange);
        const initialCode = this.get(keyCustomCode);
        prompt = initialCode && initialCode.match(/alt="([^"]*)"/)[1];
        !this.components().length && this.components(initialCode);
        const toolbar = this.get("toolbar");
        const id = "custom-codee";

        // Add the custom code toolbar button if requested and it's not already in
        // @ts-ignore
        if (options && !toolbar.filter((tlb) => tlb.id === id).length) {
          // @ts-ignore
          toolbar.unshift({
            id,
            command: commandNameCustomCode,
            label: `<svg viewBox="0 0 24 24">
              <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
            </svg>`,
            ...options,
          });
        }
      },

      /**
       * Callback to launch on keyCustomCode change
       */
      // @ts-ignore
      onCustomCodeChange() {
        // @ts-ignore
        this.components(this.get(keyCustomCode));
      },
    },

    view: {
      events: {
        dblclick: "onActive",
      },

      init() {
        // @ts-ignore
        this.listenTo(this.model.components(), "add remove reset", this.onComponentsChange);
        // @ts-ignore
        this.onComponentsChange();
      },

      /**
       * Things to do once inner components of custom code are changed
       */
      // @ts-ignore
      onComponentsChange() {
        timedInterval && clearInterval(timedInterval);
        timedInterval = setTimeout(() => {
          // @ts-ignore
          const { model, el } = this;
          const content = model.get(keyCustomCode) || "";
          let droppable = true;

          // Avoid rendering codes with scripts
          // if (content.indexOf('<script') >= 0 && opts.placeholderScript) {
          //   el.innerHTML = opts.placeholderScript;
          //   droppable = false;
          // }

          model.set({ droppable });
        }, 0);
      },

      onActive() {
        // @ts-ignore
        const { model, em } = this;
        em.get("Commands").run(commandNameCustomCode, { target: model });
      },
    },
  });

  //LOAD BLOCKS
  editor.BlockManager.add(typeCustomCode, {
    label: "Smart Image",
    media: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
    `,
    category: process.env.REACT_APP_SITE_TITLE + " AI",
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...options,
  });

  //COMMANDS

  const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
      target.appendChild(content);
    } else if (content) {
      target.insertAdjacentHTML("beforeend", content);
    }
  };

  // Add the custom code command
  editor.Commands.add(commandNameCustomCode, {
    keyCustomCode,

    run(editor, s, opts = {}) {
      const target = options.target || editor.getSelected();
      // @ts-ignore
      this.target = target;

      if (target?.get("editable")) {
        // @ts-ignore
        this.showCustomCode(target, opts);
      }
    },

    stop(editor) {
      editor.Modal.close();
    },

    /**
     * Method which tells how to show the custom code
     * @param  {Component} target
     */
    showCustomCode(target, options) {
      const title = "Get the perfect unique and custom images for your design with AI.";
      const code = target.get(keyCustomCode) || "";
      if (code) prompt = code && code.match(/alt="([^"]*)"/)[1];

      // @ts-ignore
      const content = this.getContent();
      //get the alt attr from image on string

      modal = editor.Modal.open({ title, content });
      modal.onceClose(() => editor.stopCommand(commandNameCustomCode));
      // @ts-ignore
      // this.getCodeViewer().setContent(code);
    },

    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {},

    getContentText() {
      const content = document.createElement("div");

      var closeSpan = document.createElement("span");
      closeSpan.textContent = "Write your prompt";
      content.appendChild(closeSpan);

      const txt = document.createElement("textarea");
      txt.id = "txtPrompt";
      txt.style.padding = "5px";
      txt.style.width = "100%";
      txt.style.height = "100px";
      txt.style.color = "#000";
      txt.placeholder = "A sunny beach with palm trees";
      if (prompt) txt.value = prompt;
      content.appendChild(txt);

      let label = document.createElement("label");
      label.innerText = "Select the style";
      label.style.width = "100%";
      content.appendChild(label);

      for (let key in dataStyle) {
        let label = document.createElement("label");
        label.innerText = key;
        label.style.width = "150px";
        label.style.margin = "10px";

        let input = document.createElement("input");
        input.type = "radio";
        input.name = "style";
        key === "Photo" && (input.checked = true);
        input.style.margin = "5px";
        input.addEventListener("change", () => {
          Object.keys(dataStyle).forEach((key) => {
            dataStyle[key] = false;
          });
          dataStyle[key] = true;
        });

        label.appendChild(input);
        const img = document.createElement("img");
        switch (key) {
          case "Photo":
            img.src = "https://thumbs.pixapage.com/images/42e743149cb149dbaeb4903504021001.png";
            break;
          case "Painting":
            img.src = "https://thumbs.pixapage.com/images/fdf3144394374de88e1fa7ad9e062828.png";
            break;
          case "Sketch":
            img.src = "https://thumbs.pixapage.com/images/9aa841d8718547dbbb1dbae69b75c578.png";
            break;
          case "Watercolor":
            img.src = "https://thumbs.pixapage.com/images/8162e6ba21224b718c16921ea5e219d5.png";
            break;
          case "3D":
            img.src = "https://thumbs.pixapage.com/images/723bed00a55f460fa61c6e2065050c3b.png";
            break;
        }
        img.alt = key + " style";
        img.id = key + "sstyle";
        img.height = 128;
        img.width = 128;
        img.style.border = "2px solid white";
        img.style.borderRadius = "5px";
        img.style.borderColor = "#FFF";
        label.appendChild(img);

        content.appendChild(label);
      }

      return content;
    },

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {},

    /**
     * Get all the content for the custom code
     * @return {HTMLElement}
     */
    getContent() {
      // @ts-ignore
      const codeViewer = this.getCodeViewer();
      const content = document.createElement("div");
      const pfx = editor.getConfig("stylePrefix");
      content.className = `${pfx}custom-code`;
      // @ts-ignore
      appendToContent(content, this.getPreContent());
      // content.appendChild(codeViewer.getElement());
      // @ts-ignore
      appendToContent(content, this.getContentText());
      appendToContent(content, this.getPostContent());
      // @ts-ignore
      appendToContent(content, this.getContentActions());
      codeViewer.refresh();
      setTimeout(() => codeViewer.focus(), 0);

      return content;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     * @return {HTMLElement|String}
     */
    getContentActions() {
      const cont = document.createElement("div");

      const btn = document.createElement("button");
      btn.setAttribute("type", "button");
      const pfx = editor.getConfig("stylePrefix");
      btn.innerHTML = options.buttonLabel;
      btn.className = `${pfx}btn-prim ${pfx}btn-import__custom-code`;
      btn.id = "btnSave";
      btn.style.marginRight = "10px";
      btn.style.backgroundColor = "#0d63cb";
      btn.style.color = "white";
      btn.style.padding = "5px";
      btn.style.borderRadius = "5px";
      btn.style.borderColor = "#0d63cb";
      // @ts-ignore
      btn.onclick = () => this.handleSave();
      cont.appendChild(btn);

      const img = document.createElement("img");
      img.src = spinner;
      img.alt = "spinner";
      img.id = "spinner";
      img.height = 64;
      img.width = 64;
      img.style.visibility = "hidden";
      img.style.margin = "10px";
      cont.appendChild(img);

      return cont;
    },

    /**
     * Handle the main save task
     */
    handleSave() {
      document.getElementById("spinner").style.visibility = "visible";
      document.getElementById("btnSave").disabled = true;
      const imgCont = document.getElementById("imageContainer");
      imgCont && imgCont.remove();
      // @ts-ignore
      const { target } = this;
      // @ts-ignore
      const code =
        document.getElementById("txtPrompt").value == ""
          ? "A sunny beach with palm trees"
          : document.getElementById("txtPrompt").value;

      const options = {
        method: "GET",
        url: "pages",
        headers: {
          "content-type": "application/json",
        },
      };
      var style = "";
      Object.keys(dataStyle).forEach((key) => {
        dataStyle[key] && (style = key);
      });

      options.method = "GET";
      options.url = `grapeai/generateImage?prompt=${code}&style=${style}`;

      //const el = this.view.el; // target your div
      //el.src = spinner;
      client
        .request(options)
        .then((response) => {
          const modalBody = modal.getContent();
          const cont = document.createElement("div");
          cont.id = "imageContainer";
          cont.className = "column";
          response.map((img) => {
            const image = document.createElement("img");
            image.src = img;
            image.alt = img;
            image.id = img;
            image.height = 512;
            image.width = 512;
            image.style.border = "2px solid white";
            image.style.margin = "10px";
            image.style.borderRadius = "5px";
            image.style.cursor = "pointer";
            image.onclick = () => {
              target.set(keyCustomCode, `<img src="${img}" alt="${code}" />`);
              this.setImageStatus(img);
              const assetManager = editor.AssetManager;
              assetManager.add(img);
              assetManager.render();
              editor.Modal.close();
            };
            cont.appendChild(image);
          });
          modalBody.appendChild(cont);
          modal.setContent(modalBody);
          document.getElementById("spinner").style.visibility = "hidden";
          document.getElementById("btnSave").disabled = false;
        })
        .catch((error) => {
          document.getElementById("spinner").style.visibility = "hidden";
          document.getElementById("btnSave").disabled = false;
          console.log(error);
        });
    },
    setImageStatus(img) {
      options.method = "GET";
      options.url = `grapeai/ImageStatus?imageURL=${img}`;
      client.request(options).then((response) => {
        console.log(response);
      });
    },

    /**
     * Return the code viewer instance
     * @return {CodeViewer}
     */
    getCodeViewer() {
      // @ts-ignore
      if (!this.codeViewer) {
        // @ts-ignore
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: "htmlmixed",
          theme: "hopscotch",
          readOnly: 0,
          ...options.codeViewOptions,
        });
      }
      // @ts-ignore
      return this.codeViewer;
    },

    ...options.commandCustomCode,
  });
};
