import spinner from "../../../assets/spinner.gif";
import client from "ApiClient";

export const ExpandHeading = (editor, opts = {}) => {
  const keyCustomCode = "expand-heading-plugin__code";
  const typeCustomCode = "expand-heading";
  const commandNameCustomCode = "expand-heading:open-modal";
  let timedInterval = null;
  let modal = null;

  const options = {
    isLoading: false,
    blockCustomCode: {},
    propsCustomCode: {},
    toolbarBtnCustomCode: {},
    placeholderScript: `<div style="pointer-events: none; padding: 10px;">
      <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
        <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
        </svg>
      Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
    </div>`,
    codeViewOptions: {},
    buttonLabel: "Expand heading",
    commandCustomCode: {},
    ...opts,
  };

  editor.DomComponents.addType("script", {
    view: {
      onRender() {
        // @ts-ignore
        const { model, el } = this;
        const isCC = model.closestType(typeCustomCode);
        isCC && (el.innerHTML = "");
      },
    },
  });

  editor.DomComponents.addType(typeCustomCode, {
    model: {
      defaults: {
        name: "Expand Heading",
        editable: true,
        ...options.propsCustomCode,
      },

      /**
       * Initilize the component
       */
      init() {
        // @ts-ignore
        this.on(`change:${keyCustomCode}`, this.onCustomCodeChange);
        const initialCode = this.get(keyCustomCode);
        !this.components().length && this.components(initialCode);
        const toolbar = this.get("toolbar");
        const id = "custom-code";

        // Add the custom code toolbar button if requested and it's not already in
        // @ts-ignore
        if (options && !toolbar.filter((tlb) => tlb.id === id).length) {
          // @ts-ignore
          toolbar.unshift({
            id,
            command: commandNameCustomCode,
            label: `<svg viewBox="0 0 24 24">
              <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
            </svg>`,
            ...options,
          });
        }
      },

      /**
       * Callback to launch on keyCustomCode change
       */
      // @ts-ignore
      onCustomCodeChange() {
        // @ts-ignore
        this.components(this.get(keyCustomCode));
      },
    },

    view: {
      events: {
        dblclick: "onActive",
      },

      init() {
        // @ts-ignore
        this.listenTo(this.model.components(), "add remove reset", this.onComponentsChange);
        // @ts-ignore
        this.onComponentsChange();
      },

      /**
       * Things to do once inner components of custom code are changed
       */
      // @ts-ignore
      onComponentsChange() {
        timedInterval && clearInterval(timedInterval);
        timedInterval = setTimeout(() => {
          // @ts-ignore
          const { model, el } = this;
          const content = model.get(keyCustomCode) || "";
          let droppable = true;

          // Avoid rendering codes with scripts
          // if (content.indexOf('<script') >= 0 && opts.placeholderScript) {
          //   el.innerHTML = opts.placeholderScript;
          //   droppable = false;
          // }

          model.set({ droppable });
        }, 0);
      },

      onActive() {
        // @ts-ignore
        const { model, em } = this;
        em.get("Commands").run(commandNameCustomCode, { target: model });
      },
    },
  });

  //LOAD BLOCKS
  editor.BlockManager.add(typeCustomCode, {
    label: "Smart Heading",
    media: `
    
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M200-280v-400h80v160h160v-160h80v400h-80v-160H280v160h-80Zm480 0v-320h-80v-80h160v400h-80Z"/></svg>
    `,
    category: process.env.REACT_APP_SITE_TITLE + " AI",
    activate: true,
    select: true,
    content: { type: typeCustomCode },
    ...options,
  });

  //COMMANDS

  const appendToContent = (target, content) => {
    if (content instanceof HTMLElement) {
      target.appendChild(content);
    } else if (content) {
      target.insertAdjacentHTML("beforeend", content);
    }
  };

  // Add the custom code command
  editor.Commands.add(commandNameCustomCode, {
    keyCustomCode,

    run(editor, s, opts = {}) {
      const target = options.target || editor.getSelected();
      // @ts-ignore
      this.target = target;

      if (target?.get("editable")) {
        // @ts-ignore
        this.showCustomCode(target, opts);
      }
    },

    stop(editor) {
      editor.Modal.close();
    },

    /**
     * Method which tells how to show the custom code
     * @param  {Component} target
     */
    showCustomCode(target, options) {
      const title = "Get AI based suggestions for your headings in any tone you want!";
      const code = target.get(keyCustomCode) || "";
      // @ts-ignore
      const content = this.getContent();
      //console.log(content);
      const cr = code
        .replace(`<span id="expand-heading-plugin__code"><h1>`, "")
        .replace("</h1></span>", "");
      modal = editor.Modal.open({ title, content });
      modal.onceClose(() => editor.stopCommand(commandNameCustomCode));
      // @ts-ignore
      // this.getCodeViewer().setContent(code);
      document.getElementById("txtPrompt").value = cr;
    },

    /**
     * Custom pre-content. Can be a simple string or an HTMLElement
     */
    getPreContent() {},

    /**
     * Custom post-content. Can be a simple string or an HTMLElement
     */
    getPostContent() {
      return "<div>Post content</div>";
    },

    /**
     * Get all the content for the custom code
     * @return {HTMLElement}
     */

    getContent() {
      // @ts-ignore
      //const codeViewer = this.getCodeViewer();
      const content = document.createElement("div");
      const pfx = editor.getConfig("stylePrefix");
      content.className = `${pfx}custom-code`;
      // @ts-ignore
      //appendToContent(content, this.getPreContent());
      //console.log("CODEVIEWER", codeViewer.getElement());
      content.appendChild(this.getContentText());
      // content.appendChild(document.createElement("textarea"));
      // @ts-ignore
      //appendToContent(content, this.getPostContent());
      // @ts-ignore
      appendToContent(content, this.getContentActions());
      // codeViewer.refresh();
      // setTimeout(() => codeViewer.focus(), 0);

      return content;
    },

    getContentText() {
      const content = document.createElement("div");

      var closeSpan = document.createElement("span");
      closeSpan.textContent = "Write your heading here";
      content.appendChild(closeSpan);

      const txt = document.createElement("textarea");
      txt.id = "txtPrompt";
      txt.placeholder = "Type a few words so our AI can assist you";
      txt.style.padding = "5px";
      txt.style.width = "100%";
      txt.style.height = "50px";
      txt.style.color = "#000";
      content.appendChild(txt);

      return content;
    },

    /**
     * Get the actions content. Can be a simple string or an HTMLElement
     * @return {HTMLElement|String}
     */
    getContentActions() {
      const cont = document.createElement("div");
      const pfx = editor.getConfig("stylePrefix");

      const btne = document.createElement("button");
      btne.id = "generate";
      btne.setAttribute("type", "button");
      btne.innerHTML = "Get suggestions";
      btne.className = `button`;
      btne.style.marginRight = "10px";
      btne.style.backgroundColor = "#0d63cb";
      btne.style.color = "white";
      btne.style.padding = "5px";
      btne.style.borderRadius = "5px";
      btne.style.borderColor = "#0d63cb";
      // @ts-ignore
      btne.onclick = () => this.handleSave();
      cont.appendChild(btne);

      const img = document.createElement("img");
      img.src = spinner;
      img.alt = "spinner";
      img.id = "spinner";
      img.height = 32;
      img.width = 32;
      img.style.visibility = "hidden";
      img.style.margin = "10px";

      cont.appendChild(img);

      return cont;
    },

    /**
     * Handle the main save task
     */
    handleSave() {
      // @ts-ignore
      document.getElementById("spinner").style.visibility = "visible";

      //  document.getElementById("spinner").style

      const { target } = this;
      // @ts-ignore
      const code = document.getElementById("txtPrompt").value; // this.getCodeViewer().getContent();

      const opt = {
        method: "GET",
        url: "pages",
        headers: {
          "content-type": "application/json",
        },
      };

      opt.method = "GET";
      opt.url = `grapeai/GenerateHeadings?input=${code}`; //rephrased

      //const el = this.view.el; // target your div
      //el.src = spinner;

      client
        .request(opt)
        .then((response) => {
          const modalBody = modal.getContent();
          const result = response.result;
          const cont = document.createElement("div");
          cont.className = "column";
          result.map((item, index) => {
            const btne = document.createElement("button");
            btne.id = "generate-" + index;
            btne.setAttribute("type", "button");
            btne.innerHTML = item.output;
            btne.className = `button`;
            btne.style.marginRight = "10px";
            btne.style.backgroundColor = "#FFF";
            btne.style.color = "#000";
            btne.style.padding = "5px";
            btne.style.borderRadius = "5px";
            btne.style.borderColor = "#000";
            btne.onclick = () => {
              target.set(
                keyCustomCode,
                `<span id="${keyCustomCode}"><h1>${item.output}</h1></span>`
              );
              editor.Modal.close();
            };
            cont.appendChild(btne);
          });
          modalBody.appendChild(cont);
          modal.setContent(modalBody);
          //target.set(keyCustomCode, response.output);
          document.getElementById("spinner").style.visibility = "hidden";

          //editor.Modal.close();
          //  el.src = response;
        })
        .catch((error) => {
          document.getElementById("spinner").style.visibility = "hidden";

          console.log(error);
        });
    },

    /**
     * Return the code viewer instance
     * @return {CodeViewer}
     */
    getCodeViewer() {
      // @ts-ignore
      if (!this.codeViewer) {
        // @ts-ignore
        this.codeViewer = editor.CodeManager.createViewer({
          codeName: "htmlmixed",
          theme: "hopscotch",
          readOnly: 0,
          ...options.codeViewOptions,
        });
      }
      // @ts-ignore
      return this.codeViewer;
    },

    ...options.commandCustomCode,
  });
};
