let linkCommandId = 'custom-image-pencil'

export default (editor) => {
  return {
    // extend: "image",
    // extendView: "image",
    model: {

      defaults: {
        // toolbar: [
        //   { attributes: { class: "fas fa-paperclip" }, command: linkCommandId },
        // ],
        type: "custom-image",
        tagName: "div",

        // void: true,
        // highlightable: false,
        stylable: false,
        editable: false,
        badgable: false,
        // draggable: false,
        droppable: false,

        resizable: { ratioDefault: 1 },
        traits: ['alt'],
        style: {
          "min-height": "148px",
          "position": "relative",
          "justify-content": "center",
          "border": `2px dashed #3b97e3`,
          "max-width": "1140px",
          "margin": "12px auto",
          width: "100%",
          display: "flex", // Example: display as inline-block
          // Add more styles as needed
        },
        components: [
          //   {
          //   highlightable: false,
          //   draggable: false,
          //   stylable: false,
          //   editable: false,
          //   badgable: false,
          //   content: `<style>.custom-img-edit{
          //     opacity: 0;
          //   }
          //   .custom-img-edit:hover{
          //     opacity: 0.3;
          //   }
          // </style>`
          // },
          {
            styles: `.gjs-plh-image{
              width: 30%;
              height: 30%;
              background-color: transparent;
              outline: none;
            }`,
            style: {
              width: "30%",
              height: "30%",
            },
            type: 'image',
            src: `<svg class="image-pl-svg" fill="#00a7ff" color="" background="transparent" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="rotate(0.35655999183654785 5.992621421813402 6.009378433227507)" stroke="null"><path d="m8.797 7.706l-1.469-1.825c-0.049-0.062-0.124-0.1-0.209-0.1 -0.084 0-0.158 0.039-0.207 0.1l-0.196 0.244c-0.009 0.011-0.016 0.022-0.026 0.032 -0.048 0.051-0.114 0.081-0.187 0.081 -0.07 0-0.133-0.028-0.179-0.073 -0.015-0.016-0.03-0.032-0.041-0.052l-0.723-0.897c-0.065-0.08-0.163-0.131-0.272-0.131s-0.207 0.051-0.273 0.131l-1.957 2.428c-0.024 0.038-0.038 0.083-0.038 0.132 0 0.133 0.105 0.241 0.235 0.241l0 0 5.414 0c0.099 0 0.179-0.082 0.179-0.184 0-0.039-0.011-0.075-0.032-0.103l-0.02-0.024 0 0.001z" class="a"></path><path d="m8.378 4.002c-0.324 0-0.587 0.269-0.587 0.601s0.263 0.601 0.587 0.601c0.325 0 0.587-0.269 0.587-0.601s-0.262-0.601-0.587-0.601z" class="a"></path></g></svg>`,
            fallback: `<svg class="image-pl-svg" fill="#00a7ff" color="" background="transparent" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><g transform="rotate(0.35655999183654785 5.992621421813402 6.009378433227507)" stroke="null"><path d="m8.797 7.706l-1.469-1.825c-0.049-0.062-0.124-0.1-0.209-0.1 -0.084 0-0.158 0.039-0.207 0.1l-0.196 0.244c-0.009 0.011-0.016 0.022-0.026 0.032 -0.048 0.051-0.114 0.081-0.187 0.081 -0.07 0-0.133-0.028-0.179-0.073 -0.015-0.016-0.03-0.032-0.041-0.052l-0.723-0.897c-0.065-0.08-0.163-0.131-0.272-0.131s-0.207 0.051-0.273 0.131l-1.957 2.428c-0.024 0.038-0.038 0.083-0.038 0.132 0 0.133 0.105 0.241 0.235 0.241l0 0 5.414 0c0.099 0 0.179-0.082 0.179-0.184 0-0.039-0.011-0.075-0.032-0.103l-0.02-0.024 0 0.001z" class="a"></path><path d="m8.378 4.002c-0.324 0-0.587 0.269-0.587 0.601s0.263 0.601 0.587 0.601c0.325 0 0.587-0.269 0.587-0.601s-0.262-0.601-0.587-0.601z" class="a"></path></g></svg>`,
          },
          // {
          //   tagName: "div", classes: ["custom-img-edit"], components: [{
          //     highlightable: false,
          //     draggable: false,
          //     stylable: false,
          //     editable: false,
          //     badgable: false,
          //     content: `<p style="font-size:20px;color:white;font-weight:bold;">Edit Image</p>`
          //   }], styleable: false, stylable: false,
          //   highlightable: false,
          //   draggable: false,
          //   stylable: false,
          //   editable: false,
          //   badgable: false,
          //   style: {
          //     position: "absolute",
          //     "background-color": "#00A7FF",
          //     "display": "flex",
          //     "justify-content": 'center',
          //     "align-items": "center",
          //     top: 0,
          //     left: 0,
          //     width: "100%",
          //     height: "100%",
          //   }
          // }
        ]
      },



      // isComponent(el) {
      //   return toLowerCase(el.tagName) === 'img';
      // },
      init() {

        const image = this.components().at(0)


        const me = this
        this.listenTo(image, "change:src", (value) => {
          console.warn("haha")
          const styles = this.getStyle()

          if ((image.attributes.src)) {



            this.setStyle({ ...styles, border: "none" })
          }
          else {
            this.setStyle({ ...styles, border: `2px dashed #3b97e3` })

          }
        })
      },
    }, view: {
      events: {
        "dblclick": "containerClicked",
        "click": "onSingleClick",
      },
      onSingleClick(e) {
        editor.select(editor.DomComponents.getComponent(e.target.id))
        setTimeout(function () {
          const selectedComponent = editor.getSelected();
          const editorElementIframe = editor.Canvas.getDocument()
          const iframeDocument = editorElementIframe;
          const el = selectedComponent.getEl()

          // hideStyleSector(editor)
          if (iframeDocument) {
            const container = iframeDocument.querySelector(".gjs-higlight-backdrop-container")

            container?.remove()
            const body = iframeDocument.body
            const firstChild = body.firstChild


            const backDropDiv = iframeDocument.createElement('div')
            backDropDiv.classList.add("gjs-higlight-backdrop-container")
            backDropDiv.style = `height:${iframeDocument.body.clientHeight}px`
            body.insertBefore(backDropDiv, firstChild)
            setTimeout(() => {
              if (backDropDiv) {
                backDropDiv.classList.add("active")
              }
            }, 0)

            backDropDiv.addEventListener('click', () => {
              editor.select(null);
              backDropDiv.classList.remove("active");
              setTimeout(() => {
                if (backDropDiv) {
                  backDropDiv.remove()

                }
              }, 300)

            })



          }
        }, 100)
      },
      containerClicked(component) {
        console.warn(image)
        const image = this.components().find(element => {
          return element.attributes.type === "image"
        })
        image.view.onActive()

      }
      // Style the wrapper div if needed

    },
  }

}